/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toBottom': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M73.333 66.666v-10c0-1.832-1.5-3.332-3.333-3.332H30a3.345 3.345 0 0 0-3.333 3.332v10H20v6.668h60v-6.668h-6.667zM66.667 40V30a3.344 3.344 0 0 0-3.334-3.333H36.667A3.344 3.344 0 0 0 33.333 30v10H20v6.667h60V40H66.667zM60 40H40v-6.667h20V40z"/>'
  }
})
