<template>
  <div id="custom_table">
    <section-definition
      :title="GetAsterLead"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @refreshPage="refreshClick"
    ></section-definition>
    <v-container fluid grid-list-xl page>
      <v-icon
        v-if="bulk_edit_arr.length > 0"
        title="Bulk Edit"
        small
        @click="editClick()"
        style="
          position: absolute;
          top: 38px;
          right: 205px;
          font-size: 19px;
          display: flex;
          color: #2196f3 !important;
          padding: 10px !important;
          cursor: pointer;
          border-radius: 50%;
        "
        class="fa fa-pencil-square-o"
      ></v-icon>
      <v-icon
        v-if="bulk_edit_arr.length > 0"
        title="Bulk Delete"
        small
        @click="deleteClick()"
        style="
          position: absolute;
          top: 38px;
          right: 180px;
          font-size: 16px;
          display: flex;
          color: #2196f3 !important;
          padding: 10px !important;
          cursor: pointer;
          border-radius: 50%;
        "
        class="fas fa-trash-alt"
      ></v-icon>
      <div id="toolbar-operations">
        <v-icon
          title="Previews page"
          small
          @click="pushBack()"
          class="fas fa-arrow-left"
        ></v-icon>
        <v-icon
          title="Filter"
          class="fa fa-filter"
          small
          @click="isFilter()"
          aria-hidden="true"
        ></v-icon>
        <v-icon
          title="Upload Leads"
          small
          v-show="pagecontrol.fileupload"
          @click="UploadFile()"
          class="fas fa-upload"
        ></v-icon>
        <v-icon
          title="Create Leads"
          small
          v-show="pagecontrol.cancreate"
          @click="insertDetails()"
          class="fas fa-plus"
        ></v-icon>
      </div>
      <div class="overflow-auto">
        <b-table
          sticky-header
          responsive
          striped
          class="tabledata"
          :items="AsterLeadValue"
          :fields="fields"
        >
          <template #cell(selectall)="row">
            <v-checkbox
              @change="newValue(row.item)"
              v-model="row.item.checker"
              style="height: 5px; margin-top: -5px"
            >
            </v-checkbox>
          </template>
          <template #cell(actions)="row">
            <v-icon
              color="green"
              small
              class="mr-4"
              @click="showHistory(row.item)"
              >fa fa-history</v-icon
            >
            <v-icon
              color="blue"
              small
              title="Edit"
              class="mr-4"
              v-show="pagecontrol.canedit"
              @click="editDetails(row.item)"
              >edit</v-icon
            >
            <v-icon
              color="blue"
              v-show="pagecontrol.canedit"
              @click="assignAgent(row.item)"
              title="Agent Assign"
              small
              class="mr-4"
              >queue</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              title="Delete"
              v-show="pagecontrol.candelete"
              @click="deleteDetails(row.item)"
              >delete</v-icon
            >
          </template>
        </b-table>
        <div class="pagination-div theme--light">
          <v-checkbox
            :label="select_label"
            @change="select_all()"
            style="padding-left: 15px"
            v-model="select_check"
          >
          </v-checkbox>
          <div flex id="row-selector">
            <div id="page-control">
              <span
                >{{ (currentPage - 1) * perPage + 1 }}-{{ endPage }} of
                {{ totalRows }}</span
              >
              <span class="action-icon">
                <v-icon medium class="move-page" @click="moveNextPage(false)"
                  >chevron_left</v-icon
                >
                <v-icon medium class="move-page" @click="moveNextPage(true)"
                  >chevron_right</v-icon
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Delete Lead info</v-toolbar-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this lead ?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isShowAgentAssign" max-width="400px">
        <v-form ref="refassign" v-model="valid">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title
                >#{{ assignedDetails["lead id"] }}</v-toolbar-title
              >
            </v-toolbar>
            <v-container
              id="scroll-target"
              style="max-height: 350px"
              class="scroll-y"
            >
              <template>
                <v-select
                  v-model="assignedDetails.user"
                  :items="agents"
                  label="Agent"
                  required
                  persistent-hint
                  :allow-overflow="false"
                ></v-select>
              </template>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeAssign()">Cancel</v-btn>
              <v-btn color="primary" @click="submitAssign()"
                >Assgin Agent</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-navigation-drawer fixed right :value="pagecontrol.isFilterOpen">
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            color="white"
            @click="pagecontrol.isFilterOpen = false"
          >
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div
          class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
        >
          <v-container class="sidenavContainer">
            <div class="autocompleteDiv">
              <v-autocomplete
                v-model="filter.list_id"
                :items="filtervalue"
                label="List ID"
                small
              ></v-autocomplete>
            </div>
            <div class="autocompleteDiv">
              <v-text-field
                v-model="filter.phone_number"
                label="Phone Number"
                small
              ></v-text-field>
            </div>
          </v-container>
          <v-container row class="sidenavFooter">
            <v-btn flat @click="getDatafieldApi()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <v-dialog persistent v-model="pagecontrol.isUploadFile" max-width="500px">
        <v-card>
          <v-form ref="createform">
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Upload AsterLead</span>
              </v-card-title>
            </v-toolbar>
            <v-container grid-list-md>
              <div class="autocompleteDiv">
                <v-autocomplete
                  v-model="selected_list"
                  :items="add_list"
                  label="List"
                  persistent-hint
                  small
                  required
                  single-line
                  :allow-overflow="false"
                  :rules="[(v) => !!v || 'list is required']"
                ></v-autocomplete>
              </div>
              <p>
                <span style="font-weight: bold">Not sure what to upload?</span>
                Click here to download the sample file. Format:
                <a @click="samplefiledownload('csv')">CSV</a>,
                <a @click="samplefiledownload('txt')">Txt(Tab-Delimited)</a>.
                Open the file and enter the phone numbers at the first column of
                each row. Save the file with .csv /.txt extension and upload the
                file.
              </p>
              <div
                class="file-dummy"
                @click="$refs.file.click()"
                :style="{
                  background: uploadLead.color + '66',
                  'border-color': uploadLead.color,
                  color: uploadLead.color,
                }"
              >
                {{ uploadLead.label }}
              </div>
              <input
                :hidden="true"
                type="file"
                id="file"
                ref="file"
                accept=".csv,.txt"
                v-on:change="handleFileUpload()"
              />
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="ColseFile()">Cancel</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
    <v-dialog persistent v-model="edit_popup" max-width="400px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Update Leads</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                required
                v-model="bulk_edit.user"
                label="User"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                label="Disposition"
                :items="bulk_dispo"
                return-string
                v-model="bulk_edit.disposition"
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-select
                label="List ID"
                :items="add_list"
                v-model="bulk_edit.list_id"
                persistent-hint
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="edit_popup = false">Cancel</v-btn>
          <v-btn color="primary" @click="bulk_save">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="delete_popup" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Delete Lead</v-toolbar-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this leads ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="delete_popup = false">Cancel</v-btn>
          <v-btn color="primary" @click="bulkDelete">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="show_history" max-width="400px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Activity</span>
          </v-card-title> </v-toolbar
        ><br />
        <div
          class="showing_history"
          v-for="items in lead_history"
          v-bind:key="items"
        >
          <v-icon
            color="green"
            class="icon_history"
            @click="showHistory(row.item)"
            >fa fa-phone</v-icon
          ><label>{{ items.created_at }}</label
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
            >{{ items.call_type }}</label
          ><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
            >{{ items.status }}</label
          >&nbsp;&nbsp;&nbsp;<label>{{ items.duration }}</label
          >&nbsp;&nbsp;&nbsp;<label>{{ items.description }}</label>
          <hr />
        </div>
        <v-btn
          style="margin-left: 300px"
          color="light"
          type="reset"
          flat
          @click="closehistory()"
          >Close</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { BTable } from "bootstrap-vue/src/components/table/index.js";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import { mapGetters } from "vuex";
import api from "@/utils/axios-util";
import moment from "moment";
const { convertArrayToCSV } = require("convert-array-to-csv");
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    "b-table": BTable,
  },
  name: "App",
  data() {
    return {
      selected_list: "",
      add_list: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      endPage: 0,
      pagecontrol: {
        title: "User Management",
        icon: "fa-user",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        isUploadFile: false,
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [],
        canedit: false,
        candelete: false,
        fileupload: false,
        cancreate: false,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      AsterLeadValue: [],
      bulk_edit_arr: [],
      bulk_edit: {
        user: "",
        disposition: "",
        list_id: "",
      },
      bulk_dispo: [],
      select_label: "Select All",
      select_check: false,
      edit_popup: false,
      delete_popup: false,
      lead_history: [],
      show_history: false,
      newData: [],
      fields: [],
      fielddata: [],
      DeleteRowdata: false,
      uploadLead: {},
      excel_data: [],
      read_data: "",
      isShowAgentAssign: false,
      assignedDetails: {},
      agents: [],
      valid: true,
      filter: {
        phone_number: "",
        list_id: "",
      },
      filtervalue: [],
    };
  },
  computed: {
    ...mapGetters(["GetAsterLead", "GetAsterLeadInsert"]),
    sortOptions() {
      // Create an options list from our fields
      return this.newData
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    insertDetails() {
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "leadmanagement:lead:create"
        ) === -1
      ) {
        scope.$router.push({
          name: "blankPage",
        });
      } else {
        scope.$store
          .dispatch("GetFormFields", this.GetAsterLead)
          .then(() => {
            scope.$router.push({
              name: "crm/InsertAsterLead",
            });
          })
          .catch(function (error) {
            scope.showSnackBar("error" + error);
          });
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    newValue(row) {
      var scope = this;
      let id = row["lead id"];
      if (!scope.bulk_edit_arr.includes(id)) {
        scope.bulk_edit_arr.push(id);
      } else {
        const index = scope.bulk_edit_arr.indexOf(id);
        if (index > -1) {
          scope.bulk_edit_arr.splice(index, 1);
        }
      }
    },
    editClick() {
      this.edit_popup = true;
    },
    deleteClick() {
      this.delete_popup = true;
    },
    bulk_save() {
      var scope = this;
      var edit_data = {
        disposition: scope.bulk_edit.disposition,
        user: scope.bulk_edit.user,
        list_id: scope.bulk_edit.list_id,
        bulk_id: scope.bulk_edit_arr,
        template_name: this.GetAsterLead,
      };
      scope.$store
        .dispatch("BulkEditSave", edit_data)
        .then(() => {
          scope.edit_popup = false;
          scope.bulk_edit.user = "";
          scope.bulk_edit.list_id = "";
          scope.bulk_edit.disposition = "";
          scope.bulk_edit_arr = [];
          scope.select_label = "Select All";
          scope.select_check = false;
          scope.showSnackBar("Lead Updated Successfully");
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.getDatafieldApi();
    },
    bulkDelete() {
      var scope = this;
      var delete_data = {
        bulk_id: scope.bulk_edit_arr,
        template_name: this.GetAsterLead,
      };
      scope.$store
        .dispatch("BulkDeleteSave", delete_data)
        .then(() => {
          scope.delete_popup = false;
          scope.bulk_edit_arr = [];
          scope.select_label = "Select All";
          scope.select_check = false;
          scope.showSnackBar("Lead Deleted Successfully");
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.getDatafieldApi();
    },
    select_all() {
      var scope = this;
      let id = "";
      if (scope.select_check) {
        scope.select_label = "Unselect All";
        for (let i = 0; i < scope.AsterLeadValue.length; i++) {
          scope.AsterLeadValue[i].checker = true;
          id = scope.AsterLeadValue[i]["lead id"];
          scope.bulk_edit_arr.push(id);
        }
      } else {
        for (let j = 0; j < scope.AsterLeadValue.length; j++) {
          scope.AsterLeadValue[j].checker = false;
        }
        scope.bulk_edit_arr = [];
        scope.select_label = "Select All";
      }
    },
    editDetails(row) {
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "leadmanagement:lead:edit"
        ) === -1
      ) {
        scope.$router.push({
          name: "blankPage",
        });
      } else {
        scope.$store.dispatch("Editdata", row);
        // console.log(this.GetAsterLead);
        scope.$store.dispatch("GetFormFields", this.GetAsterLead).then(() => {
          scope.$router.push({
            name: "crm/EditAsterLead",
          });
        });
      }
    },
    closehistory() {
      this.show_history = false;
    },
    showHistory(row) {
      var scope = this;
      var id = "";
      let result = [];
      row = [row];
      function in_convert(object) {
        const result = {};
        Object.keys(object).forEach(function (key) {
          result[key.replace(/ /g, "_")] = object[key];
        });
        return result;
      }
      if (row) {
        result = row.map(function (obj) {
          return in_convert(obj);
        });
      }
      if (
        result[0].lead_id !== "" &&
        result[0].lead_id !== undefined &&
        result[0].lead_id
      ) {
        id = result[0].lead_id;
      }
      if (
        result[0].order_id !== "" &&
        result[0].order_id !== undefined &&
        result[0].order_id
      ) {
        id = result[0].order_id;
      }
      if (
        result[0].ticket_id !== "" &&
        result[0].ticket_id !== undefined &&
        result[0].ticket_id
      ) {
        id = result[0].ticket_id;
      }
      var payload = {
        phone_number: result[0].phone_number,
        id: id,
      };
      scope.$store.dispatch("gethistory", payload).then((response) => {
        scope.lead_history = response;
        if (scope.lead_history) {
          for (let i = 0; i < scope.lead_history.length; i++) {
            scope.lead_history[i].created_at = moment(
              scope.lead_history[i].created_at
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          this.show_history = true;
        } else {
          scope.showSnackBar("204 No Content");
        }
      });
    },
    deleteDetails(row) {
      var scope = this;
      if (scope.pagecontrol.candelete) {
        this.DeleteRowdata = true;
      }
      let id_value = "";
      for (const [key, value] of Object.entries(row)) {
        if (
          `${key}` === "order id" ||
          `${key}` === "ticket id" ||
          `${key}` === "lead id"
        ) {
          id_value = `${value}`;
        }
      }
      this.delete_id = id_value;
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      let payload = {
        template_name: this.GetAsterLead,
        delete_id: this.delete_id,
      };
      this.$store.dispatch("deleteAsterLead", payload).then((response) => {
        scope.isProcessing = false;
        if (response.statusCode == 200) {
          scope.showSnackBar("Lead Deleted Successfully");
          scope.getDatafieldApi();
        } else {
          scope.showSnackBar("Error! Unable Delete Lead");
        }
      });
      this.closedelete();
      this.getDatafieldApi();
    },
    getDatafieldApi() {
      var scope = this;
      scope.tableLoading = true;
      scope.pagecontrol.isFilterOpen = false;
      scope.filter.list_id = "";
      scope.filter.phone_number = "";
      let titleData = scope.GetAsterLead;
      let AsterLeadFields = "";
      scope.bulk_edit.user = "";
      scope.bulk_edit.list_id = "";
      scope.bulk_edit.disposition = "";
      scope.bulk_edit_arr = [];
      scope.select_label = "Select All";
      scope.select_check = false;
      this.$store
        .dispatch("GetAsterLeadField", titleData)
        .then((response) => {
          var datas = response.data[0].fields;
          AsterLeadFields = datas;
          for (let i = 0; AsterLeadFields.length > i; ++i) {
            scope.fields.push({ key: "Selectall", label: "Select All" });
            if (AsterLeadFields[i].label === "Disposition") {
              let temp1 = JSON.parse(JSON.stringify(AsterLeadFields[i].values));
              let temp2 = JSON.parse(temp1);
              for (let j = 0; temp2.length > j; ++j) {
                scope.bulk_dispo.push(temp2[j].name);
              }
            }
            AsterLeadFields[i].model = AsterLeadFields[i].model.replace(
              "_",
              " "
            );
            AsterLeadFields[i].label = AsterLeadFields[i].label.replace(
              "_",
              " "
            );
            if (AsterLeadFields[i].show_on_view === true) {
              let key = {
                key: AsterLeadFields[i].model,
                label: AsterLeadFields[i].model,
              };
              scope.fields.push(key);
            }
            if (i === 0) {
              scope.fields.push({ key: "list id", label: "list id" });
            }
          }
          scope.fields.push({ key: "Actions", label: "Actions" });
          const payload = {
            name: scope.GetAsterLead,
            skip: scope.perPage * (scope.currentPage - 1),
            take: scope.perPage,
          };
          scope.filtervalue = [];
          scope.$store
            .dispatch("GetAsterLeadData", payload)
            .then((response) => {
              let datas = response.data;
              if (datas !== "" && datas !== undefined && datas !== null) {
                datas.forEach((element) => {
                  scope.filtervalue.push(element["list_id"]);
                });
              }
              function convert(obj) {
                const result = {};
                Object.keys(obj).forEach(function (key) {
                  result[key.replace(/_/g, " ")] = obj[key];
                });
                return result;
              }
              let result = [];
              if (datas) {
                result = datas.map(function (o) {
                  return convert(o);
                });
              }
              for (let k = 0; k < result.length; k++) {
                result[k].checker = false;
              }
              scope.AsterLeadValue = result;
              if (response.totalCount !== undefined) {
                scope.totalRows = response.totalCount;
              } else {
                scope.totalRows = 0;
              }
              scope.endPage = scope.currentPage * scope.perPage;
              scope.endPage =
                scope.endPage < scope.totalRows
                  ? scope.endPage
                  : scope.totalRows;
            })
            .catch(function (error) {
              scope.showSnackBar("error" + error);
            });
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    moveNextPage(next) {
      const scope = this;
      if (next && scope.totalRows > scope.currentPage * scope.perPage + 1) {
        scope.currentPage = scope.currentPage + 1;
        scope.getDatafieldApi();
      } else if (!next && scope.currentPage !== 1) {
        scope.currentPage = scope.currentPage - 1;
        scope.getDatafieldApi();
      }
    },
    CloseItem() {
      let scope = this;
      scope.$router.push({
        name: "crm/AsterLead",
      });
    },
    UploadFile() {
      this.pagecontrol.isUploadFile = true;
    },
    ColseFile() {
      this.pagecontrol.isUploadFile = false;
      this.$refs.createform.resetValidation();
      this.selected_list = "";
      this.uploadLead = getDefaultData();
    },
    isFilter() {
      this.pagecontrol.isFilterOpen = true;
    },
    fetchFilter() {
      var scope = this;
      var data = {
        template_name: scope.GetAsterLead,
        list_id: scope.filter.list_id,
        phone_number: scope.filter.phone_number,
      };
      scope.$store
        .dispatch("FilterAsterLeads", data)
        .then((response) => {
          let datas = response.data;
          function convert(obj) {
            const result = {};
            Object.keys(obj).forEach(function (key) {
              result[key.replace(/_/g, " ")] = obj[key];
            });
            return result;
          }
          let result = [];
          if (datas) {
            result = datas.map(function (o) {
              return convert(o);
            });
          }
          for (let k = 0; k < result.length; k++) {
            result[k].checker = true;
          }
          scope.AsterLeadValue = result;
          if (response.totalCount !== undefined) {
            scope.totalRows = response.totalCount;
          } else {
            scope.totalRows = 0;
          }
          scope.endPage = scope.currentPage * scope.perPage;
          scope.endPage =
            scope.endPage < scope.totalRows ? scope.endPage : scope.totalRows;
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    handleFileUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadLead.color = "#A64452";
      scope.uploadLead.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      formData.append("template_name", scope.GetAsterLead);
      formData.append("list_id", scope.selected_list);
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/aster/fileupload", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.getDatafieldApi();
          if (response.data.data.length > 0) {
            scope.uploadLead.color = "#4BB543";
            scope.uploadLead.label = "Uploaded Successfully";
            scope.ColseFile();
          } else {
            scope.uploadLead.color = "#F51B00";
            scope.uploadLead.label = "Invalid Field Format";
          }
          scope.$refs.file.value = "";
          if (response.data.skippedData.length > 0) {
            let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            let skipvalue = convertArrayToCSV(response.data.skippedData);
            csvContent += skipvalue;
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "skip" + scope.GetAsterLead + ".csv");
            document.body.appendChild(link);
            link.click();
          }
          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadLead.color = "#F51B00";
          scope.uploadLead.label = "Upload Error";
          scope.$refs.file.clearAll();
          return error;
        });
    },
    assignAgent(leadData) {
      const scope = this;
      scope.assignedDetails = JSON.parse(JSON.stringify(leadData));
      scope.assignedDetails.template_name = scope.GetAsterLead;
      scope.isShowAgentAssign = true;
      scope.$refs.refassign.resetValidation();
    },
    closeAssign() {
      const scope = this;
      scope.assignedDetails = {};
      scope.isShowAgentAssign = false;
      scope.$refs.refassign.resetValidation();
    },
    submitAssign() {
      const scope = this;
      const assignDetails = {
        lead_id: scope.assignedDetails["lead id"],
        user: scope.assignedDetails["user"],
        template_name: scope.assignedDetails.template_name,
      };
      scope.$store
        .dispatch("AssignAgent", assignDetails)
        .then((response) => {
          if (response.statusCode === 200) {
            scope.showSnackBar("Lead assigned to agent successfully!");
            scope.assignedDetails = {};
            scope.isShowAgentAssign = false;
            scope.$refs.refassign.resetValidation();
            scope.getDatafieldApi();
          } else {
            scope.showSnackBar("Lead assigned to agent failed!");
          }
        })
        .catch(() => {
          scope.showSnackBar("Lead assigned to agent failed!");
        });
    },
    samplefiledownload(type) {
      const scope = this;
      let titleData = scope.GetAsterLead;
      let AsterLeadFields = "";
      scope.$store.dispatch("GetAsterLeadField", titleData).then((response) => {
        var datas = response.data[0].fields;
        AsterLeadFields = datas;
        const header = [];
        for (let i = 0; AsterLeadFields.length > i; ++i) {
          let key = {
            key: AsterLeadFields[i].model,
            label: AsterLeadFields[i].label,
          };

          if (
            AsterLeadFields[i].model !== "ticket_id" &&
            AsterLeadFields[i].model !== "lead_id" &&
            AsterLeadFields[i].model !== "order_id" &&
            AsterLeadFields[i].model !== "list_id" &&
            AsterLeadFields[i].model !== "disposition" &&
            AsterLeadFields[i].show_on_view
          ) {
            header.push(AsterLeadFields[i].model);
          }
          scope.fielddata.push(key);
        }
        let csvContent = "";
        if (type === "csv") {
          csvContent += "data:text/csv;charset=utf-8,\uFEFF";
          csvContent += header.join(",");
        } else {
          csvContent += "data:text/txt;charset=utf-8,\uFEFF";
          csvContent += header.join("\t");
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", titleData + "." + type);
        document.body.appendChild(link);
        link.click();
      });
    },
    refreshClick() {
      let scope = this;
      scope.getDatafieldApi();
    },
    pushBack() {
      this.$router.push({
        name: "crm/AsterLead",
      });
    },
  },
  mounted() {
    let scope = this;
    let stats = {
      list_name: "",
      list_campaign: "",
      list_status: "Y",
    };
    scope.$store.dispatch("FilterAsterList", stats).then((response) => {
      let data = response.data;
      let list = [];
      for (let i = 0; i < data.length; i++) {
        list.push(data[i].list_id);
      }
      scope.add_list = list;
    });
    scope.agents = JSON.parse(localStorage.getItem("group_users"));
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:fileupload"
          ) !== -1
        ) {
          scope.pagecontrol.fileupload = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:edit"
          ) !== -1
        ) {
          scope.pagecontrol.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:create"
          ) !== -1
        ) {
          scope.pagecontrol.cancreate = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:delete"
          ) !== -1
        ) {
          scope.pagecontrol.candelete = true;
        }
      }.bind(scope),
      1
    );
    scope.getDatafieldApi();
    this.uploadLead = getDefaultData();
  },
};
</script>
<style>
.btn-light {
  margin-left: 900px;
}
#toolbar-operations {
  position: absolute;
  top: 38px;
  right: 55px;
  width: 130px;
  display: flex;
}
#toolbar-operations .v-icon {
  color: #2196f3 !important;
  padding: 10px !important;
  cursor: pointer;
  border-radius: 50%;
}
#toolbar-operations .v-icon:hover {
  background-color: #d9ecfc !important;
}
.edit-icon {
  margin: 0px 10px !important;
  color: blue;
}
.delete-icon {
  margin: 0px 10px !important;
  color: red;
}
.mr-3 {
  padding: 10px !important;
  margin: 10px !important;
  margin-top: -7px !important;
  color: #2196f3 !important;
  position: absolute;
  top: 87px;
  right: 45px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Lato", sans-serif;
}
.mr-6 {
  padding: 10px !important;
  margin: 50px !important;
  margin-top: -7px !important;
  color: #2196f3 !important;
  position: absolute;
  top: 87px;
  right: 90px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Lato", sans-serif;
}
.title {
  padding: 10px !important;
  margin: 10px;
  color: #2196f3 !important;
}
.b-table-sticky-header {
  overflow-y: auto !important;
  overflow-x: auto !important;
  max-height: 390px !important;
}
.tabledata {
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
}
.tabledata tr th {
  background: #fff;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Lato", sans-serif;
  padding: 15px 10px !important;
}
.tabledata tr td {
  background: #fff;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Lato", sans-serif;
  padding: 15px !important;
}
.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0rem !important;
}
.showing_history {
  padding: 5px;
  padding-top: 10px;
}
.showing_history .icon_history {
  font-size: 40px;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-bottom: -16px;
  padding: 10px;
  height: 20px;
}
.pagination-div {
  background: #fff;
  height: 60px;
  margin-top: 5px;
  width: 100%;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2) !important;
  border-bottom-color: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.pagination-div #row-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px !important;
  color: rgba(53, 18, 18, 0.54) !important;
  font-family: "Lato", sans-serif !important;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.pagination-div #row-selector select {
  margin-left: 10px;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: "Lato", sans-serif !important;
}
.pagination-div #row-selector #page-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px !important;
  color: rgba(53, 18, 18, 0.54) !important;
  font-family: "Lato", sans-serif !important;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.pagination-div #row-selector #page-control .action-icon {
  color: rgba(53, 18, 18, 0.64) !important;
  margin-left: 20px;
}
.pagination-div #row-selector #page-control .action-icon .move-page {
  margin: auto 20px;
  cursor: pointer !important;
}
</style>
