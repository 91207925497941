let AppDrawerItems = [{
        icon: "dashboard",
        title: "Dash Board",
        name: "DashBoard",
        permission: ['dashboard:reportbuilder:view'],
        auth: true
    },
    {
        icon: "fas fa-users-cog",
        title: "CRM",
        group: "crm",
        items: [
            { prependIcon: "", title: "Campaigns", name: "AsterCampaign", permission: ['leadmanagement:campaigns:view'], auth: true },
            { prependIcon: "", title: "Lists", name: "AsterList", permission: ['leadmanagement:list:view'], auth: true },
            { prependIcon: "", title: "Leads", name: "AsterLead", permission: ['leadmanagement:lead:view'], auth: true },
            { prependIcon: "", title: "Custom Forms", name: "CustomFormList", permission: ['leadmanagement:custom-form:view'], auth: true }
        ]
    },
    {
        icon: "contacts",
        title: "Contacts",
        name: "Contact",
        permission: ['configurations:contact:view'],
        auth: true
    },
    {
        icon: "people",
        title: "Users & Groups",
        group: "usergroup",
        items: [
            { prependIcon: "", title: "User Group", name: "GroupManagement", permission: ['configurations:usergroup:view'], auth: true },
            { prependIcon: "", title: "Users", name: "UserManagement", permission: ['management:user:view'], auth: true },
            { prependIcon: "", title: "Team", name: "TeamManagement",permission: ['management:team:view'], auth: true },
        ]
    },
    {
        icon: "assignment_turned_in",
        title: "Reports",
        group: "report",
        items: [
            { prependIcon: "", title: "Call Recordings", name: "RecordingReport", permission: ['reports:recording:view'], auth: true },
            { prependIcon: "", title: "Call Log", name: "CallDetailReport", permission: ['reports:calldetail:view'], auth: true },
            { prependIcon: "", title: "Agent Performance", name: "PerformanceReport", permission: ['reports:agentperformance:view'], auth: true },
            { prependIcon: "", title: "Effective Abandon", name: "EffectiveAbandonReport", permission: ['reports:calldetail:view'], auth: true },
            { prependIcon: "", title: "Agent Activity", name: "AgentActivityReport", permission: ['reports:agentactivity:view'], auth: true },
            { prependIcon: "", title: "Agent Pause", name: "AgentPauseReport", permission: ['reports:agentpause:view'], auth: true },
            { prependIcon: "", title: "Agent Dispo", name: "AgentDispoReport", permission: ['reports:agentdispo:view'], auth: true },
            { prependIcon: "", title: "Queue Summary", name: "QueueSummary", permission: ['reports:queueperformance:view'], auth: true },
            { prependIcon: "", title: "Missed Calls", name: "MissedCallReport", permission: ['reports:missedcall:view'], auth: true },
            { prependIcon: "", title: "Dropped Calls", name: "DropedCallReport", permission: ['reports:dropedcall:view'], auth: true },
        ]
    },
    {
        icon: "alarm",
        title: "Real Time",
        group: "realtime",
        items: [
            { prependIcon: "", title: "Live Agents", name: "LiveAgents", permission: ['realtime:liveagents:view'], auth: true },
            { prependIcon: "", title: "Live Calls", name: "LiveCalls", permission: ['realtime:livecalls:view'], auth: true },
            {
                prependIcon: "",
                title: "Live Status",
                name: "Wallboard",
                permission: ['realtime:livestatus:campaignview', 'realtime:livestatus:queueview'],
                auth: true
            },
        ]
    },
    {
        icon: "settings",
        title: "Configurations",
        group: "configurations",
        items: [
            { prependIcon: "", title: "Queues", name: "QueueManagement", permission: ['management:queue:view'], auth: true },
            { prependIcon: "", title: "Audio Store", name: "AudioStore", permission: ['configurations:audiostore:view'], auth: true },
            { prependIcon: "", title: "Disposition", name: "Disposition", permission: ['configurations:dispositions:view'], auth: true },
            { prependIcon: "", title: "Pause Code", name: "PauseCode", permission: ['configurations:pausecode:view'], auth: true },
            { prependIcon: "", title: "Rules", name: "Rules", permission: ['configurations:rules:view'], auth: true },
            { prependIcon: "", title: "Reminder", name: "Reminder", permission: ['configurations:blocklist:view'], auth: true },
            { prependIcon: "", title: "Block List", name: "BlockList", permission: ['configurations:blocklist:view'], auth: true },
            { prependIcon: "", title: "DNC", name: "DoNoCall", permission: ['configurations:dnc:view'], auth: true },
            { prependIcon: "", title: "Tags", name: "TagManagement", permission: ['management:tags:view'], auth: true },
            { prependIcon: "", title: "Timezone", name: "Timezone", permission: ['configurations:dialrule:view'], auth: true },
         

        ]
    },
    {
        icon: "fa fa-building",
        title: "Report Builder",
        name: "Widgets",
        permission: ['dashboard:widget:view'],
        auth: true
    },
    {
        icon: "rss_feed",
        title: "Connections",
        group: "connections",
        items: [
            { prependIcon: "", title: "Sms", name: "AsterSms", permission: ['template:sms:view'], auth: true },
            { prependIcon: "", title: "Email", name: "AsterMail", permission: ['template:mail:view'], auth: true },
            { prependIcon: "", title: "Whatsapp", name: "AsterWhatsapp", permission: ['template:whatsapp:view'], auth: true },
            { prependIcon: "", title: "Web Hooks", name: "WebHooks", permission: ['connection:webhooks:view'], auth: true }
        ]
    },
    {
      icon: "phone",
      title: "Call Flow",
      name: "CallFlow",
      permission: ["call:flow:view"],
      auth: true
    },
   
]

export default AppDrawerItems;