import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.DoNotCall;
var getters = {
    DoNot: state => state.DoNotCall.data,
}
const mutations = {
    [mutation_type.DoNotCall]: (state, response) => {
        state.DoNotCall.data = response.data.data;

    }
};
const actions = {
    DoNotCall: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/dnc/fetch`).then((response) => {
                    var donotcall_response = response;
                    commit(mutation_type.DoNotCall, donotcall_response);
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    DeleteRowdata: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/dnc/del/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

};
export default {
    state,
    getters,
    mutations,
    actions
}