import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterLead;
var getters = {
    GetAsterLead: state => state.AsterLead.template_name,
    GetAsterLeadView: state => state.AsterLead.Lead
};
const mutations = {
    [mutation_type.AsterLead]: (state, response) => {
        state.AsterLead.template_name = response;
        state.AsterLead.Lead = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetAsterLead: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form/template`).then(response => {
                    const Lead = response.data;
                    commit(mutation_type.AsterLead, Lead);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterLeadData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form/template?template_name=${query}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetAsterLeadField: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form/template/fields?template_name=${query}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetAsterLeadData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form?template_name=${query.name}&skip=${query.skip}&take=${query.take}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    if (response.data.statusCode === 204) {
                        resolve({ data: [], totalCount: 0 });
                    } else {
                        resolve(response.data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertAsterData: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/form`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })

                .catch(function(error) {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterLead: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form?template_name=${query.template_name}&name=${query.name}&value=${query.value}`).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FilterAsterLeads: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form?template_name=${query.template_name}&list_id=${query.list_id}&phone_number=${query.phone_number}`).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    updateAsterLead: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                let id = payload.id;
                let name = payload.template_name;
                delete payload.id;
                delete payload.template_name;
                api
                    .put(`/aster/form/${name}/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterLead: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/form/${payload.template_name}/${payload.delete_id}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    Viewdata: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.AsterLead, query);
            } catch (error) {
                reject(error);
            }
        });
    },
    AssignAgent: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .put(`/aster/lead/assign`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};