<template>
  <v-container fluid grid-list-xl class="pb-0">
    <v-toolbar flat extended class="transparent section-definition-toolbar">
      <v-avatar class="box-glow" :tile="tile">
        <i  v-if="icon&&!isMaterialIconType" :class="['fas', [icon]]"></i>
        <i  v-else-if="icon&&isMaterialIconType" class="material-icons">{{icon}}</i>
        <span v-else>{{ title }}</span>
      </v-avatar>
      <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>
      <v-toolbar-title class="toobar-extension" slot="extension">
        <v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" class="pl-0">
          <v-icon slot="divider">chevron_right</v-icon>
        </v-breadcrumbs>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="exportOptions.length>0">
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            v-on="on"
          >
            Export
            <v-icon right dark>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-tile v-for="(option, index) in exportOptions" :key="index" @click="exportClick(option)">
            <v-list-tile-title>
              {{option}}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-menu offset-y v-if="addOptions.length>0">
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            v-on="on"
          >
            New
            <v-icon right dark>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-tile v-for="(option, index) in addOptions" :key="index" @click="addClick(option)">
            <v-list-tile-title>
              {{option}}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn-toggle :value="toggleButtonResComputed" mandatory v-if="extraOptions.toggleButton">
        <v-btn color="primary" small value="1" flat @click="toggleButtonClick('1')">
          Calls
        </v-btn>
        <v-btn color="primary" small value="2" flat @click="toggleButtonClick('2')">
          Time
        </v-btn>
      </v-btn-toggle>
      <v-btn  color="primary"  @click="addNew(newData)" v-for="newData in addNewData" :key="newData.label">
        {{newData.label}}
      </v-btn>
      <v-btn icon color="primary" flat @click="filterClick" v-if="extraOptions.filter">
        <i  v-if="icon" :class="['fas', 'fa-filter']"></i>
      </v-btn>
      <v-btn icon color="primary" flat @click="refreshClick" v-if="extraOptions.refresh">
        <i  v-if="icon" :class="['fas', 'fa-refresh']"></i>
      </v-btn>
       
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    tile: {
      type: Boolean,
      default: true
    },
    isMaterialIconType:{
      required:false,
      default:false
    },
    exportOptions:{
      required:false,
      default: function(){
        return [];
      }
    },
    addOptions:{
      required:false,
      default: function(){
        return [];
      }
    },
    addNewData:{
      required: false,
      default: function(){
        return [];
      }
    },
    extraOptions:{
      required: false,
      default: function(){
        return ({filter:false, refresh: false, toggleButton: false});
      }
    },
    toggleButtonRes:{
      required:false,
      default:"1"
    }
  },
  methods:{
    filterClick: function(){
      this.$emit("openFilter");
    },
    refreshClick: function(){
      this.$emit("refreshPage");
    },
    exportClick: function(option){
      this.$emit("exportData", option);
    },
    addClick: function(option){
      this.$emit("addData", option);
    },
    toggleButtonClick: function(option){
      this.$emit("toggleClick", option);
    },
    addNew: function(newData){
      this.$emit("addNew", newData);
    },
    
   
  },
  computed:{
    toggleButtonResComputed: function(){
      return this.toggleButtonRes;
    }
  }
};
</script>

<style lang="stylus" scoped>
.toobar-extension {
  margin-left: 5px !important;
}

.breadcrumb-section {
  margin-left: -25px !important;
}

.action-btn {
  margin-left: 5px !important;
}
</style>
