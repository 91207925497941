<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
  <div id="app">
    <v-container id="add-widget" fluid>
      <div class="row" align="center">
        <div class="d-flex" cols="12" sm="6">
          <v-icon
            color="primary"
            medium
            class="mr-4 addWidget"
            @click="showCustomizationPopup = true"
            >add</v-icon
          >
          <v-icon
            color="red"
            v-if="isEditing"
            small
            class="mr-4 addWidget"
            @click="editWidgets"
            >edit</v-icon
          >
          <v-icon
            color="green"
            v-else
            medium
            class="mr-4 addWidget"
            @click="updateWidgets"
            >save</v-icon
          >
        </div>
      </div>
    </v-container>

    <v-dialog persistent v-model="showCustomizationPopup" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Add Widgets To DashBoard</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <div style="display:flex;width:450px;" v-for="(item, index) in disabled_widgets" :key="index">
              <label style="padding-right:370px;padding-top:25px;padding-left:25px;width:250px;">{{ item }}</label>
              <v-switch
                @change="addNewWidgets(item)"
                color="primary"
                flat
              ></v-switch>
            </div>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="showCustomizationPopup = false">Cancel</v-btn>
          <v-btn color="primary" @click="addWidget">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="list" id="list">
      <VueDragResize
        v-for="(rect, index) in rects"
        :key="index"
        :w="rect.width"
        :h="rect.height"
        :x="rect.left"
        :y="rect.top"
        :parentW="listWidth"
        :parentH="listHeight"
        :axis="rect.axis"
        :isActive="rect.active"
        :minw="rect.minw"
        :minh="rect.minh"
        :isDraggable="rect.draggable"
        :isResizable="rect.resizable"
        :parentLimitation="rect.parentLim"
        :snapToGrid="rect.snapToGrid"
        :aspectRatio="rect.aspectRatio"
        :z="rect.zIndex"
        :component="rect.component"
        :componentIndex="index"
        :contentClass="rect.class"
        :timer="rect.timer"
        :id="rect.widget_id"
        :name="rect.name"
        v-on:activated="activateEv(index)"
        v-on:deactivated="deactivateEv(index)"
        v-on:dragging="changePosition($event, index)"
        v-on:resizing="changeSize($event, index)"
      >
        <div class="filler" :style="{ backgroundColor: rect.color }"></div>
      </VueDragResize>
    </div>

    <toolbar></toolbar>
  </div>
</template>

<style>
body {
  height: 100vh;
  width: 100vw;
  background-color: #ececec;
}

#app {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Lato", sans-serif;
}

.select-component {
  padding: 5px !important;
  margin: 0px !important;
  width: 150px;
  color: #2196f3 !important;
}

.filler {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.list {
  position: absolute;
  top: 30px;
  bottom: 10px;
  left: 30px;
  right: 30px;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 2px #aaa;
  background-color: white;
}

.box-shaddow {
  box-shadow: 10px 10px 15px 0px rgba(125, 125, 125, 1);
}

#add-widget {
  float: right;
  width: 160px;
  margin-top: 20px;
  margin-right: 0px;
  z-index: 4;
  background: #fff5f5;
}

.addWidget {
  z-index: 4;
}

.select-component { 
  z-index: 4;
}
</style>

<script>
import VueDragResize from "@/components/dashboard/components/draggable/vue-drag-resize.vue";
import toolbar from "@/components/dashboard/components/toolbar/toolbar.vue";
import "@/components/dashboard/icons";

export default {
  name: "app",

  components: {
    VueDragResize,
    toolbar,
  },

  data() {
    return {
      widget_name: "",
      data_table: "",
      widget_type: "",
      refresh_rate_sec: "",
      isShowOnDashboard: false,
      maxNoofRows: "",
      xaxis_column: "",
      yaxis_column: "",
      chart_type: "",
      column: "",
      maxNoofValues: "",
      listWidth: 0,
      listHeight: 0,
      selected_component: "",
      widget_types: ["Chart", "Table"],
      chart_types: ["Pie", "Doughnut", "Line", "Bar", "Horizontal Bar"],
      xaxis_columns: ["Disposition", "Time"],
      yaxis_columns: ["Inbound", "Outbound", "Drop", "Auto"],
      table_names: ["Agent Performance", "Call Summary"],
      showCustomizationPopup: false,
      seconds: [],
      max_data_per_page: 0,
      Axios: {},
      isEditing: true,
      disabled_widgets: [],
      enabled_widgets: []
    };
  },

  created() {
    const scope = this;
    scope.getDependencies();
    scope.loadWidgets();
    scope.$store.dispatch("rect/clearWidgets");
  },
  beforeDestroy() {
    const scope = this;
    scope.$store.dispatch("rect/clearWidgets");
  },

  mounted() {
    alert;
    let listEl = document.getElementById("list");
    this.listWidth = listEl.clientWidth;
    this.listHeight = listEl.clientHeight;

    window.addEventListener("resize", () => {
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
    });
    for (let i = 5; i <= 90; i++) {
      if (i % 5 == 0) {
        this.seconds.push(i);
      }
    }
  },

  computed: {
    rects() {
      return this.$store.state.rect.rects;
    },
    widgets() {
      return this.$store.state.rect.widgets;
    },
  },

  methods: {
    getDependencies() {
      const scope = this;
      scope.$store.dispatch("rect/config").then((response) => {
        scope.widget_types = response.widget_types;
        scope.chart_types = response.chart_types;
        scope.table_names = response.tables;
      });
    },
    addNewWidgets(value) {
      const scope = this;
      scope.enabled_widgets.push(value);
    },
    getColumns() {
      const scope = this;
      scope.$store
        .dispatch("rect/fetchTableColumn", scope.data_table)
        .then((response) => {
          scope.yaxis_columns = response;
          scope.xaxis_columns = response;
        });
    },
    loadWidgets() {
      const scope = this;
      scope.disabled_widgets = [];
      scope.$store.dispatch("rect/fetchWidgets",{name: "",type: ""}).then((response) => {
        response.forEach((element) => {
          if (element.isShowOnDashboard) {
            scope.$store.dispatch("rect/fetchWidgetData", element.id);
          }else {
            scope.disabled_widgets.push(element.name);
          }
        });
      });
    },
    addWidget() {
      const scope = this;
      scope.$store
        .dispatch("rect/changeWidget", scope.enabled_widgets)
        .then(() => {
          scope.showCustomizationPopup = false;
          scope.loadWidgets();
          scope.$store.dispatch("rect/clearWidgets");
          scope.widget_name = "";
          scope.data_table = "";
          scope.widget_type = "";
          scope.xaxis_column = "";
          scope.yaxis_column = "";
          scope.isShowOnDashboard = "";
          scope.chart_type = "";
          scope.maxNoofValues = 0;
          scope.maxNoofRows = 0;
          scope.refresh_rate_sec = 5;
        })
    },

    editWidgets() {
      this.$store.dispatch("rect/enableEdit");
      this.isEditing = false;
    },

    updateWidgets() {
      const scope = this;
      scope.$store
        .dispatch("rect/updateWidget")
        .then(() => {
          scope.$store.dispatch("rect/disableEdit");
          scope.loadWidgets();
          this.isEditing = true;
        })
    },

    // Default functions for widget operations
    openCustomization() {
      this.showCustomizationPopup = true;
    },

    activateEv(index) {
      this.$store.dispatch("rect/setActive", { id: index });
    },

    deactivateEv(index) {
      this.$store.dispatch("rect/unsetActive", { id: index });
    },

    changePosition(newRect, index) {
      this.$store.dispatch("rect/setTop", { id: index, top: newRect.top });
      this.$store.dispatch("rect/setLeft", { id: index, left: newRect.left });
      this.$store.dispatch("rect/setWidth", {
        id: index,
        width: newRect.width,
      });
      this.$store.dispatch("rect/setHeight", {
        id: index,
        height: newRect.height,
      });
    },

    changeSize(newRect, index) {
      this.$store.dispatch("rect/setTop", { id: index, top: newRect.top });
      this.$store.dispatch("rect/setLeft", { id: index, left: newRect.left });
      this.$store.dispatch("rect/setWidth", {
        id: index,
        width: newRect.width,
      });
      this.$store.dispatch("rect/setHeight", {
        id: index,
        height: newRect.height,
      });
    },
  },
};
</script>
