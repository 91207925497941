import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
var getters = {
    GetPauseCode: state => state.PauseCode.getdata
};
const mutations = {
    [mutation_type.PauseCode]: (state, response) => {
        state.PauseCode.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetPausecode1: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/pausecode`).then(response => {
                    const pausecode = response.data;
                    commit(mutation_type.PauseCode, pausecode);
                    const pausecode_array = [];
                    pausecode.data.forEach(item => {
                        pausecode_array.push(item.pause_code);
                    });
                    localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterPausecode: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/pausecode?pause_code=${query}`).then(response => {
                    const pausecode = response.data;
                    commit(mutation_type.PauseCode, pausecode);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertPausecode: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/pausecode`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deletePausecode: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/pausecode/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updatePausecode: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/pausecode/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};