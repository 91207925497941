import { HorizontalBar } from '../../config/BaseCharts'

export default {
    extends: HorizontalBar,
    props: {
        data: {
            type: Object,
            default: {},
        }
    },
    mounted() {
        this.renderChart(this.data, { responsive: true, maintainAspectRatio: false })
    },
    watch: {
        'data'(newVal) {
            this.renderChart(newVal, { responsive: true, maintainAspectRatio: false })
        }
    }
}