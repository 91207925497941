<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
    <div class="cdr-container">
        <h5 style="color: #000;">SLA Summary</h5>
        <div class="child-1">
            <v-card elevation="2">
                <h4>Total Calls</h4>
                <p>10</p>
            </v-card>
            <v-card elevation="2">
                <h4>Inbound Calls</h4>
                <p>20</p>
            </v-card>
            <v-card elevation="2">
                <h4>Outbound Calls</h4>
                <p>40</p>
            </v-card>
        </div>
        <div class="child-2">
            <v-card elevation="2">
                <h4>Success Calls</h4>
                <p>80</p>
            </v-card>
            <v-card elevation="2">
                <h4>Failed Calls</h4>
                <p>20</p>
            </v-card>
            <v-card elevation="2">
                <h4>Agent-dropped Calls</h4>
                <p>60</p>
            </v-card>
        </div>
        <div class="child-3">
            <v-card elevation="2">
                <h4>Sucess Rate</h4>
                <p>10000</p>
            </v-card>
            <v-card elevation="2">
                <h4>Failure Rate</h4>
                <p>101</p>
            </v-card>
            <v-card elevation="2">
                <h4>Test Calls</h4>
                <p>104</p>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style scoped>
.cdr-container {
    overflow: auto;
    display: flex;
    padding: 10px;
    flex-direction: column;
    margin: auto;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.4);
}

.cdr-container h5 {
    width: 100%;
    text-align: center;
    font-size: 11px;
    font-weight: bolder;
    line-height: 20px;
    color: #f56342 !important;
}

.cdr-container .child-1 {
    margin-bottom: 10px;
    display: flex;
    height: 30%;
    width: 100%;
}

.cdr-container .child-2 {
    margin-bottom: 10px;
    display: flex;
    height: 30%;
    width: 100%;
}

.cdr-container .child-3 {
    margin-bottom: 10px;
    display: flex;
    height: 30%;
    width: 100%;
}

.v-card {
    height: 100%;
    width: 33%;
    margin: 5px;
    padding: 5px;
    position: relative;
    border-radius: 5px;
}

.v-card h4 {
    width: 100%;
    text-align: center;
    font-size: 11px;
    font-weight: bolder;
    line-height: 20px;
    color: #f56342 !important;
}

.v-card p {
    position: absolute;
    top: 35%;
    width: 90%;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    color: #2196f3 !important;
}
</style>