/* eslint-disable */
import config from "@/config/index";
import { reject } from "lodash";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
let Crypto = require("crypto");
const state = config.userManagement;
import auth0Variables from '../../utils/auth0-variables';
import { data } from "jquery";
const auth0Config = auth0Variables();
const common_api_url = auth0Config.common_api_url;
let security_key = auth0Config.cryptosecuritykey;
let secret_iv = "smlt";
let method = 'AES-256-CBC'
let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)

function encrypt(text, method, key, iv) {
    let encrypt = Crypto.createCipheriv(method, key, iv)
    let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
    return Buffer.from(convert).toString("base64")
}
var getters = {
    usersdata: state => state.userManagement.usersdata,
    UserType: state => state.userManagement.UserType,
    userManagementFilter: state => state.userManagement.filter,
    QueueList: state => state.userManagement.QueueList,
    Createqueue: state => state.userManagement.Createqueue,
    AssignQueueList: state => state.userManagement.AssignQueueList
}
const mutations = {
    [mutation_type.userManagement]: (state, response) => {
        let usersdata = response.data.data;
        if (usersdata !== undefined) {
            usersdata = usersdata.map(userdata => {
                userdata.selectedroles = userdata.roles.map(role => {
                    return role.id;
                })
                return userdata;
            });
            state.userManagement.usersdata = usersdata;
        } else {
            state.userManagement.usersdata = [];
        }
    },
    [mutation_type.Createqueue]: (state, response) => {
        state.userManagement.Createqueue = response;
    },
    [mutation_type.UserType]: (state, response) => {
        state.userManagement.UserType = response;
    },
    [mutation_type.QueueList]: (state, response) => {
        state.userManagement.QueueList = response.data.data;
    },
    filter_userId: (state, response) => {
        state.userManagement.filter.UserId = response.user_id;
    },
    filter_Role: (state, response) => {
        state.userManagement.filter.Role = response.role;
    },
    filter_EmailId: (state, response) => {
        state.userManagement.filter.EmailId = response.user_email;
    },
    filter_MobileNumber: (state, response) => {
        state.userManagement.filter.MobileNumber = response.user_phone_number;
    }
};
const actions = {
    userManagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let phno = state.userManagement.filter.MobileNumber != "" ? state.userManagement.filter.MobileNumber : 0;
                api.get(`${common_api_url}/tenantUser?user_email=${state.userManagement.filter.EmailId}&user_phone_number=${phno}&user_id=${state.userManagement.filter.UserId}&limit=${payload.take}&offset=${payload.skip}&role=${state.userManagement.filter.Role}`).then(response => {
                    commit(mutation_type.userManagement, response);
                    resolve(response);
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    },
    filteruserManagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let phno = state.userManagement.filter.MobileNumber != "" ? state.userManagement.filter.MobileNumber : 0;
                api.get(`${common_api_url}/tenantUser?user_email=${state.userManagement.filter.EmailId}&user_phone_number=${phno}&user_id=${state.userManagement.filter.UserId}&role=${state.userManagement.filter.Role}`).then(response => {
                    var userManagement_response = response;
                    commit(mutation_type.userManagement, userManagement_response);
                    resolve(response);
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    },
    UserType: ({ commit }) => {
        try {
            api.get(`${common_api_url}/roles`).then(response => {
                var UserType_response = response.data.data;
                var UserType_response = UserType_response.map(res => {
                    return { id: res.id, name: res.name };
                })
                commit(mutation_type.UserType, UserType_response);
            }).catch(function (error) {

            })
        } catch (error) {

        }
    },
    QueueList: ({ commit }) => {
        return new Promise((resolve,) => {
            try {
                api.get(`${common_api_url}/queue`)
                    .then(response => {
                        var QueueList_response = response;
                        commit(mutation_type.QueueList, QueueList_response);
                        resolve(response);
                    }).catch(function (error) {
                        reject(error);
                    });
            } catch (error) {

            }
        })
    },
    getUserQueue: ({ }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                // let payloadata = encrypt(payload, method, key, iv)
                api.get(`${common_api_url}/queue/users?name=${payload}`).then(response => {
                    if (response.status == 200) {
                        let res = response.data.data;
                        resolve(res);
                    } else if (response.status == 204) {
                        var d = {
                            id: '',
                            name: payload,
                            interface: "",
                            user_rank: 0,
                            queue: []
                        };
                        resolve(d);
                    } else {
                        let error = "Invalid Response";
                        reject(error);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {

            }
        })
    },
    createQueueUser: ({ }, payload) => {
        // let temparr = []
        // payload.queue.forEach(elm => {
        //     let obj = {}
        //     if (elm.id == undefined) {
        //         obj.name = encrypt(elm.name, method, key, iv)
        //         obj.user_rank = encrypt(elm.user_rank.toString(), method, key, iv)
        //     } else {
        //         obj.id = encrypt(elm.id.toString(), method, key, iv)
        //         obj.name = encrypt(elm.name, method, key, iv)
        //         obj.user_rank = encrypt(elm.user_rank.toString(), method, key, iv)
        //     }

        //     temparr.push(obj)
        // });
        // console.log(payload.queue,"payload.queue",JSON.parse(localStorage.getItem("apiInfo")).tenant_code)
        payload.queue = payload.queue.map((elm)=>{
            elm.user_rank =  elm.user_rank.toString()
            return elm
        })
        return new Promise((resolve, reject) => {

            var Queue_assign = {
                data: [{
                    "name": payload.name,
                    "interface": payload.interface,
                    "queue": payload.queue,
                    "tenant_code":JSON.parse(localStorage.getItem("apiInfo")).tenant_code
                }]
            }
            // console.log(payload.name)
            api.post(`${common_api_url}/queue/user/v1/assign`, Queue_assign).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        })
    },
    DeleteQueueUser: ({ }, payload) => {
        return new Promise((resolve, reject) => {
            api.delete(`${common_api_url}/queue/user/${payload}`).then(response => {
                if (response.status == 200) {
                    resolve(response);
                } else {
                    reject('Error');
                }
            }).catch(error => {
                reject(error);
            })
        })
    },
    editedItem: ({ }, payload) => {
        var payloads = {
            "user_id": payload.user_id,
            "name": payload.name,
            "user_email": payload.user_email,
            "user_phone_number": payload.user_phone_number,
            "roles": [payload.roles],
            "project_id": payload.project_id,
            "status": payload.status.value
        }
        payloads.groups = payload.groups;
        return new Promise((resolve, reject) => {
            var dat = { data: payloads }
            api.put(`${common_api_url}/projectUsers?id=${payload.id}&user_email=${payload.user_email}`, dat).then(response => {
                resolve(response);
            })
                .catch(function edit(error) {
                    reject(error.response);
                })
        })
    },
    newlyUser: ({ }, payload) => {
        var userdata = {
            "project": {
                "id": localStorage.getItem("projectId")
            },
            "user": [{
                "user_email": payload.user_email,
                "user_phone_number": payload.user_phone_number,
                "user_id": payload.user_id,
                "name": payload.name,
                "auth": {
                    "password": payload.password,
                    "roles": [payload.roles]
                }
            }]
        }


        userdata.user[0].groups = payload.groups;
        return new Promise((resolve, reject) => {
            api.post(`${common_api_url}/projectUsers`, userdata).then(response => {
                resolve(response);
            })
                .catch(function (error) {
                    reject(error.response);
                })
        })
    },
    DeleteRow: ({ }, payload) => {
        var deleted_id = {
            "id": payload.id
        }
        var deletes = { data: deleted_id }
        return new Promise((resolve) => {
            api.delete(`${common_api_url}/deleteUser`, deletes).then(response => {
                resolve(response);
            })
        })
    },
    EditPassword: ({ }, payload) => {
        try {
            return new Promise((resolve, reject) => {
                api
                    .put(`${common_api_url}/userPassword?id=${payload.id}&user_email=${payload.user_email}`, { "password": payload.ConfirmPassword })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error.response.data);
                    })
            })
        } catch (err) {
            reject(err);
        }
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}