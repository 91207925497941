<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="asterleadData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0" @click="viewItem(props.item)">
            {{ props.item.template_name }}
          </td>
          <td class="checkEmpty p0" @click="viewItem(props.item)">
            {{ props.item.type }}
          </td>
          <td class="checkEmpty p0" @click="viewItem(props.item)">
            {{ props.item.active == "Y" ? "Yes" : "No" }}
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete AsterLead info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditAsterlead" max-width="1500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">{{ titleData }}</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-container fluid grid-list-xl page>
              <section-definition
                :exportOptions="page.exportOptions"
                @refreshPage="refreshThisPage"
                @addNew="AddNewRule"
                :style="styleObject"
              ></section-definition>
              <table style="width: 100%"></table>
              <v-data-table
                :headers="headers1"
                :items="AsterLeadValue"
                class="elevation-1"
                :pagination.sync="pagination"
                :total-items="pagecount"
                :loading="tableLoading"
                sort-desc="true"
              >
                <template v-slot:no-data style="text-align: center">
                  <div style="text-align: center">No Data Available</div>
                </template>
              </v-data-table>
            </v-container>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.template_name"
              :items="filtervalue"
              label="Name"
              small
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Leads",
        icon: "fa-clipboard",
        loading: false,
        isAddAsterlead: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditAsterlead: false,
        temp: { key: "addasterlead", label: "Add AsterLead" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Templates",
          align: "left",
          class: "p0",
          sortable: true,
          value: "template_name",
        },
        {
          text: "Type",
          align: "left",
          class: "p0",
          sortable: true,
          value: "type",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: true,
          value: "status",
        },
      ],
      headers1: [],
      asterleadData: [],
      AsterLeadFields: [],
      AsterLeadValue: [],
      titleData: "",
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          active: "",
          template_name: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      call_status: [],
      AsterFieldData: [],
      editAsterlead: {
        id: "",
        pause_code: "",
        type: "",
        description: "",
        status: "",
      },
      addAsterlead: {
        valid: false,
        payload: {
          pause_code: "",
          type: "",
          description: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
    };
  },
  methods: {
    refreshThisPage: function() {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function() {
      this.page.isFilterOpen = true;
    },

    fetchFilter: function() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      // if (scope.filter.payload.active === "Active") {
      //   scope.filter.payload.active = "Y";
      // }
      // if (scope.filter.payload.active === "Inactive") {
      //   scope.filter.payload.active = "N";
      // }
      // var data = {
      //   active: scope.filter.payload.active,
      //   template_name: scope.filter.payload.template_name,
      // };
      // console.log();
      this.$store
        .dispatch("FilterAsterLeadData", scope.filter.payload.template_name)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.asterleadData = datas;
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store
        .dispatch("GetAsterLead")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          scope.filter.payload.template_name = "";
          var datas = response.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            datas.forEach((element) => {
              scope.filtervalue.push(element["template_name"]);
            });
          }
          scope.asterleadData = datas;
        })
        .catch(function(error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditAsterlead = false;
        });
    },
    getDatafieldApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store
        .dispatch("GetAsterLeadField", scope.titleData)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;

          var datas = response.data[0].fields;
          scope.AsterLeadFields = datas;
          var newData = [];
          scope.headers1 = [];
          for (let i = 0; scope.AsterLeadFields.length > i; ++i) {
            newData.pop(scope.AsterLeadFields[i].model);
            const head = {
              text: scope.AsterLeadFields[i].model,
              align: "left",
              class: "p0",
              sortable: true,
              value: "model",
            };
            scope.headers1.push(head);
          }
          scope.headers1[0].header = newData;
          scope.AsterFieldData = [];
          for (let j = 0; scope.AsterLeadFields.length > j; ++j) {
            const head = scope.AsterLeadFields[j].model;
            scope.AsterFieldData.push(head);
          }
          this.getDataValueApi();
        })
        .catch(function(error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlead = false;
        });
    },
    getDataValueApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store
        .dispatch("GetAsterLeadData", scope.titleData)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.AsterLeadValue = [];
          // for (const property in datas) {
          //   scope.AsterLeadValue.push(`${datas[property]}`);
          // }
          scope.AsterLeadValue = datas;
        })
        .catch(function(error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlead = false;
        });
    },
    AddNewRule: function() {
      var scope = this;
      scope.page.isAddAsterlead = true;
    },
    closeEditRules: function() {
      this.page.isEditAsterlead = false;
    },
    closeAddPausecode: function() {
      this.page.isAddAsterlead = false;
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("UpdateFields", scope.editAsterlead)
        .then(() => {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlead = false;
        })
        .catch(function(error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlead = false;
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
      scope.$store
        .dispatch("insertPausecode", scope.addAsterlead.payload)
        .then(() => {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("Created Successfully");
        })
        .catch(function(error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      var scope = this;
      scope.$store
        .dispatch("GetFormFields", item.template_name)
        .then(() => {
          scope.$router.push({
            name: "crm/EditAsterLead",
          });
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
    },
    viewItem(item) {
      var scope = this;
      scope.titleData = item.template_name;
      scope.$store.dispatch("Viewdata", item.template_name);
      scope.$router.push({
        name: "crm/Table",
      });

      scope.page.isEditAsterlead = true;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      let payload = {
        template_name: "New_form",
        delete_id: 7,
      };
      this.$store
        .dispatch("deleteAsterLead", payload)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("AsterLead Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete AsterLead");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete AsterLead");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function() {
        // if(scope.$store.getters.UserPermissions.indexOf('leadmanagement:campaigns:create') !== -1){
        //   scope.page.addnew = [{ label: "Add Campaign" }];
        // }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    //this.page.addnew = [{ label: "Add Lead" }];
    // scope.page.candelete = true;
    // scope.page.canedit = true;
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100% !important;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
.table {
  border: 1px;
}
</style>
