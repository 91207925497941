<template>
  <div>
    <section-definition
      :title="pagecontrol.title"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshClick"
      @addNew="NewItemQueue"
      :style="styleObject"
    ></section-definition>
    <loader v-if="pagecontrol.loading"></loader>
    <v-container v-else fluid grid-list-xl>
      <v-layout row>
        <v-flex xs12 v-if="control">
          <v-card class="colors">
            <v-toolbar color="white" class="colos">
              <v-toolbar-title>Create</v-toolbar-title>
            </v-toolbar>
            <v-form ref="adduserform" v-model="valid">
              <v-container
                id="scroll-target"
                style="max-height: 600px"
                class="scroll-y"
              >
                <v-layout
                  v-scroll:#scroll-target="onScroll"
                  column
                  style="height: 400px;"
                >
                  <v-flex xs12>
                    <v-text-field
                      v-model="NewQueue.name"
                      label="Queues"
                      required
                      :rules="[(v) => !!v || 'Queues is required']"
                    ></v-text-field>
                    <div
                      v-if="error_name == 'Queues name already exist'"
                      class="alert-danger"
                    >
                      Queues name already exists!
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="NewQueue.account_code"
                      label="Account Code*"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                      required
                      :rules="[(v) => !!v || 'Account code is number']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="NewQueue.announce_holdtime"
                      :items="permision"
                      label="Announce"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="NewQueue.maxlen"
                      label="Queues Length"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="NewQueue.timeout"
                      label="Wait TimeOut"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="NewQueue.musiconhold"
                      label="Music On Hold"
                      required
                      :rules="[(v) => !!v || 'music on hold  is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="NewQueue.strategy"
                      :items="Strategy"
                      label="Ring Strategy"
                      required
                      :rules="[(v) => !!v || 'Ring Strategy is required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="NewQueue.retry"
                      :items="retry"
                      label="Retry"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="NewQueue.timeoutrestart"
                      :items="permision"
                      label="Timeout Restart"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="CloseNewQueue()">Cancel</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="SaveNewQueue()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 v-else>
          <v-card class="colors">
            <v-toolbar color="white" class="colos">
              <v-toolbar-title>Update Queues</v-toolbar-title>
            </v-toolbar>
            <v-form ref="edituserform" v-model="valid">
              <v-container
                id="scroll-target"
                style="max-height: 600px"
                class="scroll-y"
              >
                <v-layout
                  v-scroll:#scroll-target="onScroll"
                  column
                  style="height: 400px;"
                >
                  <v-flex xs12>
                    <v-text-field
                      v-model="edit.name"
                      label="Queues"
                      required
                      readonly
                      :rules="[(v) => !!v || 'Queues is required']"
                    ></v-text-field>
                    <div
                      v-if="error_edit_name == 'Queues name already exist'"
                      class="alert-danger"
                    >
                      Queues name already exists!
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="edit.account_code"
                      label="Account Code*"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                      required
                      :rules="[(v) => !!v || 'Account Code is number']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="edit.announce_holdtime"
                      :items="permision"
                      label="Announce"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="edit.maxlen"
                      label="Queues Length"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="edit.timeout"
                      label="Wait TimeOut"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="edit.musiconhold"
                      required
                      :rules="[(v) => !!v || 'music on hold  is required']"
                      label="Music On Hold"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="edit.strategy"
                      :items="Strategy"
                      label="Ring Strategy"
                      required
                      :rules="[(v) => !!v || 'Ring Strategy is required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="edit.retry"
                      :items="retry"
                      label="Retry"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="edit.timeoutrestart"
                      :items="permision"
                      label="Timeout Restart"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="CloseEdit()">Cancel</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="SaveEdit()"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex class="viewpage">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-icon>table_chart</v-icon>
              <v-toolbar-title>Queues</v-toolbar-title>
              <template class="text-xs-left">
                <v-menu offset>
                  <template v-slot:activator="{}">
                    <v-card-actions>
                      <v-autocomplete
                        class="autocomplete"
                        label="Queues"
                        v-model="appliedfilter.Queue"
                        :items="Queue"
                      ></v-autocomplete>
                    </v-card-actions>
                  </template>
                </v-menu>
              </template>
            </v-toolbar>
            <v-container
              id="scroll-target"
              style="max-height: 600px"
              class="scroll-y"
            >
              <v-layout column style="height: 430px;">
                <v-list
                  v-if="queuedata.length > 0"
                  two-line
                  :pagination.sync="pagination"
                >
                  <template v-for="item in queuedata">
                    <v-list-tile avatar ripple :key="item.id">
                      <v-list-tile-content>
                        <v-list-tile-title
                          class="text--primary"
                          style="font-size:16px;"
                        >
                          {{ item.name }}
                        </v-list-tile-title>
                        <v-list-tile-title>{{
                          item.strategy
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <td>
                          <v-icon
                            :key="item.queuedata"
                            color="green"
                            small
                            class="mr-4"
                            @click="editqueuedata(item)"
                            >edit</v-icon
                          >
                          <v-icon
                            color="red"
                            small
                            class="mr-4"
                            @click="deletequeuedata(item)"
                            >delete</v-icon
                          >
                        </td>
                      </v-list-tile-action>
                    </v-list-tile>
                  </template>
                </v-list>
                <div v-else class="alert-dangers">No data found</div>
              </v-layout>
            </v-container>
          </v-card>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="Deleterow" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Detele user info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text
              >Are you sure to delete this Queues information.</v-card-text
            >
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";
// import QueueManagementFilter from "./Components/QueueManagementFilter";
export default {
  components: {
    loader,
    // QueueManagementFilter
  },
  data() {
    return {
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      offsetTop: 0,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      pagecontrol: {
        title: "Queue Management",
        icon: "fa-user",
        loading: true,
        isFilterOpen: true,
        isAddQueue: false,
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [{ label: "Add Queues" }],
        isAddUser: false,
      },
      control: true,
      page: "1",
      delete_id: "",
      totalCount: "",
      error_name: "",
      error_edit_name: "",
      pageCount: 5,
      valid: true,
      pagination: { page: 1, totalCount: "", rowsPerPage: "5" },
      queueedit: false,
      validation: true,
      Deleterow: false,
      nodata: "",
      headers: [
        { text: "Name", align: "left", sortable: false, value: "name" },
        { text: "Strategy", align: "left", sortable: false, value: "strategy" },
        { text: "Action", align: "center", sortable: false, value: "btn" },
      ],
      payload: {
        skip: 0,
        take: 0,
      },
      queryperpages: "",
      querypages: "",
      editedIndex: -1,
      edit: {
        name: "",
        announce_holdtime: "",
        timeout: "",
        retry: "",
        maxlen: "",
        strategy: "",
        timeoutrestart: "",
        account_code: "",
      },
      NewQueue: {
        name: "",
        timeout: "",
        retry: "",
        maxlen: "",
        strategy: "",
        timeoutrestart: "",
        account_code: "",
      },
      appliedfilter: {
        Queue: "",
        queue: "",
        flag: "",
      },
      permision: ["yes", "no"],
      Strategy: ["Random", "RingAll", "FewestCalls", "Leastrecent"],
      retry: ["0", "1", "2", "3", "4", "5"],
      TimeDuring: ["1", "2", "3"],
      queueperpaages: ["5", "10", "15", "20"],
      Queue: [],
    };
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    refreshClick: function() {
      this.pagecontrol.loading = true;
      this.skip = 0;
      this.appliedfilter.Queue = "";
      this.getDataFromApi();
      this.error_name = "";
      this.error_edit_name = "";
    },
    filterClick: function() {
      this.pagecontrol.isFilterOpen = true;
    },
    isFilterCancel() {
      this.pagecontrol.isFilterOpen = false;
    },
    NewItemQueue() {
      this.control = true;
    },
    editqueuedata(item) {
      this.control = false;
      this.editedIndex = this.queuedata.indexOf(item);
      this.edit = Object.assign({}, item);
    },
    CloseEdit() {
      this.control = true;
      this.$refs.edituserform.reset();
      this.edit = "";
    },
    SaveEdit() {
      var scope = this;
      if (this.editedIndex > -1) {
        Object.assign(this.queuedata[this.editedIndex], this.edit);
        this.$store
          .dispatch("edit", this.edit)
          .then(() => {
            scope.error_edit_name = "";
            this.$refs.edituserform.reset();
            scope.showSnackBar("Updated Successfully");
          })
          .catch(function(error) {
            scope.showSnackBar("error" + error);
          });
      } else {
        this.queuedata.push(this.edit);
      }
    },
    resetvalue() {
      this.skip = 0;
      this.appliedfilter.Queue = "";
    },
    deletequeuedata(item) {
      this.Deleterow = true;
      this.delete_id = item;
    },
    CloseNewQueue() {
      this.control = true;
      this.$refs.adduserform.reset();
    },
    SaveNewQueue() {
      var scope = this;
      this.queuedata.push(Object.assign({}, this.NewQueue));
      this.totalCount = this.queuedata.length;
      this.$store
        .dispatch("NewQueue", this.NewQueue)
        .then(() => {
          scope.error_name = "";
          this.$refs.adduserform.reset();
          scope.showSnackBar("Created Successfully");
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
    },
    getpagedata(value) {
      this.querypages = value;
      this.pagination.rowsPerPage = value;
      this.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      if (this.querypages == 0) {
        this.querypages = 5;
      } else {
        this.querypages;
      }
      var rowsPage = this.querypages;
      var { page } = this.pagination;
      if (rowsPage == -1) {
        this.payload.rowsPage = this.totalCount;
      } else {
        this.payload.take = rowsPage;
      }
      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise(() => {
        this.$store
          .dispatch("queueManagement", this.payload)
          .then((response) => {
            this.pagecontrol.loading = false;
            if (scope.page_flag) {
              this.pagination.page = 1;
              scope.page_flag = false;
            }
            scope.totalCount = response.data.totalCount;
            scope.pagecount = scope.totalCount;
          });
      });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },
    savedelete(item) {
      this.queuedata.splice(item, 1);
      this.$store.dispatch("Deleterow", this.delete_id).then(() => {
        this.showSnackBar("Deleted Successfully");
        this.getDataFromApi();
      });
      this.closedelete();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    closedelete() {
      this.Deleterow = false;
    },
  },
  computed: {
    ...mapGetters(["queuedata"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.totalCount == null) {
        return 0;
      } else {
        var PerPages = Math.ceil(this.totalCount / this.pagination.rowsPerPage);
        return PerPages;
      }
    },
  },
  watch: {
    "appliedfilter.Queue"(newVal) {
      if (newVal === undefined) {
        newVal = "";
      }

      this.$store.commit("filter_Queue", { name: newVal });
      this.getDataFromApi();
    },
    pagination: {
      handler() {
        this.getDataFromApi().then((data) => {
          this.totalCount = data.totalCount;
        });
      },
      deep: true,
    },
    queryperpages(item) {
      this.queryperpages = item;
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
    this.$store
      .dispatch("queue")
      .then((response) => {
        this.Queue = response;
      })
      .catch(() => {
        this.Queue = [];
      });
  },
  refreshvalue() {
    this.$store.commit("filter_Queue", { name: "" });
  },
  beforeDestroy() {
    this.$store.commit("filter_Queue", { name: "" });
  },
};
</script>
<style>
.colors {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: rgba(0, 0, 0, 0.5);
}
.colos {
  box-shadow: none;
}
.mx-4 {
  width: 20px;
}
.alert-danger {
  color: red;
}
.alert-dangers {
  text-align: center;
  font-size: 20px;
}
.text-xs-left {
  padding-left: 10px;
  text-indent: 100px;
}
.show {
  text-align: left;
  font-size: 18px;
  position: relative;
  left: 55px;
  font-weight: 700;
}

.autocomplete {
  width: 225px;
}
.viewpage {
  width: 970px;
}
.button {
  right: 10px;
  min-width: 0;
}
</style>
