<template>
  <div>
    <v-layout wrap row ma-0>
      <!-- <v-flex xs12>
        <v-text-field v-model="appliedfilter.UserName" label="UserId"></v-text-field>
      </v-flex> -->
      <v-flex xs12>
        <v-text-field v-model="appliedfilter.name" label="Tag Name"></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["appliedfilter"],
  data() {
    return {
      page: {
        menu: {
          name: false,
        }
      },
      filter_name: "",
    };
  },
  methods: {
    CloseFilter() {
      this.pagecontrol.isFilterOpen = false;
    },
  },
  watch: {
     'appliedfilter.name'(newVal) {
      this.$store.commit("filter_name",{ name: newVal });
      this.$emit("getDataFrom");
    }
  },
  computed: { ...mapGetters(["TagManagementFilter"]) },
  mounted() {
  }
};
</script>