import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Timezone;
var getters = {
    GetTimezon: state => state.Timezone.getdata
};
const mutations = {
    [mutation_type.Timezone]: (state, response) => {
        state.Timezone.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetTimezone: ({ commit },query) => {
        let condition=""
        if(query.zone_name){      
              condition+=`&name=${query.zone_name}` 
          
        }
        if(query.status){
         
              condition+=`&status=${query.status}` 
          
        }
       
        return new Promise((resolve, reject) => {
            try {
                api.get(`timezone/fetch?skip=${query.skip}&take=${query.take}`+condition).then(response => {
                    
                    commit(mutation_type.CommitNothing, {});
                   
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },

    insertTimezone: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/timezone`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteTimezone: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/timezone/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateTimezone: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/timezone/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};