<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
  >
    <el-input
      type="textarea"
      v-bind="attributesBinding"
      :rows="2"
      :placeholder="
        currentField.isPlaceholderVisible ? currentField.placeholder : ''
      "
      v-model="textarea"
    ></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "LongTextInput",
  props: ["currentField"],
  data() {
    return {
      textarea: "",
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
};
</script>
