<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="table"
    class="elevation-1" :pagination.sync="pagination"


    >
      <template v-slot:items="props">
        <td class="checkEmpty p0">{{ props.item.campaign }}</td>
        <td class="checkEmpty p0">{{ props.item.list_id }}</td>
        <td class="checkEmpty p0">{{ props.item.total_leads }}</td>
        <td class="checkEmpty p0">{{ props.item.dialed_leads }}</td>
        <td class="checkEmpty p0">{{ props.item.available_leads }}</td>
       <td class="checkEmpty p0">{{ props.item.success }}</td>
        <td class="checkEmpty p0">{{ props.item.failed }}</td>
        <td class="checkEmpty p0">{{ props.item.recycle_count}}</td>
        
      </template>
    </v-data-table>
  </div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default{
		data(){
			return{
        headers: [
          { text: "Campaign", align: "left", sortable: false,class: "p0", value: "campaign_name" },
          { text: "List Id", align: "left", sortable: false,class: "p0", value: "list_id" },
          { text: "Total Leads", align: "left", sortable: false,class: "p0", value: "total_leads" },
          { text: "Dialed Leads", align: "left", sortable: false,class: "p0", value: "dial_leads" },
          { text: "Available Leads", align: "left", sortable: false,class: "p0", value: "" },
          { text: "Success", align: "left", sortable: false,class: "p0", value: "success" },
          { text: "Failure", align: "left", sortable: false,class: "p0", value: "failure" },
          { text: "Recycle Count", align: "left", sortable: false,class: "p0", value: "recycle_count" },
        ],
        pagination: {
        rowsPerPage: 10,
      },
      }
		},
    computed:{
    ...mapGetters([
       "table",
      ]), 
    }

	}
</script>
<style scoped>
	table.v-table tbody td, table.v-table thead th {
    height: 25px;
	}
</style>