<template>
  <div>
    <el-container>
      <el-main class="el-main-left">
        <div class="wrapper--forms">
          <el-form>

            <el-row v-if="forms.length === 0">
              <div class="empty-section">Please add Sections</div>
            </el-row>
            <template v-for="(eachFormObj, eachFormIndex) in forms">
              <div :key="`div-${eachFormIndex}`" class="section-block">
                <div class="source">
                  <el-row>
                    <el-col :span="15">
                          <!-- //section changed by abdul// -->
                          <el-input
                            placeholder="Please Enter Form Title"
                            v-model="eachFormObj.template_name"
                            style="width: 100%"
                          ></el-input> </el-col
                        ><el-col :span="4">
                          <el-button
                            style="margin-bottom:10px;margin-left:15px;"
                            v-if="methodeOf === 'Create'"
                            @click="CreateCustomForm"
                            type="primary"
                            >{{ methodeOf }}</el-button
                          >
                          <el-button
                            style="margin-bottom:10px;margin-left:15px;"
                            v-if="methodeOf === 'Update'"
                            @click="UpdateCustomForm"
                            type="primary"
                            >{{ methodeOf }}</el-button
                          ></el-col
                        >
                        <el-col :span="5">
                          <!-- //button changed by abdul// -->
                          <el-button
                            type="danger"
                            round
                            style="float: right"
                            @click="deleteSection(eachFormIndex, eachFormObj.title)"
                            >Reset Form</el-button
                          >
                        </el-col>
                  </el-row>
                </div>
                <div class="meta">
                  <el-row>
                    <draggable
                      :list="eachFormObj.fields"
                      class="dragArea"
                      :group="{ name: 'formbuilder', pull: false, put: true }"
                      :sort="true"
                      ghost-class="sortable__ghost"
                    >
                      <el-col
                        v-for="(field, index) in eachFormObj.fields"
                        :key="index"
                        v-bind="field"
                        :span="field.span"
                        class="form__group"
                        :class="{ 'is--active': field === activeField }"
                      >
                        <span class="form__selectedlabel">
                          {{ field.fieldType }}
                        </span>
                        <div @click="editElementProperties(field)">
                          <!-- <label class="form__label" v-model="form.label" v-show="form.hasOwnProperty('label')">{{ form.label }}</label> -->
                          <component
                            :is="field.fieldType"
                            :currentField="field"
                            class="form__field"
                          ></component>
                        </div>
                        <!-- Actions list -->
                        <div class="form__actiongroup">
                          <el-button
                            circle
                            size="mini"
                            icon="el-icon-rank"
                            class="form__actionitem--move"
                          ></el-button>
                          <el-button-group class="form__actionlist">
                            <el-button
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                cloneElement(index, field, eachFormObj.fields)
                              "
                              v-show="!field.isUnique"
                            ></el-button>
                            <el-button
                              size="mini"
                              icon="el-icon-delete"
                              @click="
                                deleteElement(
                                  index,
                                  eachFormObj.fields,
                                  eachFormIndex
                                )
                              "
                            ></el-button>
                          </el-button-group>
                        </div>
                      </el-col>
                      <!-- </div> -->
                    </draggable>
                  </el-row>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </el-main>
      <el-aside class="wrapper--sidebar" width="32%">
        <el-tabs type="border-card" :value="activeTabForFields">
          <el-tab-pane name="elements" label="Elements">
            <elements>{{ elements }}</elements>
          </el-tab-pane>
          <el-tab-pane name="properties" label="Properties">
            <properties
              v-show="Object.keys(activeField).length > 0"
            ></properties>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
    </el-container>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { FormBuilder } from "@/components/form_elements/formbuilder";
import { mapGetters } from "vuex";
import Elements from "@/components/form_elements/Elements";
import Properties from "@/components/form_elements/properties/Properties";
import TextInput from "@/components/form_elements/FormElementTextInput";
import Button from "@/components/form_elements/FormElementButton";
import Checkbox from "@/components/form_elements/FormElementCheckbox";
import DatePicker from "@/components/form_elements/FormElementDatePicker";
import DatetimePicker from "@/components/form_elements/FormElementDatetimePicker";
import HtmlComponent from "@/components/form_elements/FormElementHtml";
import LongTextInput from "@/components/form_elements/FormElementLongTextInput";
import NumberInput from "@/components/form_elements/FormElementNumberInput";
import RadioButton from "@/components/form_elements/FormElementRadioButton";
import Rating from "@/components/form_elements/FormElementRating";
import SelectList from "@/components/form_elements/FormElementSelectList";
import elswitch from "@/components/form_elements/FormElementSwitch";
import TableComponent from "@/components/form_elements/FormElementTable";
import TimePicker from "@/components/form_elements/FormElementTimePicker";
import Upload from "@/components/form_elements/FormElementUpload";
import RatingAdvancedProps from "@/components/form_elements/properties/RatingAdvancedProps";
import TextInputAdvancedProps from "@/components/form_elements/properties/TextInputAdvancedProps.vue";
import HtmlAdvancedProps from "@/components/form_elements/properties/HtmlAdvancedProps.vue";
import NumberInputAdvancedProps from "@/components/form_elements/properties/NumberInputAdvancedProps.vue";
import OptionsAdvancedProps from "@/components/form_elements/properties/OptionsAdvancedProps.vue";
import SelectListAdvancedProps from "@/components/form_elements/properties/SelectListAdvancedProps.vue";
import draggable from "vuedraggable";

export default {
  name: "Home",
  components: {
    Elements,
    Properties,
    RatingAdvancedProps,
    TextInputAdvancedProps,
    HtmlAdvancedProps,
    NumberInputAdvancedProps,
    OptionsAdvancedProps,
    SelectListAdvancedProps,
    draggable,
    TextInput,
    Button,
    Checkbox,
    DatePicker,
    DatetimePicker,
    HtmlComponent,
    LongTextInput,
    NumberInput,
    RadioButton,
    Rating,
    SelectList,
    elswitch,
    TableComponent,
    TimePicker,
    Upload,
  },
  data() {
    return {
      sortElementOptions: FormBuilder.$data.sortElementOptions,
      elements: [],
      methodeOf: "Save",
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "forms",
      "activeField",
      "activeTabForFields",
      "formType",
      "PageMethode",
    ]),
  },
  mounted() {
    this.methodeOf = localStorage.getItem("PageMethode");
    const formData = JSON.parse(localStorage.getItem("forms"));
    this.$store.dispatch("restoreForm", formData);
  },
  updated() {
    const scope = this;
    for (let i = 0; i < scope.forms[0].fields.length; i++) {
      scope.forms[0].fields[i].position = i + 1;
    }
  },
  methods: {
    deleteElement(index, form) {
      var scope = this;
      if (this.methodeOf == "Create") {
        this.$confirm(
          `Are you sure to delete the section ${scope.activeField.model}?`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          scope.forms[0].fields = scope.forms[0].fields.filter(function(obj) {
            return obj.position !== scope.activeField.position;
          });
          scope.showSnackBar("Field was deleted successfully!");
        });
      }
      if (this.methodeOf == "Update") {
        this.$confirm(
          `This may cause data lose!`,
          "Warning!",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          this.$confirm(
            `Are you sure to delete ${scope.activeField.model}?`,
            "Confirmation",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            scope.$store
              .dispatch("deleteCustomformField", {
                template_name: scope.forms[0].template_name,
                model: scope.activeField.model,
              })
              .then((response) => {
                if (response.statusCode === 200) {
                  scope.showSnackBar("Field was deleted successfully!");
                  FormBuilder.deleteElement(index, form);
                  scope.$store.dispatch("restoreForm", scope.forms);
                } else if (response.statusCode === 204) {
                  FormBuilder.deleteElement(index, form);
                  scope.$store.dispatch("restoreForm", scope.forms);
                }
              })
              .catch(() => {
                scope.isProcessing = false;
                scope.forms[0].fields = scope.forms[0].fields.filter(function(
                  obj
                ) {
                  return obj.position !== scope.activeField.position;
                });
                scope.showSnackBar("Field was deleted successfully!");
              });
          });
        });
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    cloneElement(index, field, form) {
      FormBuilder.cloneElement(index, field, form);
    },
    editElementProperties(field) {
      // console.log("form ->", this.forms);
      // console.log("activeField ->", this.activeField);
      FormBuilder.editElementProperties(field);
    },
    CreateCustomForm() {
      const scope = this;
      var Form = scope.forms[0].fields;
      var i = 1;
      var conversion = Form.map((value) => {
        for (var key in value) {
          if (
            value.link_property !== "" &&
            value.link_property !== undefined &&
            value.link_property &&
            value.fieldType === "TextInput" &&
            key === "link_property"
          ) {
            value[key] = { map: value.link_property };
          }
          if (value[key] === 0 && key === "position") {
            value[key] = i;
          }
          if (
            value.hasOwnProperty(key) &&
            key === "values" &&
            !Array.isArray(value.values)
          ) {
            value[key] = [value[key]];
          }
        }
        i = i + 1;
        return value;
      });
      let split = true;
      const payload = [{ fields: conversion }];
      if (scope.forms[0].template_name) {
        payload[0].template_name = scope.forms[0].template_name;
        if (
          payload[0].template_name.split(" ").length > 1 ||
          payload[0].template_name.split("-").length > 1
        ) {
          scope.$alert(
            `Error: Title is not allowed to space/hypens !`,
            "Warning",
            {
              type: "warning",
            }
          );
          split = false;
        }
      }
      if (scope.forms[0].type) {
        payload[0].type = scope.forms[0].type;
      }
      if (scope.forms[0].active) {
        payload[0].active = scope.forms[0].active;
      } else {
        payload[0].active = "Y";
      }
      // (payload, "value");
      if (split) {
        scope.$store
          .dispatch("CreateCustomForm", payload)
          .then((response) => {
            // console.log("response", response);
            if (response.statusCode === 201) {
              scope.showSnackBar("Form was created successfully!");
              scope.$router.push({
                name: "crm/CustomFormList",
              });
            } else {
              scope.$alert(`Error: ${response.message}!`, "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              });
            }
          })
          .catch(() => {
            scope.isProcessing = false;
            // console.log("Error", err);
          });
      }
    },
    UpdateCustomForm() {
      const scope = this;
      var originalForm = JSON.parse(localStorage.getItem("originalForm"));
      const originalFields = originalForm[0].fields.map((value) => {
        return value.model;
      });
      const edittedFields = scope.forms[0].fields.map((value) => {
        return value.model;
      });
      var difference = edittedFields.filter(
        (x) => originalFields.indexOf(x) === -1
      );
      const AddedFields = scope.forms[0].fields.filter(
        (x) => difference.indexOf(x.model) !== -1
      );
      scope.updateModifiedFields().then((promiseResponse) => {
        if (AddedFields.length > 0 && promiseResponse.message === "Success") {
          var conversion = AddedFields.map((value) => {
            for (var key in value) {
              let k = 0;
              for (let i = 0; edittedFields.length > i; ++i) {
                if (originalFields[i] !== edittedFields[i]) {
                  k = i;
                  if (value[key] === 0 && key === "position") {
                    value[key] = k;
                  }
                }
              }
              if (
                value.hasOwnProperty(key) &&
                key === "values" &&
                !Array.isArray(value.values)
              ) {
                value[key] = [value[key]];
              }
            }
            return value;
          });

          const payload = [{ fields: conversion }];
          if (scope.forms[0].template_name) {
            payload[0].template_name = scope.forms[0].template_name;
          }
          this.$store
            .dispatch("CreateNewField", payload)
            .then((response) => {
              //// console.log(response);
              if (response.message === "Success") {
                scope.showSnackBar("Field was updated successfully!");
                scope.$router.push({
                  name: "crm/CustomFormList",
                });
              }
            })
            .catch((err) => {
              this.$alert(`Error: ${err.data.message}!`, "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              });
            });
        } else if (promiseResponse.message === "Failure") {
          this.$alert(`${promiseResponse.error}`, "Warning", {
            type: "warning",
          });
        } else if (promiseResponse.message === "Success") {
          scope.$router.push({
            name: "crm/CustomFormList",
          });
        } else {
          this.$alert(`Error: Please Add New Fields!`, "Warning", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          });
        }
      });
    },
    updateModifiedFields() {
      var scope = this;
      return new Promise((resolve, reject) => {
        var originalForm = JSON.parse(localStorage.getItem("originalForm"));
        const updatedObject = [];
        const insertedModels = [];

        scope.forms[0].fields.map((object) => {
          originalForm[0].fields.map((object1) => {
            Object.keys(object).forEach((key) => {
              if (
                ((object[key] !== object1[key] &&
                  object["model"] === object1["model"]) ||
                  (object["model"] === object1["model"] &&
                    ((key === "values" &&
                      (object["fieldType"] === "SelectList" ||
                        object["fieldType"] === "Checkbox" ||
                        object["fieldType"] === "RadioButton")) ||
                      (key === "link_property" &&
                        object["is_link"] &&
                        object["link_type"] === "Child")) &&
                    JSON.stringify(object[key]) !==
                      JSON.stringify(object1[key]))) &&
                !object["isdefaultfield"]
              ) {
                if (!insertedModels.includes(object["model"])) {
                  if (!Array.isArray(object.values)) {
                    object.values = [object.values];
                  }
                  updatedObject.push(object);
                }
                insertedModels.push(object["model"]);
              }
            });
            return object1;
          });
          return object;
        });
        scope.forms[0].fields.map((object) => {
          for (var key in object) {
            if (
              object.link_property !== "" &&
              object.link_property !== undefined &&
              object.link_property &&
              object.fieldType === "TextInput" &&
              key === "link_property"
            ) {
              object[key] = { map: object.link_property };
            }
          }
          return object;
        });
        let isDefaultModified = false;
        updatedObject.map((data) => {
          if (data.isdefaultfield) {
            this.$alert("You cannot modify system field", "Warning", {
              type: "warning",
            });
            isDefaultModified = true;
          }
        });
        if (!isDefaultModified) {
          if (updatedObject.length > 0) {
            this.$store
              .dispatch("UpdateFields", {
                fields: updatedObject,
                template_name: this.forms[0].template_name,
              })
              .then((response) => {
                if (response.message === "Success") {
                  resolve(response);
                }
              })
              .catch((err) => {
                err.data.message = "Failure";
                reject(err.data);
              });
          } else {
            resolve({ message: "Success" });
          }
        }
      });
    },
    addSection() {
      var scope = this;
      const formObj = {
        template_name: "",
        active: "Y",
        type: scope.formType,
        fields: [],
      };
      this.forms.push(formObj);
    },
    // delete section in changed by abdul

    deleteSection() {
      var scope = this;
      this.$confirm(
        `Are you sure to delete the section ${scope.forms[0].template_name}?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        scope.showSnackBar("Section was deleted successfully!");
        // this.$delete(this.forms, formIndex);
        scope.$store
          .dispatch("GetDefaultFields", scope.forms[0].type)
          .catch(function(error) {
            scope.showSnackBar("error" + error);
          });
      });
    },
  },
  beforeDestroy() {
    // this.activeField = [];
    this.$store.dispatch("clearForm");
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: linear-gradient(to bottom, #fff, #409eff);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
  height: calc(90vh);
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }

    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
}
</style>
