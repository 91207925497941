<template>
  <div id="callLogReportFilter">
    <div class="datetimefilter">
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>From</label>
        <div>
          <datetime
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="filter_fromdate"
          ></datetime>
        </div>
      </div>
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>To</label>
        <div>
          <datetime
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="filter_todate"
          ></datetime>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <v-text-field
      v-model="appliedfilter.phonenumber"
      :items="filters.phonenumber"
      label="Phone Number"
      :allow-overflow="false"
    >
    </v-text-field>
    <v-autocomplete
      v-model="appliedfilter.type"
      :items="filters.type"
      label="Call Type"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
    <v-text-field
      v-model="appliedfilter.station"
      :items="filters.station"
      label="Station"
      :allow-overflow="false"
    >
    </v-text-field>
    <v-autocomplete
      v-model="appliedfilter.agent"
      :items="filteragents"
      label="Agent"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
    <v-autocomplete
      v-model="appliedfilter.campaign"
      :items="filters.campaign"
      label="Campaign"
      persistent-hint
      small
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
    <v-text-field
      v-model="appliedfilter.uniqueid"
      :items="filters.uniqueid"
      label="Uniqueid"
      :allow-overflow="false"
    >
    </v-text-field>
    <v-text-field
      v-model="appliedfilter.did"
      :items="filters.did"
      label="DID"
      :allow-overflow="false"
    >
    </v-text-field>
    <v-autocomplete
      v-model="appliedfilter.queue"
      :items="filterqueue"
      label="Queue"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>

    <v-autocomplete
      v-model="appliedfilter.dispo"
      :items="filterdispo"
      label="Dispo Status"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
    <v-autocomplete
      v-model="appliedfilter.status"
      :items="filters.status"
      label="Status"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
      {{ appliedfilter.template }}
    </v-autocomplete>
    <v-autocomplete
      v-model="appliedfilter.template"
      :items="filtertemplate"
      label="Template"
      persistent-hint
      small
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["appliedfilter"],
  data() {
    return {
      page: {
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      submit: "",
      filter_fromdate: "",
      filter_todate: "",
      filterqueue: [],
      filtertemplate: [],
      filterdispo: [],
      filteragents: [],
    };
  },
  watch: {
    appliedfilter: {
      handler: function () {
        if (
          this.appliedfilter.fromdate == this.filters.fromdate &&
          this.appliedfilter.todate == this.filters.todate
        ) {
          this.date_flag = false;
          this.filter_fromdate = this.appliedfilter.fromdate;
          this.filter_todate = this.appliedfilter.todate;
        }
      },
      deep: true,
    },
    filter_fromdate(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      // this.$emit("getDataFrom");
    },
    filter_todate(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      // this.$emit("getDataFrom");
    },
    "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.template"(newVal) {
      this.$store.commit("FILTER_TEMPLATE", { template: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.uniqueid"(newVal) {
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.type"(newVal) {
      // var scope = this;
      this.$store.dispatch("type", newVal);
      // scope.$emit("getDataFrom");
    },
    "appliedfilter.phonenumber"(newVal) {
      this.$store.commit("FILTER_PHONE", { phone: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.campaign"(newVal) {
      this.$store.commit("FILTER_CAMPAIGN", { campaign: newVal });
    },
    "appliedfilter.group"(newVal) {
      this.$store.commit("FILTER_GROUP", { group: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.station"(newVal) {
      this.$store.commit("FILTER_STATION", { station: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.did"(newVal) {
      this.$store.commit("FILTER_DID", { did: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.queue"(newVal) {
      this.$store.commit("FILTER_QUEUE", { queue: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.dispo"(newVal) {
      this.$store.commit("FILTER_DISPO", { dispo: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.status"(newVal) {
      this.$store.commit("FILTER_STATUS", { status: newVal });
      // this.$emit("getDataFrom");
    },
  },

  computed: {
    ...mapGetters(["filters", "filter", "queue"]),
  },
  mounted() {
    // this.$store.dispatch("reportuserlist");
    // this.$store.dispatch("GetDisposition");
    // this.$store.dispatch("reportqueuelist");
    // this.$store.dispatch("reporttemplatelist");
    this.filteragents = JSON.parse(localStorage.getItem("group_users"));
    this.filterqueue = JSON.parse(localStorage.getItem("group_queue"));
    this.filterdispo = JSON.parse(localStorage.getItem("group_disposition"));
    this.filtertemplate = JSON.parse(localStorage.getItem("group_template"));
    this.filter_fromdate = moment(this.filters.fromdate).format();
    this.filter_todate = moment(this.filters.todate).format();
  },
};
</script>
