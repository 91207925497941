<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="asterlistData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.list_id }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.campaign }}</td>
          <td class="checkEmpty p0">{{ props.item.recycle_count }}</td>
          <td class="checkEmpty p0">{{ props.item.count }}</td>
          <td class="checkEmpty p0">
            {{ props.item.active == "Y" ? "Active" : "Inactive" }}
          </td>
          <!-- ///quick action are added by abdul///-->
          <td class="text-xs-center checkEmpty p0 sticky">
            <v-icon
              title="Download List"
              block
              :disabled="downloadtooltip"
              color="blue"
              small
              class="mr-4 fas fa-download"
              v-if="page.canedit"
              @click="samplefiledownload(props.item.list_id)"
            ></v-icon>
            <v-icon
              title="Buffer Reset"
              block
              :disabled="buffertooltip"
              color="green"
              small
              class="mr-4 fas fa-refresh"
              v-if="page.canedit"
              @click="bufferreset(props.item.list_id)"
            ></v-icon>
            <v-icon
              title="List Reset"
              block
              :disabled="listtooltip"
              color="SlateBlue"
              small
              class="mr-4 fas fa-refresh"
              v-if="page.canedit"
              @click="listreset(props.item.list_id)"
            ></v-icon>
            <v-icon
              title="Recycle"
              block
              :disabled="recycletooltip"
              color="blue"
              small
              class="mr-4 fas fa-circle-o-notch"
              v-if="page.canedit"
              @click="listrecycle(props.item.id)"
            ></v-icon>
            <v-icon
              title="Edit"
              block
              :disabled="edittooltip"
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              title="Delete"
              block
              :disabled="deletetooltip"
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.list_id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete list info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you Sure ?.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddAsterlist" max-width="500px">
      <v-card>
        <v-form ref="createform" v-model="createvalid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create List</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="addAsterlist.payload.list_id"
                  required
                  label="List ID"
                  type="text"
                  :rules="[
                    (v) => !!v || 'List ID is required',
                    (v) =>
                      (!isNaN(parseFloat(v)) && v > 0) ||
                      'List ID Must Be Number',
                  ]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="addAsterlist.payload.name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="addAsterlist.payload.description"
                  label="Description"
                  type="text"
                  :rules="[(v) => !!v || 'Description is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addAsterlist.payload.campaign"
                  :items="addAsterlist.options.campaign"
                  label="Campaign"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  :rules="[(v) => !!v || 'Campaign is required']"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addAsterlist.payload.active"
                  :items="addAsterlist.options.active"
                  label="Active"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addAsterlist.payload.recycling"
                  :items="addAsterlist.options.recycling"
                  label="Recycling"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn color="primary" flat @click="saveAddAsterlist()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditAsterlist" max-width="500px">
      <v-card>
        <v-form ref="updateform" v-model="updatevalid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update List</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="editAsterlist.list_id"
                  required
                  label="List ID"
                  type="text"
                  :rules="[
                    (v) => !!v || 'List ID is required',
                    (v) =>
                      (!isNaN(parseFloat(v)) && v > 0) ||
                      'List ID Must Be Number',
                  ]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="editAsterlist.name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="editAsterlist.description"
                  required
                  label="Description"
                  type="text"
                  :rules="[(v) => !!v || 'Description is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editAsterlist.campaign"
                  :items="addAsterlist.options.campaign"
                  label="Campaign"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Campaign is required']"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editAsterlist.active"
                  :items="addAsterlist.options.active"
                  label="Active"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editAsterlist.recycling"
                  :items="addAsterlist.options.recycling"
                  label="Recycling"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.name"
              :items="filtervalue"
              label="Name"
              small
            ></v-autocomplete>
          </div>
          <!-- //////filter is added by abdul/////// -->
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.campaign"
              :items="addAsterlist.options.campaign"
              label="Campaign"
              small
            ></v-select>
          </div>
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.status"
              :items="filter.options.status"
              label="Status"
              item-text="name"
              item-value="value"
              return-string
              persistent-hint
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      createvalid: true,
      updatevalid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      downloadtooltip: false,
      buffertooltip: false,
      listtooltip: false,
      recycletooltip: false,
      edittooltip: false,
      deletetooltip: false,
      page: {
        title: "List",
        icon: "fa-clipboard",
        loading: false,
        isAddAsterlist: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditAsterlist: false,
        temp: { key: "addasterlist", label: "Add AsterList" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "List ID",
          align: "left",
          class: "p0",
          sortable: true,
          value: "list_id",
        },
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name",
        },
        {
          text: "Campaign",
          align: "left",
          class: "p0",
          sortable: false,
          value: "campaign",
        },
        {
          text: "Recycle Count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "recycle_count",
        },
        {
          text: "Lead Count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "count",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          class: "sticky",
          align: "center",
          sortable: false,
          value: "btn",
          width: "25%",
        },
      ],
      asterlistData: [],
      // pagecount: 0,
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          name: "",
          campaign: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editAsterlist: {
        list_id: "",
        name: "",
        campaign: "",
        description: "",
        active: "",
        recycling: "",
      },
      addAsterlist: {
        valid: false,
        payload: {
          list_id: "",
          name: "",
          campaign: "",
          description: "",
          active: { name: "Active", value: "Y" },
          recycling: "",
        },
        options: {
          campaign: [],
          active: [
            { name: "Active", value: "Y" },
            { name: "Inactive", value: "N" },
          ],
          recycling: [
            { name: "1", value: "1" },
            { name: "2", value: "2" },
            { name: "3", value: "3" },
            { name: "4", value: "4" },
            { name: "5", value: "5" },
            { name: "6", value: "6" },
            { name: "7", value: "7" },
            { name: "8", value: "8" },
            { name: "9", value: "9" },
            { name: "10", value: "10" },
            { name: "11", value: "11" },
            { name: "12", value: "12" },
            { name: "13", value: "13" },
            { name: "14", value: "14" },
            { name: "15", value: "15" }, 
            { name: "16", value: "16" }, { name: "17", value: "17" },{ name: "18", value: "18" },{ name: "19", value: "19" },{ name: "20", value: "20" }
          ],
        },
      },
    };
  },
  methods: {
    refreshThisPage: function() {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },

    samplefiledownload(id) {
      let AsterLeadFields = "";
      this.$store.dispatch("downloadlistdata", id).then((response) => {
        var datas = response.data.data;
        AsterLeadFields = datas;
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
        const keys = Object.keys(AsterLeadFields[0]);
        let row = keys.join(",");
        csvContent += row + "\r\n";       
        AsterLeadFields.forEach(function(rows) {
          let data = Object.values(rows);
           let data1 = [];
              data.forEach((elm) => {
                  if (typeof elm === "string") {
                  data1.push(elm.replace(/[\\#^+()$~%,'";*?<>{}!]/g, " "));
                      // data1.push(elm.replace(/'|"|#|,|;|\n/g, " "));
                  } else {
                      data1.push(elm);
                  }
              });
          let row = data1.join(",");
          
          csvContent += row + "\r\n";
        });
        
        var encodedUri = encodeURI(csvContent);
       
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
         
        link.setAttribute("download", id + ".csv");
        document.body.appendChild(link);
        link.click();
      });
    },

    filterClick: function() {
      this.page.isFilterOpen = true;
    },

    fetchFilter: function() {
      var scope = this;
      scope.tableLoading = true;
      let status = "";
      if (scope.filter.payload.status == "Active") {
        status = "Y";
      }
      if (scope.filter.payload.status == "Inactive") {
        status = "N";
      }
      var data = {
        list_name: scope.filter.payload.name,
        list_campaign: scope.filter.payload.campaign,
        list_status: status,
      };
      this.page.isFilterOpen = false;
      this.$store
        .dispatch("FilterAsterList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.asterlistData = datas;
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.filter.payload.name = "";
      scope.filter.payload.campaign = "";
      scope.filter.payload.status = "";
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      scope.filter.payload.name = "";
      scope.filtervalue = [];
      this.$store
        .dispatch("GetAsterList")
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data !== "" &&
            response.data
          ) {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
              datas.forEach((element) => {
                scope.filtervalue.push(element["name"]);
              });
            }
            scope.asterlistData = datas;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            scope.asterlistData = [];
          }
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditAsterlist = false;
        });
    },
    AddNewRule: function() {
      this.page.isAddAsterlist = true;
    },
    closeEditRules: function() {
      this.page.isEditAsterlist = false;
    },
    closeAddPausecode: function() {
      var scope = this;
      scope.page.isAddAsterlist = false;
      scope.addAsterlist.payload = {
        list_id: "",
        name: "",
        campaign: "",
        description: "",
        active: { name: "Active", value: "Y" },
        recycling: "",
      };
      scope.$refs.createform.resetValidation();
    },
    saveEditRules() {
      var scope = this;
      this.$refs.updateform.validate();
      scope.page.isEditAsterlist = !this.$refs.updateform.validate();
      if (this.$refs.updateform.validate()) {
        scope.$store
          .dispatch("updateAsterList", scope.editAsterlist)
          .then(() => {
            scope.page.isAddAsterlist = false;
            scope.showSnackBar("Updateded Successfully");
            this.page.isEditAsterlist = false;
            scope.getDataFromApi();
          })
          .catch(function(error) {
            scope.page.isAddAsterlist = false;
            scope.showSnackBar("error" + error);
            this.page.isEditAsterlist = false;
          });
      }
      scope.getDataFromApi();
    },
    saveAddAsterlist() {
      var scope = this;
      this.$refs.createform.validate();
      scope.page.isAddAsterlist = !this.$refs.createform.validate();
      if (this.$refs.createform.validate()) {
        scope.$store
          .dispatch("insertAsterList", this.addAsterlist.payload)
          .then(() => {
            scope.showSnackBar("Created Succefully");
            scope.addAsterlist.payload.list_id = " ";
            scope.addAsterlist.payload.name = " ";
            scope.addAsterlist.payload.campaign = "";
            scope.addAsterlist.payload.description = "";
            scope.addAsterlist.payload.active = "";
            scope.addAsterlist.payload.recycling = "";
            scope.getDataFromApi();
          })
          .catch(function(error) {
            scope.page.isAddAsterlist = false;
            scope.showSnackBar("error" + error);
          });
      }

      // this.getDataFromApi();
      scope.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(list_id) {
      this.DeleteRowdata = true;
      this.delete_id = list_id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditAsterlist = true;
      this.editAsterlist.id = item.id;
      this.editAsterlist.list_id = item.list_id;
      this.editAsterlist.name = item.name;
      this.editAsterlist.campaign = item.campaign;
      this.editAsterlist.description = item.description;
      this.editAsterlist.active = item.active;
      this.editAsterlist.recycling = item.recycling;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAsterList", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("List Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },

    listrecycle(id) {
      var scope = this;
      scope.$store
        .dispatch("updateAsterListrecyclecount", id)
        .then(() => {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
      this.getDataFromApi();
    },

    listreset(id) {
      var scope = this;
      scope.$store
        .dispatch("listreset", id)
        .then(() => {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
      this.getDataFromApi();
    },

    bufferreset(id) {
      var scope = this;
      scope.$store
        .dispatch("bufferreset", id)
        .then(() => {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
        })
        .catch(function(error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
      this.getDataFromApi();
    },

    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    scope.$store.dispatch("GetCampaigns").then((response) => {
      scope.addAsterlist.options.campaign = response;
    });
    setTimeout(
      function() {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add List" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
