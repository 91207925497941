<template>
  <div class="main__wrapper">
    <section-definition
      :title="forms[0].template_name"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @refreshPage="refreshClick"
      :style="styleObject"
    ></section-definition>
    <v-container fluid grid-list-xl page>
      <el-container>
        <el-main :style="cssProps">
          <el-form>
            <template v-for="(eachFormObj, eachFormIndex) in formData">
              <el-row class="form-preview" :key="`row-${eachFormIndex}`">
                <div class="wrapper--forms">
                  <el-col
                    v-for="(field, index) in eachFormObj.fields"
                    :key="index"
                    :span="field.span"
                    v-bind="field"
                    class="el-form-item__label"
                    v-show="field.show_on_view"
                  >
                    <component
                      :is="field.fieldType"
                      :currentField="field"
                      class="form__field"
                    ></component>
                  </el-col>
                </div>
              </el-row>
            </template>
          </el-form>
        </el-main>
      </el-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="saveEditRules()">Create</v-btn>
        <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { FormBuilder } from "@/components/form_elements/formbuilder";
import { mapGetters } from "vuex";
var _ = require("lodash");

export default {
  name: "Publish",
  components: FormBuilder.$options.components,
  data() {
    return {
      pagecontrol: {
        title: "User Management",
        icon: "fa-user",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [],
        canedit: false,
        candelete: false,
        canassignqueue: false,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      formData: [],
    };
  },
  methods: {
    refreshClick() {
      const scope = this;
      let data = scope.forms[0].fields;
      for (let i = 0; data.length > i; ++i) {
        document.getElementById(data[i].model).value = "";
      }
    },
    closeEditRules() {
      const scope = this;
      scope.$router.push({
        name: "crm/Table",
      });
    },
    saveEditRules() {
      const scope = this;
      let data = scope.forms[0].fields;
      let template_name = scope.forms[0].template_name;
      let fields = [];
      data.forEach((element) => {
        if (element.fieldType === "Checkbox") {
          const values = [];
          let container = document.querySelector(`#${element.model}`);
          let checkboxesAll = container.querySelectorAll(
            'input[type="checkbox"]:checked'
          );
          for (let checkbox of checkboxesAll) {
            values.push(checkbox.value);
          }
          fields.push({ name: element.model, value: `${values}` });
        } else if (element.fieldType === "RadioButton") {
          let container = document.querySelector(
            `#${element.model}.el-radio.is-checked`
          );
          let selectedRadio = container.querySelector("span.el-radio__label");
          fields.push({
            name: element.model,
            value: `${selectedRadio.outerText}`,
          });
        } else if (
          element.model !== "ticket_id" &&
          element.model !== "lead_id" &&
          element.model !== "order_id" &&
          element.model !== "list_id" &&
          element.show_on_view
        ) {
          let ele = document.getElementById(element.model).value;
          fields.push({ name: element.model, value: ele });
        }
      });
      let payload = {
        template_name,
        fields,
      };
      scope.$store
        .dispatch("insertAsterData", payload)
        .then(() => {
          scope.showSnackBar("Created Successfully");
          scope.$router.push({
            name: "crm/Table",
          });
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
      for (let i = 0; data.length > i; ++i) {
        document.getElementById(data[i].model).value = "";
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
  },
  computed: {
    ...mapGetters(["forms", "themingVars", "GetAsterLeadInsert"]),
    cssProps() {
      var result = {},
        themingVars = this.themingVars;

      for (var v in themingVars) {
        if (themingVars.hasOwnProperty(v)) {
          var newV = "--theme-" + _.kebabCase(v),
            suffix = "";

          // Add px to the value if the default value contain 'px'
          if (_.includes(newV, "size")) suffix = "px";
          else if (_.includes(newV, "margin")) suffix = "px";
          else if (_.includes(newV, "radius")) suffix = "px";

          result[newV] = themingVars[v] + suffix;
        }
      }

      return result;
    },
  },
  mounted() {
    const scope = this;
    let data = scope.forms[0].fields.filter((field) => {
      return (
        field.model !== "ticket_id" &&
        field.model !== "lead_id" &&
        field.model !== "order_id" &&
        field.model !== "list_id"
      );
    });
    data.map((field) => {
      field.disabled = field.disabled ? !field.disabled : field.disabled;
      if (field.show_on_view && field.is_link && field.fieldType === 'TextInput') {
        field.show_on_view = false;
      }
      return field;
    });
    const stringForms = JSON.stringify(scope.forms);
    scope.formData = JSON.parse(stringForms);
    scope.formData[0].fields = data;
  },
};
</script>
<style>
.form-preview {
  border: 0px solid #ccc !important;
}
.el-form-item {
  padding: 200px 100px;
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 0px;
}
.headline {
  font-size: 20px !important;
  padding: 20px !important;
}
</style>
