<template>
  <div class="main__wrapper">
    <section-definition
      :title="pagecontrol.title"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @refreshPage="refreshClick"
      :style="styleObject"
    ></section-definition>
    <v-container fluid grid-list-xl page>
      <el-container>
        <el-main :style="cssProps">
          <el-form>
            <template v-for="(eachFormObj, eachFormIndex) in formData">
              <el-row class="form-preview" :key="`row-${eachFormIndex}`">
                <div class="wrapper--forms">
                  <el-col
                    v-for="(field, index) in eachFormObj.fields"
                    :key="index"
                    :span="field.span"
                    v-bind="field"
                    class="el-form-item__label"
                    v-show="field.show_on_view"
                  >
                    <component
                      :is="field.fieldType"
                      :currentField="field"
                      class="form__field"
                    ></component>
                  </el-col>
                </div>
              </el-row>
            </template>
          </el-form>
        </el-main>
      </el-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
        <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { FormBuilder } from "@/components/form_elements/formbuilder";
import { mapGetters } from "vuex";
var _ = require("lodash");

export default {
  name: "Publish",
  components: FormBuilder.$options.components,
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      formData: [],
      pagecontrol: {
        title: "",
        icon: "fa-user",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [],
        canedit: false,
        candelete: false,
        canassignqueue: false,
      },
    };
  },
  methods: {
    refreshClick: function() {
      var scope = this;
      let id_value = "";
      let id = "";
      for (const [key, value] of Object.entries(this.GetAsterLeadInsert)) {
        if (
          `${key}` === "order id" ||
          `${key}` === "ticket id" ||
          `${key}` === "lead id"
        ) {
          id = `${key}`.replace(" ", "_");
          id_value = `${value}`;
        }
      }
      let form = {
        template_name: this.GetAsterLead,
        name: id,
        value: id_value,
      };
      this.$store
        .dispatch("FilterAsterLead", form)
        .then((response) => {
          var datas = response.data[0];
          let one = [];
          for (let value of Object.values(datas)) {
            one.push(value);
          }
          let data = scope.forms[0].fields;
          for (let i = 0; data.length > i; ++i) {
            document.getElementById(data[i].model).value = one[i];
          }
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
    },
    AddNewRule: function() {
      var scope = this;
      scope.page.isAddAsterlead = true;
    },
    closeEditRules() {
      let scope = this;
      scope.$router.push({
        name: "crm/Table",
      });
    },
    saveEditRules() {
      let scope = this;
      let data = scope.forms[0].fields;
      let template_name = this.GetAsterLead;
      let fields = [];
      data.forEach((element) => {
        if (element.fieldType === "Checkbox") {
          const values = [];
          let container = document.querySelector(`#${element.model}`);
          let checkboxesAll = container.querySelectorAll(
            'input[type="checkbox"]:checked'
          );
          for (let checkbox of checkboxesAll) {
            values.push(checkbox.value);
          }
          fields.push({ name: element.model, value: `${values}` });
        } else if (element.fieldType === "RadioButton") {
          let container = document.querySelector(
            `#${element.model}.el-radio.is-checked`
          );
          let selectedRadio = container.querySelector("span.el-radio__label");
          fields.push({
            name: element.model,
            value: `${selectedRadio.outerText}`,
          });
        } else if (
          element.model !== "ticket_id" &&
          element.model !== "lead_id" &&
          element.model !== "order_id" &&
          element.model !== "list_id" &&
          element.show_on_view
        ) {
          let ele = document.getElementById(element.model).value;
          fields.push({ name: element.model, value: ele });
        }
      });
      let id_value = "";
      for (const [key, value] of Object.entries(this.GetAsterLeadInsert)) {
        if (
          `${key}` === "order id" ||
          `${key}` === "ticket id" ||
          `${key}` === "lead id"
        ) {
          id_value = `${value}`;
        }
      }
      let payload = {
        id: id_value,
        template_name,
        fields,
      };
      scope.$store
        .dispatch("updateAsterLead", payload)
        .then(() => {
          scope.showSnackBar("Updated Successfully");
          scope.$router.push({
            name: "crm/Table",
          });
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
      for (let i = 0; data.length > i; ++i) {
        document.getElementById(data[i].model).value = "";
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
  },
  computed: {
    ...mapGetters([
      "forms",
      "themingVars",
      "GetAsterLeadInsert",
      "GetAsterLead",
    ]),
    cssProps() {
      var result = {},
        themingVars = this.themingVars;

      for (var v in themingVars) {
        if (themingVars.hasOwnProperty(v)) {
          var newV = "--theme-" + _.kebabCase(v),
            suffix = "";

          // Add px to the value if the default value contain 'px'
          if (_.includes(newV, "size")) suffix = "px";
          else if (_.includes(newV, "margin")) suffix = "px";
          else if (_.includes(newV, "radius")) suffix = "px";

          result[newV] = themingVars[v] + suffix;
        }
      }

      return result;
    },
  },
  mounted() {
    const scope = this;
    let id = "";
    let id_value = "";
    let data = scope.forms[0].fields.filter((field) => {
      return (
        field.model !== "ticket_id" &&
        field.model !== "lead_id" &&
        field.model !== "order_id" &&
        field.model !== "list_id"
      );
    });
    data.map((field) => {
      field.disabled = field.disabled ? !field.disabled : field.disabled;
      if (field.show_on_view && field.is_link && field.fieldType === 'TextInput' && field.link_property.length > 0) {
        field.show_on_view = false;
      }
      return field;
    });
    const stringForms = JSON.stringify(scope.forms);
    scope.formData = JSON.parse(stringForms);
    scope.formData[0].fields = data;
    for (const [key, value] of Object.entries(scope.GetAsterLeadInsert)) {
      if (
        `${key}` === "order id" ||
        `${key}` === "ticket id" ||
        `${key}` === "lead id"
      ) {
        id = `${key}`.replace(" ", "_");
        id_value = `${value}`;
        scope.pagecontrol.title = "#" + value;
      }
    }

    let form = {
      template_name: this.GetAsterLead,
      name: id,
      value: id_value,
    };
    this.$store.dispatch("FilterAsterLead", form).then((response) => {
      var datas = response.data[0];
      let data = scope.forms[0].fields;
      for (let i = 0; data.length > i; ++i) {
        if (data[i].is_link && data[i].link_property.length > 0 && data[i].fieldType === 'TextInput') {
          data[i].link_property.forEach(element => {
            if (datas[data[i].linked_to] === element) {
              data[i].show_on_view = true;
            }
          });
        }
        if (
          data[i].show_on_view &&
          data[i].model !== "ticket_id" &&
          data[i].model !== "lead_id" &&
          data[i].model !== "order_id" &&
          data[i].model !== "list_id"
        ) {
          if (data[i].fieldType === "Checkbox") {
            let container = document.querySelectorAll(
              `label#${data[i].model}.el-checkbox`
            );
            let values = datas[data[i].model].split(",");
            for (let node of container) {
              if (values.includes(node.outerText)) {
                node.className = "el-checkbox is-checked";
                node.querySelector("span.el-checkbox__input").className =
                  "el-checkbox__input is-checked";
              }
            }
          } else if (data[i].fieldType === "RadioButton") {
            let container = document.querySelectorAll(
              `label.el-radio#${data[i].model}`
            );
            for (let node of container) {
              if (datas[data[i].model] === node.outerText) {
                node.className = "el-radio is-checked";
                node.querySelector("span.el-radio__input").className =
                  "el-radio__input is-checked";
                node.setAttribute("aria-checked", true);
              }
            }
          } else {
            document.getElementById(data[i].model).value = datas[data[i].model];
          }
        }
      }
    });
  },
};
</script>
<style>
.form-preview {
  border: 0px solid #ccc !important;
}
.el-form-item {
  padding: 200px 100px;
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 0px;
}
.headline {
  font-size: 20px !important;
  padding: 20px !important;
}
</style>
