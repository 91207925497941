import config from "@/config/index";
import api from '../../utils/axios-util';
import * as calldetails from "../mutation-types";

const state = config.overall_report_state;
var from_date = '';
var to_date = '';

var getters = {

    calldetails_data: () => config.overall_report_state.calldetails.report,
    effectiveabandon_data: () => config.overall_report_state.effectiveabandon.report,
    recording_date: () => config.overall_report_state.recordingreport.report,
    filter: () => config.overall_report_state.filter,
    filters: () => config.overall_report_state.filters,
    missedcallreport: () => config.overall_report_state.missedcallreport.report,
    dropedcallreport: () => config.overall_report_state.dropedcallreport.report,
    auth0accessToken: () => config.auth0.auth0.accessToken,
    agentActivity: () => config.overall_report_state.agent_activity_report.report,
    fromdate: () => config.overall_report_state.filters.fromdate,
    performance_report: () => config.overall_report_state.performance_report.report,
    queueSummary: () => config.overall_report_state.queueSummaryReport.report,
    agentPauseReport: () => config.overall_report_state.agent_pause_report.report,
    agentDispoReport: () => config.overall_report_state.agent_dispo_report.report,
}

const mutations = {

    [calldetails.CALLDETAILS]: (state, payload) => {
        state.calldetails.report = payload;
    },
    [calldetails.EFFECTIVEABANDON]: (state, payload) => {
        state.effectiveabandon.report = payload;
    },
    [calldetails.RECORDINGDETAILS]: (state, payload) => {
        state.recordingreport.report = payload;
    },
    [calldetails.FILTERFROMDATE]: (state, payload) => {
        state.filters.fromdate = payload;

    },
    [calldetails.FILTER_TODATE]: (state, payload) => {

        state.filters.todate = payload;

    },
    [calldetails.ACTION_FROMDATE]: (state, payload) => {
        state.filter.fromdate = payload;
        state.filter.filter_process = true;
    },
    [calldetails.ACTION_TODATE]: (state, payload) => {
        state.filter.todate = payload;
        state.filter.filter_process = true;
    },
    [calldetails.FILTER_AGENT]: (state, payload) => {
        state.filter.agent = payload.agent;
    },
    [calldetails.FILTER_TEMPLATE]: (state, payload) => {
        state.filter.template = payload.template;
    },
    [calldetails.FILTER_TYPE]: (state, payload) => {
        state.filter.type = payload.type;
    },
    [calldetails.FILTER_PHONE]: (state, payload) => {
        state.filter.phone = payload.phone;
    },
    [calldetails.FILTER_CAMPAIGN]: (state, payload) => {
        state.filter.campaign = payload.campaign;
    },
    [calldetails.FILTER_EXTENSION]: (state, payload) => {
        state.filter.extension = payload.extension;

    },
    [calldetails.FILTER_GROUP]: (state, payload) => {
        state.filter.group = payload.group;
    },
    [calldetails.FILTER_STATION]: (state, payload) => {
        state.filter.station = payload.station;
    },
    [calldetails.FILTER_DID]: (state, payload) => {
        state.filter.did = payload.did;
    },
    [calldetails.FILTER_QUEUE]: (state, payload) => {
        state.filter.queue = payload.queue;
    },
    [calldetails.FILTER_DISPO]: (state, payload) => {
        state.filter.dispo = payload.dispo;
    },
    [calldetails.FILTER_STATUS]: (state, payload) => {
        state.filter.status = payload.status;
    },
    [calldetails.DATE]: (state, payload) => {
        state.filter.fromdate = payload.from_date;
        state.filter.todate = payload.to_date;
    },
    [calldetails.MISSEDCALLDETAILS]: (state, payload) => {
        state.missedcallreport.report = payload;
    },
    [calldetails.DROPEDCALLDETAILS]: (state, payload) => {
        state.dropedcallreport.report = payload;
    },
    [calldetails.FILTER_LAST_APP]: (state, payload) => {
        state.filters.last_app = payload.last_app;
    },
    [calldetails.AGENT_ACTIVITY_REPORTS]: (state, payload) => {
        state.agent_activity_report.report = payload;
    },
    [calldetails.CAMPAIGN_LISTS]: (state, payload) => {
        var campaign_payload = [];
        for (var i = 0; i < payload.data.count; i++) {
            campaign_payload.push(payload.data.data[i].campaign_name);
        }
        state.filters.campaign = campaign_payload;
    },
    [calldetails.FILTER_Event]: (state, payload) => {
        state.filter.event = payload.event;
    },
    [calldetails.AGENT_CAMPAIGN]: (state, payload) => {
        state.filter.campaign = payload.campaign;
    },
    [calldetails.Performance_report]: (state, payload) => {
        state.performance_report.report = payload;
    },
    [calldetails.queueSummaryReport]: (state, payload) => {
        state.queueSummaryReport.report = payload;
    },
    [calldetails.FILTER_UNIQUEID]: (state, payload) => {
        state.filter.uniqueid = payload.uniqueid;
    },
    [calldetails.AGENT_PAUSE_REPORT]: (state, data) => {
        state.agent_pause_report.report = data;
    },
    [calldetails.AGENT_DISPO_REPORT]: (state, data) => {
        state.agent_dispo_report.report = data;
    },
};

const actions = {
    async calldetails({ commit, state }, payload) {

        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }
        return new Promise((resolve, reject) => {
            try {

                if (payload.descending == null) {
                    payload.descending = false;
                }

                api.get("CallLogfetch?from_call_date=" + from_date + "&to_call_date=" + to_date + "&phone=" + state.filter.phone + "&campaign=" + state.filter.campaign + "&queue=" + state.filter.queue + "&agent=" + agent + "&call_type=" + state.filter.type + "&status=" + state.filter.status + "&take=" + payload.take + "&skip=" + payload.skip + "&station=" + state.filter.station + "&dispo_status=" + state.filter.dispo + "&descending=" + payload.descending + "&did_number=" + state.filter.did + "&uniqueid=" + state.filter.uniqueid + "&template_name=" + state.filter.template + "&team_user=" + team_user).then(response => {
                    commit(calldetails.CALLDETAILS, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.CALLDETAILS, []);
                    resolve(error)
                });

            } catch (error) {
                commit(calldetails.CALLDETAILS, []);
                reject(error);
            }
        })
    },
    effectiveabandon({ commit, state }, payload) {

        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        // let agent = '';
        // let team_user = '';
        // if (state.filter.agent === '' || state.filter.agent.length === 0) {
        //     team_user = payload.agent
        // } else {
        //     agent = state.filter.agent
        // }
        return new Promise((resolve, reject) => {
            try {

                if (payload.descending == null) {
                    payload.descending = false;
                }

                api.get("EffectiveAbandonfetch?from_call_date=" + from_date + "&to_call_date=" + to_date + "&phone=" + state.filter.phone + "&queue=" + state.filter.queue + "&take=" + payload.take + "&skip=" + payload.skip + "&descending=" + payload.descending).then(response => {
                    commit(calldetails.EFFECTIVEABANDON, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.EFFECTIVEABANDON, []);
                    resolve(error)
                });

            } catch (error) {
                commit(calldetails.EFFECTIVEABANDON, []);
                reject(error);
            }
        })
    },
    fromdate({ commit }, payload) {
        commit('FILTERFROMDATE', payload);

    },
    todate({ commit }, payload) {
        commit('FILTER_TODATE', payload);
    },

    missedcallreports({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        return new Promise((resolve, reject) => {
            try {
                if (payload.descending == null) {
                    payload.descending = false;
                }
                api.get("missedcallreports?fm_date_time=" + from_date + "&to_date_time=" + to_date + "&phone=" + state.filter.phone + "&queue=" + state.filter.queue + "&take=" + payload.take + "&skip=" + payload.skip + "&did_number=" + state.filter.did + "&last_app=" + state.filters.last_app + "&descending=" + payload.descending).then(response => {
                    commit(calldetails.MISSEDCALLDETAILS, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.MISSEDCALLDETAILS, []);
                    resolve(error)
                });
            } catch (error) {
                commit(calldetails.MISSEDCALLDETAILS, []);
                reject(error);
            }
        })
    },

    dropedcallreports({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }

        return new Promise((resolve, reject) => {
            try {
                api.get("/dropedcallreports?from_date=" + from_date + "&to_date=" + to_date + "&agent=" + agent + "&queue=" + state.filter.queue + "&extension=" + state.filter.extension + "&phone_number=" + state.filter.phone + "&uniqueid=" + state.filter.uniqueid + "&skip=" + payload.skip + "&take=" + payload.take + "&team_user=" + team_user).then(response => {
                    resolve(response.data)
                    commit(calldetails.DROPEDCALLDETAILS, response.data.data);
                }).catch(function(error) {
                    commit(calldetails.DROPEDCALLDETAILS, []);
                    resolve(error)
                });
            } catch (error) {
                commit(calldetails.DROPEDCALLDETAILS, []);
                reject(error);
            }
        })
    },
    agentactivityreports({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }

        return new Promise((resolve, reject) => {
            try {
                if (payload.descending == null) {
                    payload.descending = false;
                }
                api.get("agentActivity?fm_date=" + from_date + "&to_date=" + to_date + "&campaign=" + state.filter.campaign + "&event=" + state.filter.event + "&take=" + payload.take + "&skip=" + payload.skip + "&agent=" + agent + "&descending=" + payload.descending + "&team_user=" + team_user).then(response => {
                    commit(calldetails.AGENT_ACTIVITY_REPORTS, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.AGENT_ACTIVITY_REPORTS, []);
                    resolve(error)
                });
            } catch (error) {
                commit(calldetails.AGENT_ACTIVITY_REPORTS, []);
                reject(error);
            }
        })
    },
    agentPauseReports({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }

        return new Promise((resolve, reject) => {
            try {
                if (payload.descending == null) {
                    payload.descending = false;
                }
                api.get("break/report?fm_date=" + from_date + "&to_date=" + to_date + "&take=" + payload.take + "&skip=" + payload.skip + "&agent=" + agent + "&descending=" + payload.descending + "&team_user=" + team_user).then(response => {
                    commit(calldetails.AGENT_PAUSE_REPORT, response.data.data);
                    resolve(response.data);
                }).catch(function(error) {
                    commit(calldetails.AGENT_PAUSE_REPORT, []);
                    resolve(error);
                });
            } catch (error) {
                commit(calldetails.AGENT_PAUSE_REPORT, []);
                reject(error);
            }
        })
    },
    agentDispoReports({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }

        return new Promise((resolve, reject) => {
            try {
                if (payload.descending == null) {
                    payload.descending = false;
                }
                api.get("dispo/report?fm_date=" + from_date + "&to_date=" + to_date + "&take=" + payload.take + "&skip=" + payload.skip + "&agent=" + agent + "&descending=" + payload.descending + "&team_user=" + team_user).then(response => {
                    commit(calldetails.AGENT_DISPO_REPORT, response.data.data);
                    resolve(response.data);
                }).catch(function(error) {
                    commit(calldetails.AGENT_DISPO_REPORT, []);
                    resolve(error);
                });
            } catch (error) {
                commit(calldetails.AGENT_DISPO_REPORT, []);
                reject(error);
            }
        })
    },
    type({ commit }, payload) {
        commit(calldetails.FILTER_TYPE, { type: payload })
    },

    exportdatafetch({ commit }, payload) {
        if (payload.calldetail == 'exportCallDetail') {
            return new Promise((resolve, reject) => {
                try {
                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;
                    api.get("CallLogfetch?from_call_date=" + from_date + "&to_call_date=" + to_date + "&phone=" + state.filter.phone + "&campaign=" + state.filter.campaign + "&queue=" + state.filter.queue + "&agent=" + state.filter.agent + "&call_type=" + state.filter.type + "&status=" + state.filter.status + "&station=" + state.filter.station + "&dispo_status=" + state.filter.dispo + "&did_number=" + state.filter.did + "&uniqueid=" + state.filter.uniqueid + "&template_name=" + state.filter.template + "&team_user=" + payload.team_user).then(response => {
                        resolve(response.data.data);
                    }).catch(function(error) {
                        resolve(error);
                    })
                } catch (error) {
                    reject(error);
                }
            })
        }
        if (payload.calldetail == 'exportrecordigdetails') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;
                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        //dispatch("reportuserlist")
                        //.then((response) => {
                        //        let agentData = response.toString(",");
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("recording?fromdate=" + from_date + "&todate=" + to_date + "&campaign=" + state.filter.campaign + "&station=" + state.filter.station + "&phone_number=" + state.filter.phone + "&call_type=" + state.filter.type + "&team_user=" + team_user).then(response => {
                                resolve(response.data.data);
                            }).catch(function(error) {
                                resolve(error);
                            })
                            //})
                            //.catch((err) => { console.log("---ERROR---", err) });
                    } else {
                        agent = state.filter.agent;
                        api.get("recording?fromdate=" + from_date + "&todate=" + to_date + "&campaign=" + state.filter.campaign + "&station=" + state.filter.station + "&phone_number=" + state.filter.phone + "&call_type=" + state.filter.type + "&agent=" + agent).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    reject(error);
                }
            })
        }

        if (payload.misseddetail == 'exportdropedcalldetails') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;
                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        //dispatch("reportuserlist")
                        //.then((response) => {
                        //       let agentData = response.toString(",");
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("/dropedcallreports?from_date=" + from_date + "&to_date=" + to_date + "&team_user=" + team_user + "&queue=" + state.filter.queue + "&extension=" + state.filter.extension + "&phone_number=" + state.filter.phone).then(response => {
                                resolve(response.data.data);
                            }).catch(function(error) {
                                resolve(error);
                            })
                            //})
                            // .catch((err) => { console.log("---ERROR---", err) });
                    } else {
                        agent = state.filter.agent;
                        api.get("/dropedcallreports?from_date=" + from_date + "&to_date=" + to_date + "&agent=" + agent + "&queue=" + state.filter.queue + "&extension=" + state.filter.extension + "&phone_number=" + state.filter.phone).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    commit(calldetails.DROPEDCALLDETAILS, []);
                    reject(error);
                }
            })
        }
        if (payload.misseddetail == 'exportmissedcalldetails') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    api.get("missedcallreports?fm_date_time=" + from_date + "&to_date_time=" + to_date + "&phone=" + state.filter.phone + "&queue=" + state.filter.queue + "&did_number=" + state.filter.did + "&last_app=" + state.filters.last_app).then(response => {
                        resolve(response.data.data);
                    }).catch(function(error) {
                        resolve(error);
                    })
                } catch (error) {
                    reject(error);
                }
            })
        }

        if (payload.abandoncalldetail == 'exporteffectiveabandoncalldetails') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    api.get("EffectiveAbandonfetch?from_call_date=" + from_date + "&to_call_date=" + to_date + "&phone=" + state.filter.phone + "&queue=" + state.filter.queue).then(response => {
                        resolve(response.data.data);
                    }).catch(function(error) {
                        resolve(error);
                    })
                } catch (error) {
                    reject(error);
                }
            })
        }

        if (payload.misseddetail == 'AgentActivityReport') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        //dispatch("reportuserlist")
                        // .then((response) => {
                        //    let agentData = response.toString(",");
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("agentActivity?fm_date=" + from_date + "&to_date=" + to_date + "&campaign=" + state.filter.campaign + "&event=" + state.filter.event + "&team_user=" + team_user).then(response => {
                                resolve(response.data.data);
                            }).catch(function(error) {
                                resolve(error);
                            })
                            //})
                            // .catch((err) => { console.log("---ERROR---", err) });
                    } else {
                        agent = state.filter.agent;
                        api.get("agentActivity?fm_date=" + from_date + "&to_date=" + to_date + "&campaign=" + state.filter.campaign + "&event=" + state.filter.event + "&agent=" + agent).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    reject(error);
                }
            })
        }

        if (payload.misseddetail == 'AgentPauseReport') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("break/report?fm_date=" + from_date + "&to_date=" + to_date + "&team_user=" + team_user).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    } else {
                        agent = state.filter.agent;
                        api.get("break/report?fm_date=" + from_date + "&to_date=" + to_date + "&agent=" + agent).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    reject(error);
                }
            })
        }


        if (payload.misseddetail == 'AgentDispoReport') {
            return new Promise((resolve, reject) => {
                try {

                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("dispo/report?fm_date=" + from_date + "&to_date=" + to_date + "&team_user=" + team_user).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    } else {
                        agent = state.filter.agent;
                        api.get("dispo/report?fm_date=" + from_date + "&to_date=" + to_date + "&agent=" + agent).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    reject(error);
                }
            })
        }


        if (payload.misseddetail == 'Performancereport') {
            return new Promise((resolve, reject) => {
                try {
                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;
                    let agent = '';
                    let team_user = '';
                    if (state.filter.agent === '' || state.filter.agent.length === 0) {
                        //dispatch("reportuserlist")
                        // .then((response) => {
                        //    let agentData = response.toString(",");
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        api.get("agentPerformance?fromdate=" + from_date + "&todate=" + to_date + "&team_user=" + team_user + "&campaign=" + state.filter.campaign).then(response => {
                                resolve(response.data.data);
                            }).catch(function(error) {
                                resolve(error);
                            })
                            //})
                            // .catch((err) => { console.log("---ERROR---", err) });
                    } else {
                        agent = state.filter.agent;
                        api.get("agentPerformance?fromdate=" + from_date + "&todate=" + to_date + "&agent=" + agent + "&campaign=" + state.filter.campaign).then(response => {
                            resolve(response.data.data);
                        }).catch(function(error) {
                            resolve(error);
                        })
                    }
                } catch (error) {
                    reject(error);
                }
            })
        }




        if (payload.misseddetail == 'exportQueuSummaryReport') {
            return new Promise((resolve, reject) => {
                try {
                    from_date = state.filters.fromdate;
                    to_date = state.filters.todate;

                    api.get("queueSummary?fromdate=" + from_date + "&todate=" + to_date + "&queue=" + state.filter.queue).then(response => {
                        resolve(response.data.data);
                    }).catch(function(error) {
                        resolve(error);
                    })
                } catch (error) {
                    reject(error);
                }
            })
        }
    },

    recordingdetails({ commit, state }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        if (payload.descending == null) {
            payload.descending = false;
        }
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            team_user = state.filter.agent
        }
        return new Promise((resolve, reject) => {
            try {
                api.get("recording?fromdate=" + from_date + "&todate=" + to_date + "&campaign=" + state.filter.campaign + "&station=" + state.filter.station + "&phone_number=" + state.filter.phone + "&call_type=" + state.filter.type + "&team_user=" + team_user + "&agent=" + agent + "&skip=" + payload.skip + "&descending=" + payload.descending + "&take=" + payload.take + "&uniqueid=" + state.filter.uniqueid).then(response => {
                    commit(calldetails.RECORDINGDETAILS, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    // commit(calldetails.RECORDINGDETAILS, error);
                    resolve(error)
                });

            } catch (error) {
                // commit(calldetails.RECORDINGDETAILS, error);
                reject(error);
            }
        })
    },
    queueSummaryReport({ commit }, payload) {

        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        if (payload.descending == null) {
            payload.descending = false;
        }

        return new Promise((resolve, reject) => {
            try {
                api.get("queueSummary?fromdate=" + from_date + "&todate=" + to_date + "&queue=" + state.filter.queue + "&skip=" + payload.skip + "&take=" + payload.take).then(response => {
                    commit(calldetails.queueSummaryReport, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.Performance_report, error);
                    resolve(error)
                });

            } catch (error) {
                commit(calldetails.Performance_report, error);
                reject(error);
            }
        })

    },
    Performancereport({ commit }, payload) {
        from_date = state.filters.fromdate;
        to_date = state.filters.todate;
        if (payload.descending == null) {
            payload.descending = false;
        }
        let agent = '';
        let team_user = '';
        if (state.filter.agent === '' || state.filter.agent.length === 0) {
            team_user = payload.agent
        } else {
            agent = state.filter.agent
        }
        return new Promise((resolve, reject) => {
            try {
                api.get("agentPerformance?fromdate=" + from_date + "&todate=" + to_date + "&agent=" + agent + "&campaign=" + state.filter.campaign + "&skip=" + payload.skip + "&take=" + payload.take + "&descending=" + payload.descending + "&team_user=" + team_user).then(response => {
                    commit(calldetails.Performance_report, response.data.data);
                    resolve(response.data)
                }).catch(function(error) {
                    commit(calldetails.Performance_report, error);
                    resolve(error)
                });

            } catch (error) {
                commit(calldetails.Performance_report, error);
                reject(error);
            }
        })
    },
    audioplay({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                api.get("recordingUrl?tenant_code=" + payload.tid + "&date=" + payload.date + "&id=" + payload.id).then(response => {
                    resolve(response.data)
                }).catch(function(error) {
                    resolve(error)
                });

            } catch (error) {
                commit(calldetails.RECORDINGDETAILS, error);
                reject(error);
            }
        })

    },

    reporttemplatelist: () => {
        api.get("TempListFetch").then(response => {
            var Templist = response.data.data;
            var Temp_list = [];
            for (var i = 0; i < Templist.length; i++) {
                Temp_list.push(Templist[i].template_name)
            }
            localStorage.setItem("group_template", JSON.stringify(Temp_list));

        })
    },

    // reportuserlist: ({ dispatch, commit }) => {
    //   // var userList = [];
    //   // api.get("userList").then(response => {
    //   //   var user_response = response.data.data;
    //   //   for (var i = 0; i < user_response.length; i++) {
    //   //     if (user_response[i].agent != '') {
    //   //       userList.push(user_response[i].agent)
    //   //     }
    //   //   }
    //   //   commit(calldetails.AGENT_LISTS, userList);
    //   // })
    //   let data = localStorage.getItem('groupId');
    //   dispatch('getGroupUserLists', data).then(response => {
    //     commit(calldetails.AGENT_LISTS, response);
    //     // resolve(response.data.data)
    //   })
    // },
    // reportuserlist: ({ dispatch }) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             let data = localStorage.getItem('groupId');
    //             dispatch('getGroupUserLists', data).then(response => {
    //                     resolve(response);
    //                 })
    //                 .catch(function(error) {
    //                     // commit(calldetails.RECORDINGDETAILS, error);
    //                     reject(error)
    //                 });

    //         } catch (error) {
    //             // commit(calldetails.RECORDINGDETAILS, error);
    //             reject(error);
    //         }
    //     })
    // },
    ReportcampaignList: ({ commit }) => {
        api.get("campaignList").then(response => {
            var data_response = response;
            commit(calldetails.CAMPAIGN_LISTS, data_response);
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};