import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
const mutations = {
    [mutation_type.Disposition]: (state, response) => {
        state.Disposition = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetAudio: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/audio`).then(response => {
                    resolve(response);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FindAudio: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/audio?status=${query}`).then(response => {
                    resolve(response);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FilterAudio: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/audio?id=${query}`).then(response => {
                    resolve(response);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    deleteAudio: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/audio/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAudio: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/audio/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    mutations,
    actions
};