import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.TagManagement;
var getters = {
    tagsdata: state => state.TagManagement.tagsdata,
    TagManagementFilter: state => state.TagManagement.filter,

}
const mutations = {
    [mutation_type.TagManagement]: (state, response) => {
        state.TagManagement.tagsdata = response.data.data
    },
    filter_name: (state, response) => {
        state.TagManagement.filter.name = response.name;
    },
    [mutation_type.CommitNothing]: () => {}
}
const actions = {
    TagManagement: ({ commit }) => {
        return new Promise((resolve, reject) => {
            let name = "";
            if (state.TagManagement.filter.name) {
                name = `name=${state.TagManagement.filter.name}`
            } else {
                name = ''
            }
            api.get(`/tags/fetch?${name}`).then(response => {
                var TagManagement_response = response;
                commit(mutation_type.TagManagement, TagManagement_response)
                resolve(response);
            }).catch(function(error) {
                reject(error);
            })

        })
    },
    Tagmanagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let name = "";
            if (payload) {
                name = `name=${payload}`
            } else {
                name = ''
            }
            api.get(`/tags/fetch?${name}`).then(response => {
                var TagManagement_response = response;
                commit(mutation_type.TagManagement, TagManagement_response)
                resolve(response);
            }).catch(function(error) {
                reject(error);
            })

        })
    },
    Addtags: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let data = {
                name: payload.name,
                description: payload.description,
                status: payload.status.value
            }
            api.post('/tags/create', data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    },
    editeditem: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            var data = {
                description: payload.description,
                status: payload.status.value
            }
            api.put(`/tags/update/${payload.name}`, data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    },
    DeleteRows: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.delete(`/tags/delete/${payload.name}`).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}