import config from "@/config/index";
import auth0Js from 'auth0-js';
import auth0Variables from '../../utils/auth0-variables';
import axios from '../../utils/axios-util';
import VueJwtDecode from 'vue-jwt-decode'
import {
    AUTH0LOGINSUCCESS,
    AUTH0LOGOUTSUCCESS,
    RESETTOKEN
} from "../mutation-types";
const state = config.auth0;
var variables = auth0Variables();
var token = '';
var permissionInfo = '';
var auth0 = new auth0Js.WebAuth({
    domain: variables.domain,
    clientID: variables.clientId,
    redirectUri: variables.callbackUrl,
    audience: variables.audience,
    responseType: 'token id_token',
    scope: 'openid profile offline_access'
});
const getters = {
    UserPermissions: state => state.settings.permissions,
}
const mutations = {
    [AUTH0LOGINSUCCESS]: (state, payload) => {
        state.auth0.isLoggedIn = true;
        state.auth0.accessToken = payload.token;
        state.auth0.api_url = payload.api_url;
        state.auth0.portal_url = payload.portal_url;
        state.auth0.tenant_code = payload.tenant_code;
    },
    [AUTH0LOGOUTSUCCESS]: (state) => {
        state.auth0.isLoggedIn = false;
        state.auth0.accessToken = '';
    },
    [RESETTOKEN]: (state, payload) => {
        state.auth0.accessToken = payload;
    }
};
const actions = {
    auth0Login: () => {
        return new Promise((resolve, reject) => {
            try {
                auth0.authorize({
                    prompt: 'login'
                })
            } catch (err) {
                reject(err)
            }
        })
    },
    auth0Parse: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            auth0.parseHash((err, authResult) => {
                var decoded_token = VueJwtDecode.decode(authResult.accessToken);
                var tenant_code = decoded_token["http://api.doocti.com/claims/tenant_code"];
                localStorage.setItem("TenantCode", tenant_code);
                var api_url = decoded_token["http://api.doocti.com/claims/api_domain"];
                var portal_url = decoded_token["http://api.doocti.com/claims/portal_domain"];
                var user_email = decoded_token["http://api.doocti.com/claims/email"];
                var apiInfo = {
                    token: authResult.accessToken,
                    portal_url: `https://${portal_url}`,
                    api_url: `https://${api_url}`,
                    tenant_code: tenant_code,
                    user_email:user_email
                }
                localStorage.apiInfo = JSON.stringify(apiInfo);
                axios.defaults.baseURL = apiInfo.portal_url;
                axios.defaults.headers.Authorization = `Bearer ${apiInfo.token}`;
                if (authResult && authResult.accessToken && authResult.idToken) {
                    var config1 = {
                        headers: {
                            Authorization: `Bearer ${authResult.accessToken}`,
                        },
                    };
                    axios
                        .get(`${variables.common_api_url}/common/project/config?tenant_code=${tenant_code}&user_email=${user_email}`, config1)
                        .then(response => {
                            localStorage.projectConfig = JSON.stringify(response.data);
                            localStorage.projectId = response.data.projects[0].id;
                            commit(AUTH0LOGINSUCCESS, apiInfo);
                            dispatch('getUserPermissions').then(() => {
                                resolve(authResult);
                            });
                        })
                        .catch(() => {

                        });
                } else if (err) {
                    reject(err)
                }
            })
        })
    },
    tokencheck: ({ commit }) => {
        token = localStorage.getItem('userToken');
        permissionInfo = JSON.parse(localStorage.getItem('permissionInfo'));
        // const username = JSON.parse(localStorage.getItem('projectConfig'));
        // console.log('username', username.projects[0].userInfo[0].user_name)
        if (state.auth0.accessToken == '') {
            commit(RESETTOKEN, token);
        }
        if (permissionInfo) {
            commit('ModifyUserPermissions', permissionInfo);
        }
    },
    auth0Logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            try {

                commit(AUTH0LOGOUTSUCCESS);
                localStorage.removeItem("apiInfo");
                localStorage.removeItem("projectConfig");
                localStorage.removeItem("projectId");
                localStorage.removeItem("group_template");
                localStorage.removeItem("group_users");
                localStorage.removeItem("group_queue");
                localStorage.removeItem("group_disposition");
                resolve();
            } catch (error) {
                reject(error)
            }
        })
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};