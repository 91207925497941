import AppSidebar from "@/layouts/App/NavDrawer";
import AppToolbar from "@/layouts/App/Toolbar";
import AppFooter from "@/layouts/App/Footer";

import CallflowToolbar from "@/layouts/CallFlow/Toolbar";
import CallflowFooter from "@/layouts/CallFlow/Footer";
import CallFlow from "@/views/CallFlow/CallFlow";
import CreateCallFlow from "@/views/CallFlow/create/CreatePage";
import RenderCallFlow from "@/views/CallFlow/render/RenderPage";

import LoginPage from "@/views/LoginPage";
import BlankPage from "@/views/BlankPage";
import LiveAgents from "@/views/RealTime/LiveAgents";
import LiveCalls from "@/views/RealTime/LiveCalls";
import Wallboard from "@/views/RealTime/Wallboard";

import RecordingReport from "@/views/Reports/RecordingReport";
import CallDetailReport from "@/views/Reports/CallDetailReport";
import PerformanceReport from "@/views/Reports/PerformanceReport";
import EffectiveAbandonReport from "@/views/Reports/EffectiveAbandonReport";
import AgentActivityReport from "@/views/Reports/AgentActivityReport";
import AgentPauseReport from "@/views/Reports/AgentPauseReport";
import AgentDispoReport from "@/views/Reports/AgentDispoReport";
import QueueSummary from "@/views/Reports/QueueSummary";
import MissedCallReport from "@/views/Reports/MissedCallReport";
import DropedCallReport from "@/views/Reports/DropedCallReport";

import Auth0CallBack from "@/views/Auth0Callback/Auth0Callback";
import UserManagement from "@/views/UserManagement/UserManagement";
import QueueManagement from "@/views/QueueManagement/QueueManagement";
import AudioStore from "@/views/Configurations/AudioStore";
import WebHookManagement from "@/views/Connections/WebHookManagement";

import DoNotCall from "@/views/Configurations/DoNotCall";
import blockList from "@/views/Configurations/blockList";
import reminder from "@/views/Configurations/reminder";
import GroupManagement from "@/views/Configurations/GroupManagement";
import Disposition from "@/views/Configurations/Disposition";
import PauseCode from "@/views/Configurations/PauseCode";
import Contact from "@/views/Configurations/Contact";
import Rules from "@/views/Configurations/Rules";
import Dialrule from "@/views/Configurations/Timezone";
import AsterCampaign from "@/views/CRM/AsterCampaigns";
import AsterList from "@/views/CRM/AsterList";
import AsterLead from "@/views/CRM/AsterLeads";
import TagManagement from "@/views/TagManagement/TagManagement";
import AsterSms from "@/views/Template/AsterSms";
import AsterMail from "@/views/Template/AsterMail";
import AsterWhatsapp from "@/views/Template/AsterWhatsapp";
import CustomForm from '@/views/CRM/custom-form/index';
import Home from '@/views/CRM/custom-form/Home';
import Preview from '@/views/CRM/custom-form/Preview'
import CustomFormList from '@/views/CRM/CustomForm';
import InsertAsterLead from '@/views/CRM/InsertAsterLead';
import Table from '@/views/CRM/Table';
import EditAsterLead from '@/views/CRM/EditAsterLead';
import DashBoard from '@/views/DashBoard/dashboard';
import Widgets from '@/views/DashBoard/widgets';
import addWidgets from '@/views/DashBoard/addwidgets';
import editWidgets from '@/views/DashBoard/editwidgets';
import TeamManagement from "@/views/Configurations/Team"

export const routes = [
    { path: "/", redirect: "/doocti/auth/login" },
    {
        path: "/doocti/auth/login",
        name: "doocti/Auth/Login",
        components: {
            default: LoginPage
        },
        auth: false,
        meta: {
            permission: {
                access: true
            }
        }
    },
    {
        path: "/callback",
        name: "callback",
        components: {
            default: Auth0CallBack
        },
        meta: {
            auth: false,
            permission: {
                access: true
            }
        }
    },
    {
        path: "/dashboard",
        name: "DashBoard",
        components: {
            default: DashBoard,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    
    {
        path: "/widgets",
        name: "Widgets",
        components: {
            default: Widgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/addwidgets",
        name: "addwidgets",
        components: {
            default: addWidgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/editwidgets",
        name: "editwidgets",
        components: {
            default: editWidgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/blank-page",
        name: "blankPage",
        components: {
            default: BlankPage,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/realtime/live-agents",
        name: "realtime/LiveAgents",
        components: {
            default: LiveAgents,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:liveagents:view']
            }
        }
    },
    {
        path: "/realtime/wallboard",
        name: "realtime/Wallboard",
        components: {
            default: Wallboard,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:livestatus:campaignview', 'realtime:livestatus:queueview', ]
            }
        }
    },
    {
        path: "/realtime/live-calls",
        name: "realtime/LiveCalls",
        components: {
            default: LiveCalls,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:livecalls:view']
            }
        }
    },
    {
        path: "/report/recording-report",
        name: "report/RecordingReport",
        components: {
            default: RecordingReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:recording:view']
            }
        }
    },
    {
        path: "/report/call-detail-report",
        name: "report/CallDetailReport",
        components: {
            default: CallDetailReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:calldetail:view']
            }
        }
    },
    {
        path: "/report/performance-report",
        name: "report/PerformanceReport",
        components: {
            default: PerformanceReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentperformance:view']
            }
        }
    },
    {
        path: "/report/effective-abandon-report",
        name: "report/EffectiveAbandonReport",
        components: {
            default: EffectiveAbandonReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:calldetail:view']
            }
        }
    },
    {
        path: "/report/agent-activity-report",
        name: "report/AgentActivityReport",
        components: {
            default: AgentActivityReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentactivity:view']
            }
        }
    },
    {
        path: "/report/agent-pause-report",
        name: "report/AgentPauseReport",
        components: {
            default: AgentPauseReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentpause:view']
            }
        }
    },
    {
        path: "/report/agent-dispo-report",
        name: "report/AgentDispoReport",
        components: {
            default: AgentDispoReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentdispo:view']
            }
        }
    },
    {
        path: "/report/queue-summary",
        name: "report/QueueSummary",
        components: {
            default: QueueSummary,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:queueperformance:view']
            }
        }
    },
    {
        path: "/report/missed-call-report",
        name: "report/MissedCallReport",
        components: {
            default: MissedCallReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:missedcall:view']
            }
        }
    },
    {
        path: "/report/droped-call-report",
        name: "report/DropedCallReport",
        components: {
            default: DropedCallReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:dropedcall:view']
            }
        }
    },
    {
        path: "/usergroup/user-management",
        name: "usergroup/UserManagement",
        components: {
            default: UserManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:user:view']
            }
        }
    },
{
    path: "/usergroup/team",
        name: "usergroup/TeamManagement",
        components: {
            default: TeamManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:team:view']
            }
        }
    },


    {
        path: "/configurations/queue-management",
        name: "configurations/QueueManagement",
        components: {
            default: QueueManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:queue:view']
            }
        }
    },
    {
        path: "/connections/web-hooks",
        name: "connections/WebHooks",
        components: {
            default: WebHookManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['connection:webhooks:view']
            }
        }
    },
    {
        path: "/configurations/dnc",
        name: "configurations/DoNoCall",
        components: {
            default: DoNotCall,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dnc:view']
            }
        }
    },
    {
        path: "/configurations/blocklist",
        name: "configurations/BlockList",
        components: {
            default: blockList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:blocklist:view']
            }
        }
    },
    
    {
        path: "/configurations/reminder",
        name: "configurations/Reminder",
        components: {
            default: reminder,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:blocklist:view']
            }
        }
    },
    {
        path: "/usergroup/groupmanagement",
        name: "usergroup/GroupManagement",
        components: {
            default: GroupManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:usergroup:view']
            }
        }
    },
    {
        path: "/configurations/audiostore",
        name: "configurations/AudioStore",
        components: {
            default: AudioStore,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:audiostore:view']
            }
        }
    },
    {
        path: "/configurations/disposition",
        name: "configurations/Disposition",
        components: {
            default: Disposition,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dispositions:view']
            }
        }
    },
    {
        path: "/configurations/pausecode",
        name: "configurations/PauseCode",
        components: {
            default: PauseCode,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/contacts",
        name: "Contact",
        components: {
            default: Contact,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:contact:view']
            }
        }
    },
    {
        path: "/configurations/rules",
        name: "configurations/Rules",
        components: {
            default: Rules,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:rules:view']
            }
        }
    },
    {
        path: "/configurations/timezone",
        name: "configurations/Timezone",
        components: {
            default: Dialrule,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dialrule:view']
            }
        }
    },
    {
        path: "/crm/campaigns",
        name: "crm/AsterCampaign",
        components: {
            default: AsterCampaign,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:campaigns:view']
            }
        }
    },
    {
        path: "/crm/leads",
        name: "crm/AsterLead",
        components: {
            default: AsterLead,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:view']
            }
        }
    },
    {
        path: "/connections/sms",
        name: "connections/AsterSms",
        components: {
            default: AsterSms,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['template:sms:view']
            }
        }
    },
    {
        path: "/connections/mail",
        name: "connections/AsterMail",
        components: {
            default: AsterMail,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['template:mail:view']
            }
        }
    },
    {
        path: "/connections/whatsapp",
        name: "connections/AsterWhatsapp",
        components: {
            default: AsterWhatsapp,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['template:whatsapp:view']
            }
        }
    },
    {
        path: "/crm/leads/create",
        name: "crm/InsertAsterLead",
        components: {
            default: InsertAsterLead,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:create']
            }
        }
    },
    {
        path: "/crm/table",
        name: "crm/Table",
        components: {
            default: Table,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:view']
            }
        }
    },
    {
        path: "/crm/leads/edit",
        name: "crm/EditAsterLead",
        components: {
            default: EditAsterLead,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:edit']
            }
        }
    },
    {
        path: "/crm/lists",
        name: "crm/AsterList",
        components: {
            default: AsterList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:list:view']
            }
        }
    },
    {
        path: "/crm/customform",
        name: "crm/CustomForm",
        components: {
            default: CustomForm,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/crm/customform/home",
        name: "crm/home",
        components: {
            default: Home,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/crm/customform/list",
        name: "crm/CustomFormList",
        components: {
            default: CustomFormList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/crm/customform/preview",
        name: "crm/preview",
        components: {
            default: Preview,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/configurations/tag-management",
        name: "configurations/TagManagement",
        components: {
            default: TagManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:tags:view']
            }
        }
    },
    {
      path: "/CallFlow",
      name: "CallFlow",
      components: {
        default: CallFlow,
        sidebar: AppSidebar,
        header: AppToolbar,
        footer: AppFooter,
      },
    },
    {
      path: "/CallFlow/Create",
      name: "CallFlow/Create",
      components: {
        default: CreateCallFlow,
        sidebar: AppSidebar,
        header: CallflowToolbar,
        footer: CallflowFooter,
      },
    },
    {
      path: "/CallFlow/Render",
      name: "CallFlow/Render",
      components: {
        default: RenderCallFlow,
        sidebar: AppSidebar,
        header: CallflowToolbar,
        footer: CallflowFooter,
      },
    },
];