export default function config() {

    var environment = 'production'; //change the environment before building the package
    var credentials = {};
    if (environment == 'development') {
        credentials.clientId = 'ySM1U4InMt8rCpjahy3S2xg8h8cvIve4';
        credentials.domain = 'dev-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback':'https://report-sandbox.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://dev-api.doocti.com';
        credentials.common_api_url = 'https://api-dev.doocti.com';
        credentials.cryptosecuritykey="raghavan" //change key same as backend api
 
    } else if (environment == 'demo') {
        credentials.clientId = 'ySM1U4InMt8rCpjahy3S2xg8h8cvIve4';
        credentials.domain = 'dev-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://demo-portal.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://dev-api.doocti.com';
        credentials.common_api_url = 'https://api-demo.doocti.com';
    } else if (environment == 'test') {
        credentials.clientId = 'Htiw39Tuhnwiu67ijphlh8iBFPn7qpsQ';
        credentials.domain = 'test-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://wallboard-test.doocti.com/doocti-report/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://testapi.doocti.com';
        credentials.common_api_url = 'https://test.doocti.com';
    } else if (environment == 'production') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://portal.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        credentials.common_api_url = 'https://api.doocti.com'; 
        credentials.cryptosecuritykey="raghavan" //change key same as backend api
    } else if (environment == 'qa-production') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://qa-portal.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        credentials.common_api_url = 'https://api.doocti.com'; 
        credentials.cryptosecuritykey="raghavan" //change key same as backend api
    }
    else if (environment == 'portal-sandboxv1') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://portal-sandboxv1.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        credentials.common_api_url = 'https://api.doocti.com'; 
        credentials.cryptosecuritykey="raghavan" //change key same as backend api
    }
    return credentials;
}