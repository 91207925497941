import types from './mutation-types';
import Axios from "../../../utils/axios-util";

export default {
    config({ dispatch }) {
        return new Promise((resolve, reject) => {
            try {
                const configs = {};
                dispatch('fetchWidgetType').then((response) => {
                    configs.widget_types = response;
                    dispatch('fetchChartType').then((response) => {
                        configs.chart_types = response;
                        dispatch('fetchTable').then((response) => {
                            configs.tables = response;
                            localStorage.setItem("widgetTable", JSON.stringify(configs));
                            resolve(configs);
                        });
                    });
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchWidgetType() {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/dashboard/widget/types`)
                    .then((result) => {
                        const widget_types = result.data.data;
                        resolve(widget_types);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchChartType() {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/dashboard/chart/types`)
                    .then((result) => {
                        const chart_types = result.data.data;
                        resolve(chart_types);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchTable() {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/dashboard/table`)
                    .then((result) => {
                        const tables = result.data.data;
                        resolve(tables);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchTableColumn({ state }, table) {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/dashboard/columns?table_name=${table}`)
                    .then((result) => {
                        const columns = result.data.data;
                        state.widgets = columns;
                        resolve(columns);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchWidgets({ state }, payload) {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/widget?type=${payload.type}&name=${payload.name}`)
                    .then((result) => {
                        if (result.data.statusCode === 200) {
                            const columns = result.data.data;
                            state.widgets = columns;
                            resolve(columns);
                        } else {
                            const columns = [];
                            state.widgets = columns;
                            resolve([]);
                        }
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    fetchProperty() {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/widget/config`)
                    .then((result) => {
                        if (result.status === 200) {
                            const data = result.data.data;
                            resolve(data);
                        } else {
                            const data = [{}];
                            resolve(data);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchWidgetData({ dispatch }, id) {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/widget/${id}`)
                    .then((result) => {
                        const columns = result.data.data[0];
                        localStorage.setItem("editWidget", JSON.stringify(columns));
                        dispatch("addWidget", columns);
                        dispatch("setActive", { id: 0 });
                        dispatch("unsetActive", { id: 0 });
                        resolve(columns);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateWidget({ state }) {
        return new Promise((resolve, reject) => {
            try {
                const responseData = [];
                const rects = state.rects;
                rects.map(rect => {
                    state.widgets.forEach(widget => {

                        if (rect.widget_id === widget.id) {
                            delete widget["id"];
                            const obj = {
                                ...rect,
                                ...widget,
                            };
                            const payload = {
                                id: obj.widget_id,
                                name: obj.name,
                                data_table: obj.data_table === "" ? "aster_agent_performance" : obj.data_table,
                                type: obj.type,
                                xaxis_column: obj.xaxis_column,
                                yaxis_column: obj.yaxis_column,
                                is_shown_on_dashboard: obj.isShowOnDashboard,
                                chart_type: obj.chart_type,
                                no_of_fields: obj.no_of_fields,
                                max_no_data_per_page: obj.max_no_data_per_page,
                                active: obj.active,
                                is_draggable: false,
                                is_resizeable: false,
                                width: obj.width,
                                height: obj.height,
                                min_width: obj.minw,
                                min_height: obj.minh,
                                position_top: obj.top,
                                position_left: obj.left,
                                zindex: obj.zIndex,
                                axis: obj.axis,
                                parent_limit: obj.parentLim,
                                snap_to_grid: obj.snapToGrid,
                                aspect_ratio: obj.aspectRatio,
                                color: obj.color,
                                component: obj.component,
                                class: obj.class,
                                refresh_rate: obj.timer,
                                backgroun_colors: obj.backgroun_colors,
                                labels: obj.labels,
                            };
                            const id = payload.id;
                            delete payload.id;
                            Axios.put(`/widget/${id}`, payload)
                                .then((result) => {
                                    const columns = result.data.data;
                                    responseData.push(columns);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        }
                    });
                });
                resolve({ responseData });
            } catch (error) {
                reject(error);
            }
        });
    },
    changeWidget({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                const value = {
                    name: payload
                }
                Axios.post(`/addwidget`, value)
                    .then((response) => {
                        const res = response.data;
                        commit(types.COMMIT_NOTHING);
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log("ERR", err);
                        reject(err.response);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    createWidget({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                Axios.post(`/widget`, payload)
                    .then((response) => {
                        const res = response.data;
                        commit(types.COMMIT_NOTHING);
                        // commit(types.ENABLE_ACTIVE, 0);
                        // commit(types.DISABLE_ACTIVE, 0);
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log("ERR", err);
                        reject(err.response);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    saveWidget({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                Axios.put(`/widget/${id}`, payload)
                    .then((result) => {
                        const response = result.data;
                        commit(types.COMMIT_NOTHING);
                        // commit(types.ENABLE_ACTIVE, 0);
                        // commit(types.DISABLE_ACTIVE, 0);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    renderWidgetData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(`/widget/data/${payload.id}?skip=${payload.skip}&take=${payload.take}`)
                    .then((result) => {
                        commit(types.COMMIT_NOTHING);
                        resolve(result.data.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    addWidget({ commit, state }, data) {
        const index = state.rects.length;
        const widgetObj = {
            index: index,
            widget: data
        }
        commit(types.CREATE_WIDGET, widgetObj);
    },
    enableEdit({ commit, state }) {
        for (let i = 0; i < state.rects.length; i++) {
            commit(types.ENABLE_DRAGGABLE, i);
            commit(types.ENABLE_RESIZABLE, i);
        }
    },
    disableEdit({ commit, state }) {
        for (let i = 0; i < state.rects.length; i++) {
            commit(types.DISABLE_DRAGGABLE, i);
            commit(types.DISABLE_RESIZABLE, i);
        }
    },
    removeWidget({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                Axios.delete(`/widget/${payload.id}`)
                    .then((response) => {
                        commit(types.REMOVE_WIDGET, payload.index);
                        resolve(response.data.data);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    clearWidgets({ commit }) {
        commit(types.CLEAR_WIDGETS);
    },
    setActive({ commit, state }, { id }) {
        for (let i = 0, l = state.rects.length; i < l; i++) {
            if (i === id) {
                commit(types.ENABLE_ACTIVE, i);
                continue;
            }

            commit(types.DISABLE_ACTIVE, i);
        }
    },
    unsetActive({ commit }, { id }) {
        commit(types.DISABLE_ACTIVE, id);
    },

    toggleDraggable({ commit, state }, { id }) {
        if (!state.rects[id].draggable) {
            commit(types.ENABLE_DRAGGABLE, id);
        } else {
            commit(types.DISABLE_DRAGGABLE, id);
        }
    },

    toggleResizable({ commit, state }, { id }) {
        if (!state.rects[id].resizable) {
            commit(types.ENABLE_RESIZABLE, id);
        } else {
            commit(types.DISABLE_RESIZABLE, id);
        }
    },

    toggleParentLimitation({ commit, state }, { id }) {
        if (!state.rects[id].parentLim) {
            commit(types.ENABLE_PARENT_LIMITATION, id);
        } else {
            commit(types.DISABLE_PARENT_LIMITATION, id);
        }
    },

    toggleSnapToGrid({ commit, state }, { id }) {
        if (!state.rects[id].snapToGrid) {
            commit(types.ENABLE_SNAP_TO_GRID, id);
        } else {
            commit(types.DISABLE_SNAP_TO_GRID, id);
        }
    },

    setAspect({ commit }, { id }) {
        commit(types.ENABLE_ASPECT, id);
    },
    unsetAspect({ commit }, { id }) {
        commit(types.DISABLE_ASPECT, id);
    },

    setWidth({ commit }, { id, width }) {
        commit(types.CHANGE_WIDTH, { id, width });
    },

    setHeight({ commit }, { id, height }) {
        commit(types.CHANGE_HEIGHT, { id, height });
    },

    setTop({ commit }, { id, top }) {
        commit(types.CHANGE_TOP, { id, top });
    },

    setLeft({ commit }, { id, left }) {
        commit(types.CHANGE_LEFT, { id, left });
    },

    changeXLock({ commit, state }, { id }) {
        switch (state.rects[id].axis) {
            case 'both':
                commit(types.ENABLE_Y_AXIS, id);
                break;
            case 'x':
                commit(types.ENABLE_NONE_AXIS, id);
                break;
            case 'y':
                commit(types.ENABLE_BOTH_AXIS, id);
                break;
            case 'none':
                commit(types.ENABLE_X_AXIS, id);
                break;
        }
    },

    changeYLock({ commit, state }, { id }) {
        switch (state.rects[id].axis) {
            case 'both':
                commit(types.ENABLE_X_AXIS, id);
                break;
            case 'x':
                commit(types.ENABLE_BOTH_AXIS, id);
                break;
            case 'y':
                commit(types.ENABLE_NONE_AXIS, id);
                break;
            case 'none':
                commit(types.ENABLE_Y_AXIS, id);
                break;
        }
    },

    changeZToBottom({ commit, state }, { id }) {
        if (state.rects[id].zIndex === 1) {
            return
        }

        commit(types.CHANGE_ZINDEX, { id, zIndex: 1 });

        for (let i = 0, l = state.rects.length; i < l; i++) {
            if (i !== id) {
                if (state.rects[i].zIndex === state.rects.length) {
                    continue
                }
                commit(types.CHANGE_ZINDEX, { id: i, zIndex: state.rects[i].zIndex + 1 });
            }
        }
    },

    changeZToTop({ commit, state }, { id }) {
        if (state.rects[id].zIndex === state.rects.length) {
            return
        }

        commit(types.CHANGE_ZINDEX, { id, zIndex: state.rects.length });

        for (let i = 0, l = state.rects.length; i < l; i++) {
            if (i !== id) {
                if (state.rects[i].zIndex === 1) {
                    continue
                }
                commit(types.CHANGE_ZINDEX, { id: i, zIndex: state.rects[i].zIndex - 1 });
            }
        }
    },

    setMinWidth({ commit }, { id, width }) {
        commit(types.CHANGE_MINW, { id, minw: width });
    },

    setMinHeight({ commit }, { id, height }) {
        commit(types.CHANGE_MINH, { id, minh: height });
    },

    updatePreview({ commit }, data) {
        commit(types.UPDATE_PREVIEW, data);
    },
};