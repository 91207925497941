import axios from 'axios';
var apiInfo = JSON.parse(localStorage.apiInfo || '{}');

let axiosInstance = axios.create({

    baseURL: apiInfo.portal_url,
    // baseURL: ' http://0.0.0.0:7779',
    timeout: 20000,
    headers: {
        'Authorization': `Bearer ${apiInfo.token}`,
    },
});

axiosInstance.interceptors.request.use(
    config => {
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    config => {
        return config;
    },
    e => {
        return Promise.reject(e);
    }
);
export default axiosInstance;
/**
 * @return The adapter that axios uses for dispatching requests. This may be overwritten in tests.
 *
 * @see https://github.com/axios/axios/tree/master/lib/adapters
 * @see https://github.com/ctimmerm/axios-mock-adapter/blob/v1.12.0/src/index.js#L39
 */
export const getDefaultAdapter = () => axios.defaults.adapter;