import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.Blocklist;
var getters = {
    Blocklist: state => state.Blocklist.data,
}
const mutations = {
    [mutation_type.Blocklist]: (state, response) => {
        state.Blocklist.data = response.data.data;
    }
};
const actions = {
    Blocklist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/blocklist/fetch`).then((response) => {
                    var Blocklist_response = response;
                    resolve(response)
                        // console.log('response', response);
                    commit(mutation_type.Blocklist, Blocklist_response);
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    // DeleteBlocklistdata: (payload) => {
    //     var deleted_id = {
    //         "id": payload.id
    //     }

    //     var deletes = { data: deleted_id }

    //     return new Promise((resolve) => {
    //         api.delete(`/blocklist/del/${payload.id}`, deletes).then(response => {
    //             resolve(response);
    //         })
    //     })
    // },

    DeleteBlocklistdata: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/blocklist/del/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },


};
export default {
    state,
    getters,
    mutations,
    actions
}