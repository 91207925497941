<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
    <div class="realtime-container">
        <h4 style="text-align: center">Overall Call Summary</h4>
        <div class="content">
            <div class="row-content">
                <v-card elevation="2">
                    <span class="title-block">Total Calls</span><span class="value-block">10</span>
                </v-card>
                <v-card elevation="2">
                    <span class="title-block">Inbound Calls</span><span class="value-block">10</span>
                </v-card>
            </div>
            <div class="row-content">
                <v-card elevation="2">
                    <span class="title-block">Outbound Calls</span><span class="value-block">10</span>
                </v-card>
                <v-card elevation="2">
                    <span class="title-block">Success Calls</span><span class="value-block">10</span>
                </v-card>
            </div>
            <div class="row-content">
                <v-card elevation="2">
                    <span class="title-block">Failed Calls</span><span class="value-block">10</span>
                </v-card>
                <v-card elevation="2">
                    <span class="title-block">Agent-dropped Calls</span><span class="value-block">10</span>
                </v-card>
            </div>
            <div class="row-content">
                <v-card elevation="2">
                    <span class="title-block">Failed Calls</span><span class="value-block">10</span>
                </v-card>
                <v-card elevation="2">
                    <span class="title-block">Agent-dropped Calls</span><span class="value-block">10</span>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style scoped>
.realtime-container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    margin: auto;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.4);
}

.realtime-container .content {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.realtime-container .content .row-content {
    display: flex;
    width: 100%;
    height: 22%;
    margin: 5px auto;
}

.realtime-container .content .row-content>.v-card {
    width: 49%;
    height: 100%;
    border-radius: 5px;
    padding: 10px 0px;
    margin-left: 5px;
    display: flex;
    position: relative;
}

.realtime-container .content .row-content>.v-card .title-block {
    display: flex;
    width: 80%;
    font-size: 14px;
    padding: 0px 9px;
    font-weight: 500;
    align-items: center;
    color: crimson;
}

.realtime-container .content .row-content>.v-card .value-block {
    display: flex;
    width: 20%;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    color: deepskyblue;
}
</style>