<template>
	<div id="agentActivityReportFilter">
		<div class="row">
			<v-container class="pa-1">
				<v-menu
					v-model="page.menu.fromdate"
					:close-on-content-click="false"
					full-width
					max-width="290"
					small
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							:value="appliedfilter.fromdate"
							label="From Date"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="appliedfilter.fromdate"
						@change="page.menu.fromdate = false"
					></v-date-picker>
				</v-menu>
			</v-container>
			<v-container class="pa-1">
				<v-menu
					v-model="page.menu.todate"
					:close-on-content-click="false"
					full-width
					max-width="290"
					small
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							:value="appliedfilter.todate"
							label="To Date"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="appliedfilter.todate"
						@change="page.menu.todate = false"
					></v-date-picker>
				</v-menu>
			</v-container>
		</div>
		<v-autocomplete
			v-model="appliedfilter.agent"
			:items="filteragents"
      label="Agent"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
    <!-- <v-autocomplete
      v-model="appliedfilter.campaign"
      :items="filters.campaign"
      label="Campaign"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete> -->
    <v-autocomplete
      v-model="appliedfilter.event"
      :items="filters.event"
      label="Event"
      persistent-hint
      small
      multiple
      single-line
      :allow-overflow="false"
    >
    </v-autocomplete>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	import moment from 'moment'
	export default{
		props: ['appliedfilter'],
		data(){
			return{
				page:{
					menu:{
						fromdate:false,
						todate:false
					}
				},
				filter_fromdate:'',
				filter_todate:'',
				filteragents: [],
			}
		},

watch:{
  'appliedfilter.fromdate'(newVal){ 
    var from_date = moment(newVal).format('YYYY-MM-DD');
    this.$store.commit('ACTION_FROMDATE', from_date );
    this.$store.commit('FILTERFROMDATE', from_date);
    // this.$emit("getDataFrom");
  },
  'appliedfilter.todate'(newVal){
   var to_date = moment(newVal).format('YYYY-MM-DD');
   this.$store.commit('ACTION_TODATE', to_date );
   this.$store.commit('FILTER_TODATE', to_date);
//    this.$emit("geteditDataFrom");
 },
 'appliedfilter.agent'(newVal){
   this.$store.commit('FILTER_AGENT', { agent: newVal });
//    this.$emit("getDataFrom");
 },
 'appliedfilter.campaign'(newVal){
   this.$store.commit('AGENT_CAMPAIGN', { campaign: newVal });
//    this.$emit("getDataFrom");
 },
 'appliedfilter.event'(newVal){
   this.$store.commit('FILTER_Event', { event: newVal });
//    this.$emit("getDataFrom");
 },
},
	computed:{
 ...mapGetters([
  "filters",
  "filter",
  "queue",
  "fromdate",
  ]),
},
mounted(){
//   this.$store.dispatch('reportqueuelist');
  this.filteragents = JSON.parse(localStorage.getItem("group_users"));
  this.appliedfilter.fromdate = moment(this.filters.fromdate).format('YYYY-MM-DD');
  this.appliedfilter.todate = moment(this.filters.todate).format('YYYY-MM-DD');
  
}
};
</script>