<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
  >
    <el-radio
      v-model="radio"
      v-bind="attributesBinding"
      v-for="item in currentField.values"
      :key="item.name"
      :label="item.value"
      :disabled="item.disabled"
    >
    </el-radio>
  </el-form-item>
</template>

<script>
import fetchData from "@/api/fetch-data";
export default {
  name: "RadioButton",
  props: ["currentField"],
  data() {
    return {
      radio: "1",
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
    setTimeout(() => {
      const containers = document.querySelectorAll(
        `#${this.currentField.model}`
      );
      for (let container of containers) {
        if (container.classList.value === "el-radio is-checked") {
          this.radio = container.querySelector(
            "span.el-radio__label"
          ).outerText;
        }
      }
    }, 500);
  },
};
</script>
