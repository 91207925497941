<template>
  <v-toolbar
    :fixed="fixedToolbar"
    v-if="toolbar"
    :class="navToolbarScheme"
    :clipped-left="toolbarClippedLeft"
    app
  >
    <v-toolbar-side-icon
      class="hidden-lg-and-up"
      @click.stop="$store.dispatch('toggleDrawer', !navDrawer)"
    ></v-toolbar-side-icon>
    <v-toolbar-title
      v-text="title"
      class="ml-0 hidden-lg-and-up"
    ></v-toolbar-title>
    <v-btn
      icon
      light
      class="hidden-md-and-down"
      @click.stop="toggleMiniVariantMode"
    >
      <v-tooltip bottom v-if="navMiniVarient" color="sidebar">
        <v-icon slot="activator">fas fa-arrow-circle-right</v-icon>
        <span>Expand</span>
      </v-tooltip>
      <v-tooltip bottom v-else color="sidebar">
        <v-icon slot="activator">fas fa-arrow-circle-left</v-icon>
        <span>Collapse</span>
      </v-tooltip>
    </v-btn>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <v-avatar slot="activator" size="40" color="#2196f3">
        <span class="white--text text-h2">{{ avatarName }}</span>
      </v-avatar>
      <v-list dense>
        <v-list-tile avatar v-if="false">
          <v-list-tile-avatar>
            <img :src="authUser.avatar" :alt="authUser.name" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title v-text="authUser.name"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="() => {}">
          <v-list-tile-avatar>
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title @click="auth0Logout">Sign Out</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <div class="text-center">
      <div class="row" justify="center">
        <v-flex xs6 md3>
          <v-dialog v-model="checkingtokens" :fullscreen="true">
            <v-card>
              <v-card-title>
                <img :src="doocti" />
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="card-text">
                Your session has been expired
                <a class="click-text" @click="redirecttologin">Click Here </a>
                to login again
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-flex>
      </div>
    </div>
  </v-toolbar>
</template>
<script>
import { authUser } from "@/data/dummyData";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      title: "doocti",
      logo: "/static/doologo.png",
      doocti: "/static/doocti.png",
      checkingtokens: false,
      avatarName: "",
    };
  },
  mounted() {
    const username = JSON.parse(localStorage.getItem("projectConfig"));
    const firstLetter = username.projects[0].userInfo[0].user_name;
    this.avatarName = firstLetter.charAt(0).toUpperCase();
  },
  computed: {
    ...mapGetters({
      navDrawer: "navDrawer",
      toolbarClippedLeft: "toolbarClippedLeft",
      fixedToolbar: "fixedToolbar",
      toolbar: "toolbarVisibility",
      navToolbarScheme: "navToolbarScheme",
      navMiniVarient: "navMiniVarient",
    }),
    authUser() {
      return authUser;
    },
    ...mapGetters(["checkingtoken"]),
  },
  methods: {
    toggleMiniVariantMode() {
      this.$store.dispatch("toggleMiniVariantMode");
      this.$store.dispatch("toggleMiniVarient");
    },

    auth0Logout: function () {
      var scope = this;
      scope.$store.dispatch("auth0Logout").then(function () {
        scope.$router.push({
          name: "doocti/Auth/Login",
        });
      });
    },
    redirecttologin: function () {
      this.auth0Logout();
    },
  },
  watch: {
    checkingtoken(newVal) {
      if (newVal) {
        this.checkingtokens = true;
      }
    },
  },
};
</script>
<style scoped>
.card-text {
  margin-top: 200px;
  margin-left: 450px;
  font-size: 20px;
  word-break: break-all;
  width: 310px;
}
.click-text {
  padding-left: 20px;
}
</style>
