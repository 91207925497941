<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
  >
    <el-select
      v-if="update"
      v-model="value"
      placeholder="Select"
      :loading="loading"
      v-bind="attributesBinding"
      @change="getChildProperties()"
    >
      <el-option
        v-for="(item,index) in currentField.values"
        :key="index"
        :value="item.value"
        :label="item.name"
        :disabled="item.disabled"
      >
      </el-option>
      <!-- <el-option
        v-for="(item,index) in currentField.values"
        :key="index"
        :value="item.value"
        :label="item.name"
        :disabled="item.disabled"
        :click="getChildProperties"
        v-show="
          currentField.link_type === 'Parent' &&
            currentField.linked_to !== '' &&
            currentField.is_link
        "
      >
      </el-option>
      <el-option
        v-for="(item, index) in currentField.childValues"
        :key="index"
        :value="item.value"
        :label="item.name"
        :disabled="item.disabled"
        v-show="
          currentField.link_type === 'Child' &&
            currentField.linked_to !== '' &&
            currentField.is_link
        "
      >
      </el-option> -->
    </el-select>
  </el-form-item>
</template>

<script>
import fetchData from "@/api/fetch-data";
import qs from "qs";

export default {
  name: "SelectList",
  props: ["currentField"],
  data() {
    return {
      update: true,
      value: "",
      loading: false,
    };
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      attr.clearable = this.currentField.clearable;
      attr.multiple = this.currentField.multiple;
      attr.filterable = this.currentField.filterable;
      attr.remote = this.currentField.remote;
      if (attr.remote) {
        attr.remoteMethod = this.remoteMethod;
      }
      return attr;
    },
  },
  methods: {
    reload() {
      this.update = false;
      this.$nextTick(() => {
        this.update = true;
      });
    },
    getChildProperties() {
      const scope = this;
      if (scope.currentField.is_link === true && scope.currentField.link_type === 'Parent') {
      let payload = {
        model: scope.currentField.model,
        linked_to: scope.currentField.linked_to,
        option: scope.value,
      };
      scope.$store.dispatch("getChildProperties", payload);
      }
      },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        let dataUrl = this.currentField.dataUrl;
        let valueField = this.currentField.valueField;
        let labelField = this.currentField.labelField;
        var param = { queryStr: query };
        let promise = fetchData.fetchOptionsData(
          dataUrl,
          labelField,
          valueField,
          qs.stringify(param)
        );
        promise.then((data) => {
          this.currentField.options = data;
          this.loading = false;
        });
      } else {
        this.currentField.options = [];
      }
    },
  },
  watch: {
    "currentField.multiple"() {
      this.reload();
    },
  },
};
</script>
