<template>
  <div id="LiveAgents">
    <section-definition
      :title="page.title"
      :icon="page.icon"
      :extraOptions="page.options"
      :breadcrumbs="page.breadcrumbs"
      @openFilter="openLiveAgentsFilter"
      @refreshPage="refreshLiveAgentsPage"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="Live_agents_response"
        :pagination.sync="pagination"
        class="elevation-1"
        sort-desc="true"
      >
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.user }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.team }}</td>
          <td class="text-wrap">{{ props.item.queue }}</td>
          <td class="checkEmpty p0">{{ props.item.call_type }}</td>
          <td class="checkEmpty p0">{{ props.item.phone_number }}</td>
          <td class="checkEmpty p0">{{ props.item.station }}</td>
          <td class="checkEmpty p0">
            {{ props.item.status }}
            <div v-if="props.item.reason != null && props.item.reason !== ''">
              ({{ props.item.reason }})
            </div>
          </td>
          <td class="checkEmpty p0">{{ props.item.dur }}</td>
          <td class="text-xs-right checkEmpty p0">
            <v-btn
              small
              fab
              :color="canBarge(props.item) ? 'white' : 'success'"
              flat
              :disabled="canBarge(props.item)"
              @click="toggleBargeSheet('listen', props.item)"
              v-if="
                checkBargeStatus(props.item.status, props.item.id) &&
                  page.canBarge
              "
            >
              <i class="fas fa-eye"></i>
            </v-btn>
            <v-btn
              small
              fab
              flat
              color="error"
              @click="forceLogout(props.item)"
              v-if="page.canLogout"
            >
              <i class="fas fa-sign-out-alt"></i>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-bottom-sheet
      v-model="page.bargestatus"
      hide-overlay
      persistent
      lazy
      inset
      max-width="500"
      class="align-left"
      id="barging-div"
      transparent
    >
      <v-card
        tile
        class="player-div"
        v-if="barge_pop.status == 'CONNECT' && barge_pop.flag == '1'"
      >
        <v-toolbar color="primary" dark small>
          <v-toolbar-title small>Monitoring Agent</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="toggleBargeSheet('close', {})">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-avatar slot="activator" size="40">
                <img :src="authUser.avatar" :alt="authUser.name" />
              </v-avatar>
              <div class="ml-2">
                <div style="font-weight: bold;">{{ page.agent }}</div>
                <div class="bold">{{ forcelogoutItem.user }}</div>
                <div>{{ forcelogoutItem.station }}</div>
              </div>
            </div>
            <div>
              <v-chip
                :color="page.bargeType == 'listen' ? 'primary' : 'light'"
                :text-color="page.bargeType == 'listen' ? 'white' : 'black'"
                small
                @click="changeBargeType('listen')"
                >Listen</v-chip
              >
              <v-chip
                :color="page.bargeType == 'barging' ? 'primary' : 'light'"
                :text-color="page.bargeType == 'barging' ? 'white' : 'black'"
                small
                @click="changeBargeType('barging')"
                >Barge</v-chip
              >
              <v-chip
                :color="page.bargeType == 'w-barging' ? 'primary' : 'light'"
                :text-color="page.bargeType == 'w-barging' ? 'white' : 'black'"
                small
                @click="changeBargeType('w-barging')"
                >Wisper</v-chip
              >
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-chip color="success" text-color="white">
                <v-avatar>
                  <i class="fas fa-phone-alt"></i>
                </v-avatar>
                {{ page.bargingAgent }}
              </v-chip>
              <v-chip color="secondary" text-color="white" v-if="false">
                <v-avatar>
                  <i class="fas fa-stopwatch"></i>
                </v-avatar>
                {{ page.bargingDur }}
              </v-chip>
            </div>
            <div class="row align-center justify-sb">
              <v-text-field
                v-model="page.extension"
                :items="availextension_dropdown"
                @click:append="page.extension = ''"
                return-masked-value
                mask="###############"
                label="Extension"
                small
              ></v-text-field>
              <div v-if="page.isPlaying == true">
                <v-btn
                  icon
                  outline
                  small
                  color="error"
                  @click="page.isPlaying = false"
                >
                  <v-icon small>stop</v-icon>
                </v-btn>
              </div>
              <div v-if="page.isPlaying == false">
                <v-btn
                  icon
                  outline
                  small
                  color="error"
                  @click="startBargein(page)"
                >
                  <v-icon small>play_arrow</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-bottom-sheet>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color:white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field
              v-model="payload.number"
              label="Phone Number"
              small
            ></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="payload.agent"
              :items="filteragents"
              label="Agent"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="payload.Queue"
              :items="queue"
              label="Queue"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-text-field
              v-model="payload.Extension"
              :items="extension_dropdown"
              label="Station"
              small
            ></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="payload.Type"
              :items="type"
              label="Call Type"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="payload.Status"
              :items="status"
              label="Status"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-dialog persistent v-model="page.confirmDialog" max-width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Are You Sure To Logout The User?</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-avatar slot="activator" size="40">
                <img :src="authUser.avatar" :alt="authUser.name" />
              </v-avatar>
              <div class="ml-2">
                <div class="bold">{{ forcelogoutItem.user }}</div>
                <div>{{ forcelogoutItem.station }}</div>
              </div>
            </div>
            <div>
              <v-chip color="secondary" small text-color="white">
                <v-avatar>
                  <i class="fas fa-check-circle"></i>
                </v-avatar>
                {{ forcelogoutItem.status }}
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat="flat" @click="cancelConfirm">Cancel</v-btn>
          <v-btn color="primary" @click="okConfirm(forcelogoutItem)"
            >Continue</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="page.loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="alert error" v-if="page.errorlogout">
      <strong>Error!</strong> Error while Logging Out User. Please Try again.
    </div>
    <div class="alert success" v-if="page.successLogout">
      <strong>Success!</strong> User Logout Successfully.
    </div>
    <div class="alert error" v-if="page.barge.error">
      <strong>Error!</strong>
      {{ page.barge.message }}
    </div>
  </div>
</template>
<script>
import { authUser } from "@/data/dummyData";
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";
export default {
  components: {
    loader,
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Live Agents",
        icon: "fa-user-tie",
        loading: true,
        bargestatus: false,
        isPlaying: false,
        isFilterOpen: false,
        bargeType: "listen",
        confirmDialog: false,
        loadingDialog: false,
        errorlogout: false,
        successLogout: false,
        extension: "",
        barge: { error: false, message: "", minimized: false },
        breadcrumbs: [],
        options: { filter: true, refresh: true },
        status: "",
        flag: "",
        agent: "",
        barge_flag: "",
        id: "",
        canBarge: false,
        canLogout: false,
      },
      extensions: ["101", "102", "103", "104"],
      model: "",
      payloadqueue: "",
      payloadextension: "",
      payloadAvailextension: "",
      payloadCampaign: "",
      payloaduser: "",
      refreshvlaue: "",
      bargingAgent: {},
      forcelogoutItem: {},
      barge_option: "-",
      Live_agents: [],
      headers: [
        {
          text: "Agent",
          align: "left",
          sortable: true,
          class: "p0",
          value: "user",
        },
        {
          text: "User Name",
          align: "left",
          sortable: false,
          class: "p0",
          value: "user",
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "user",
        },
        {
          text: "Queue",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
        {
          text: "Call Type",
          align: "left",
          sortable: false,
          class: "p0",
          value: "call_type",
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "phone_number",
        },
        {
          text: "Station",
          align: "left",
          sortable: true,
          class: "p0",
          value: "station",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          class: "p0",
          value: "status",
        },
        {
          text: "Duration",
          align: "left",
          sortable: true,
          class: "p0",
          value: "dur",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          class: "p0",
          value: "action",
        },
      ],
      liveagents: [],
      agent_data: [],
      result_id: "",
      barge_pop: {
        status: "",
        flag: "",
        name: "",
      },
      payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      startup_payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      settimeoutstop: false,
      pagination: {
        rowsPerPage: -1,
        sortBy: "name",
        descending: true,
      },
      filteragents: [],
    };
  },
  computed: {
    ...mapGetters([
      "Live_agents_response",
      "queue_dropdown",
      "extension_dropdown",
      "availextension_dropdown",
      "type",
      "status",
      "user",
      "queue",
      "campaign",
    ]),
    authUser() {
      return authUser;
    },
    isbarge: function() {
      var barge = this.page.id;
      if (barge != "" && barge != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "page.status"(newval) {
      this.barge_pop.status = newval;
    },
    "page.flag"(newval) {
      this.barge_pop.flag = newval;
    },
    "page.name"(newval) {
      this.barge_pop.name = newval;
    },
  },
  methods: {
    toggleBargeSheet: function(event, item) {
      if (event == "close") {
        this.page.bargestatus = false;
        this.page.isPlaying = false;
        this.page.bargeType = "listen";
        this.page.extension = "";
        this.page.bargingAgent = item.station;
        this.page.bargingDur = item.dur;
        this.page.id = "";
        this.page.flag = "";
        this.page.agent = "";
        this.props.item.status = "connect";
      } else {
        this.page.bargestatus = true;
        this.page.isPlaying = false;
        this.page.bargeType = event;
        this.page.extension = "";
        this.page.bargingAgent = item.station;
        this.page.bargingDur = item.dur;
        this.page.status = item.status;
        this.page.flag = 1;
        this.page.id = item.id;
        this.page.agent = item.name;
      }
    },
    checkBargeStatus(name, id) {
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "realtime:liveagents:barge"
        ) !== -1
      ) {
        if (name == "CONNECT") {
          if (scope.isbarge) {
            if (id == this.page.id) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    fetchFilter() {
      const scope = this;
      var string_data = JSON.stringify(scope.payload);
      scope.startup_payload = JSON.parse(string_data);
      scope.livaagents();
    },
    livaagents() {
      const scope = this;
      //scope.$store.dispatch("reportuserlist").then((response) => {
      //   let dataa = response.toString(",");
      // scope.payload.agent = JSON.parse(localStorage.getItem("group_users"));
      this.$store
        .dispatch("liveagents", this.startup_payload)
        .then(function(result) {
          var result_data = result.data.data;
          scope.agent_data = [];
          scope.Live_agents = [];
          result_data.forEach(function(result) {
            if (result.id == scope.page.id) {
              if (result.status != "CONNECT") {
                scope.page.flag = 0;
                scope.page.agent = "";
                scope.page.id = "";
              }
            }
          });
          if (scope.refreshvlaue == "1") {
            scope.page.loading = false;
          }
          scope.settimeoutstop = true;
          scope.timeOutLiveAgent();
        })
        .catch(function(err) {
          if (err.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      //});
    },
    timeOutLiveAgent: function() {
      var scope = this;
      if (scope.settimeoutstop) {
        this.liveAgentTime = setTimeout(function() {
          scope.livaagents();
        }, 7000);
        scope.settimeoutstop = false;
      }
    },
    resetTimeout: function() {
      clearTimeout(this.liveAgentTime);
      this.livaagents();
    },
    queuelist() {
      this.$store.dispatch("queuelist", this.payloadqueue);
    },
    extensionlist() {
      this.$store.dispatch("extensionlist", this.payloadextension);
    },

    Availextensionlist() {
      this.$store.dispatch("Availextensionlist", this.payloadAvailextension);
    },
    campaignlist() {
      this.$store.dispatch("campaignList", this.payloadCamapign);
    },
    agentlist() {
      this.$store.dispatch("userlist", this.payloaduser);
    },
    changeBargeType: function(event) {
      this.page.bargeType = event;
    },
    canBarge: function(item) {
      if (item.status != "Idle") {
        return false;
      } else {
        return true;
      }
    },
    forceLogout(item) {
      this.forcelogoutItem = item;
      this.page.confirmDialog = true;
    },
    restvalues() {
      this.startup_payload = {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      };
      this.payload = {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      };
      // this.startup_payload.number = "";
      // this.startup_payload.agent = "";
      // this.startup_payload.Queue = "";
      // this.startup_payload.Campaign = "";
      // this.startup_payload.Extension = "";
      // this.startup_payload.Type = "";
      // this.startup_payload.Status = "";
      this.livaagents();
    },
    okConfirm(forcelogoutItem) {
      this.$store.dispatch("userLogout", forcelogoutItem);
      this.livaagents();
      this.page.confirmDialog = false;
      this.page.loadingDialog = true;
      var scope = this;
      setTimeout(
        function() {
          scope.page.loadingDialog = false;
          scope.page.successLogout = true;
          setTimeout(
            function() {
              scope.page.successLogout = false;
            }.bind(scope),
            4000
          );
        }.bind(scope),
        2000
      );
    },
    cancelConfirm() {
      this.forcelogoutItem = {};
      this.page.confirmDialog = false;
    },
    startBargein: function() {
      var scope = this;
      if (this.page.extension == "Other") {
        this.page.extension = this.page.other_exten;
      }
      if (this.page.bargeType == "") {
        alert("Please Fill Barge Type");
        this.page.isPlaying = false;
      }
      var barge = {
        bargeType: this.page.bargeType,
        bargeChannel: this.page.bargingAgent,
        bargeVia: this.page.extension,
      };
      if (this.page.extension != "") {
        if (this.page.bargeType != "") {
          this.$store.dispatch("barge", barge);
          this.page.isPlaying = true;
        } else {
          this.page.isPlaying = false;
        }
      } else {
        if (this.page.extension == "" && this.page.bargeType != "") {
          scope.page.barge.message = "Please Enter the Extension";
          this.page.isPlaying = false;
          scope.page.barge.error = true;
          setTimeout(
            function() {
              scope.page.barge = { error: false, message: "" };
            }.bind(scope),
            4000
          );
        }
      }
    },

    openLiveAgentsFilter: function() {
      this.page.isFilterOpen = true;
    },
    refreshLiveAgentsPage: function() {
      var scope = this;
      scope.page.loading = true;
      scope.restvalues();
      scope.refreshvlaue = "1";
    },
  },
  mounted() {
    let scope = this;
    this.filteragents = JSON.parse(localStorage.getItem("group_users"));
    this.page.loading = false;
    this.livaagents();
    this.queuelist();
    this.extensionlist();
    this.Availextensionlist();
    this.campaignlist();
    this.agentlist();
    setTimeout(
      function() {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:liveagents:barge"
          ) !== -1
        ) {
          scope.page.canBarge = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:liveagents:forcelogout"
          ) !== -1
        ) {
          scope.page.canLogout = true;
        }
      }.bind(scope),
      1000
    );
  },
  beforeDestroy() {
    this.payloadqueue = "";
    this.payloadextension = "";
    this.payloadAvailextension = "";
    this.payloadCamapign = "";
    this.payloaduser = "";
    this.$store.dispatch("queuelist", "");
    this.$store.dispatch("extensionlist", "");
    this.$store.dispatch("Availextensionlist", "");
    this.$store.dispatch("campaignList", "");
    this.$store.dispatch("userlist", "");
    this.settimeoutstop = false;
    clearTimeout(this.liveAgentTime);
  },
};
</script>
<style>
.text-wrap {
  /* .test1 {      
    width: 11em;      
    background-color: lightblue;       
    border: 2px solid black;      
    padding: 10px;      
    word-wrap: break-word;      
    font-size: 20px;    
}    */
  max-width: 112px;
  white-space: normal;
  word-break: break-word;
}

.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.icons {
  height: 80px;
}
</style>
