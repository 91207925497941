import { render, staticRenderFns } from "./EditAsterLead.vue?vue&type=template&id=ecbb86e6&"
import script from "./EditAsterLead.vue?vue&type=script&lang=js&"
export * from "./EditAsterLead.vue?vue&type=script&lang=js&"
import style0 from "./EditAsterLead.vue?vue&type=style&index=0&id=ecbb86e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports