/* eslint-disable no-empty-pattern */
import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
import auth0Variables from '../../utils/auth0-variables';
const state = config.queueManagement;
const auth0Config = auth0Variables();
const common_api_url = auth0Config.common_api_url;

var getters = {
    queuedata: state => state.queueManagement.queuedata
}
const mutations = {
    [mutation_type.queueManagement]: (state, response) => {
        if (response.data.data)
            state.queueManagement.queuedata = response.data.data;
        else
            state.queueManagement.queuedata = [];
    },
    [mutation_type.CommitNothing]: () => {},
    filter_Queue: (state, response) => {
        state.queueManagement.filter.Queue = response.name;
    },
}
const actions = {
    queueManagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/queue?name=${state.queueManagement.filter.Queue}&limit=${payload.take}&offset=${payload.skip}`)
                    .then(response => {
                        var queueManagement_response = response;
                        commit(mutation_type.queueManagement, queueManagement_response);
                        resolve(response);
                    }).catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        })
    },
    queue: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/queue?limit=0&offset=0`)
                    .then(response => {
                        commit(mutation_type.queueManagement, response);
                        var length = [];
                        for (let i = 0; i < response.data.totalCount; ++i) {
                            length.push(response.data.data[i].name);
                        }
                        let queues = response.data.data.map(d => d.name);
                        localStorage.setItem("group_queue", JSON.stringify(queues));
                        resolve(length);
                    }).catch(function(error) {
                        commit(mutation_type.queueManagement, error);
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        })
    },

    NewQueue: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                var newdata = {
                    "name": payload.name,
                    "timeout": payload.timeout,
                    "retry": payload.retry,
                    "wrapuptime": payload.wrapuptime,
                    "maxlen": payload.maxlen,
                    "strategy": payload.strategy,
                    "weight": payload.weight,
                    "timeoutrestart": payload.timeoutrestart,
                    "account_code": payload.account_code,
                    "musiconhold": payload.musiconhold
                }
                api.post(`${common_api_url}/queue`, newdata).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response)
                }).catch(function(error) {

                    reject(error.response);
                })
            } catch (error) {
                reject(error);
            }
        });

    },
    edit: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            var data = {
                "name": payload.name,
                "announce_holdtime": payload.announce_holdtime,
                "timeout": payload.timeout,
                "retry": payload.retry,
                "maxlen": payload.maxlen,
                "strategy": payload.strategy,
                "timeoutrestart": payload.timeoutrestart,
                "account_code": payload.account_code
            }
            api.put(`${common_api_url}/queue/${payload.id}`, data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error.response)
            })
        })
    },
    Deleterow: ({ commit }, payload) => {
        return new Promise((resolve) => {

            var data = {
                'id': payload.id
            }
            api.delete(`${common_api_url}/queue/${payload.id}`, data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            })
        })
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}