<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="pausecodeData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.dispo_id }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">{{ props.item.active }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Disposition</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are your Sure ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddDisposition" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Create Disposition</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="addDisposition.payload.dispo_id"
                required
                label="Dispo Id"
                type="text"
                :rules="[(v) => !!v || 'Dispo_id is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="addDisposition.payload.description"
                required
                label="Description"
                type="text"
                :rules="[(v) => !!v || 'Description is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-switch
                v-model="addDisposition.payload.call_back"
                color="primary"
                flat
                label="Call back"
              ></v-switch>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="addDisposition.payload.active"
                required
                label="Status"
                :items="status"
                :rules="[(v) => !!v || 'Status is required']"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
          <v-btn color="primary" flat @click="saveAddPausecode()">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditDisposition" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Update Disposition</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="editDisposition.dispo_id"
                required
                label="Dispo Id"
                type="text"
                :rules="[(v) => !!v || 'dispo_id is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editDisposition.description"
                required
                label="Description"
                type="text"
                :rules="[(v) => !!v || 'Description is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-switch
                v-model="editDisposition.call_back"
                flat
                color="primary"
                label="Call back"
              ></v-switch>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="editDisposition.active"
                required
                label="Status"
                :items="status"
                :rules="[(v) => !!v || 'Status is required']"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.dispo_id"
              :items="filtervalue"
              label="dispo_id"
              small
            ></v-select>
            <v-select
              v-model="filter.payload.active"
              label="Status"
              :items="status"
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Disposition",
        icon: "fa-clipboard",
        loading: false,
        isAddDisposition: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditDisposition: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Dispo Id",
          align: "left",
          class: "p0",
          sortable: true,
          value: "dispo_id",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      pausecodeData: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          active: "",
          dispo_id: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      call_status: [],
      editDisposition: {
        id: "",
        dispo_id: "",
        description: "",
        active: "",
        call_back: "",
      },
      addDisposition: {
        valid: false,
        payload: {
          dispo_id: "",
          description: "",
          active: "",
          call_back: false,
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      status: ["Active", "Inactive"],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      if (scope.filter.payload.active === "Active") {
        scope.filter.payload.active = "Y";
      }
      if (scope.filter.payload.active === "Inactive") {
        scope.filter.payload.active = "N";
      }
      var data = {
        dispo_status: scope.filter.payload.active,
        dispo_id: scope.filter.payload.dispo_id,
      };
      // console.log();
      this.$store
        .dispatch("FilterDisposition", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.pausecodeData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      scope.filter.payload.active = "";
      scope.filter.payload.dispo_id = "";
      this.page.isFilterOpen = false;
      scope.filtervalue = [];
      this.$store
        .dispatch("GetDisposition")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            datas.forEach((element) => {
              scope.filtervalue.push(element["dispo_id"]);
            });
          }
          scope.pausecodeData = datas;
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
    },
    AddNewRule: function () {
      this.page.isAddDisposition = true;
    },
    closeEditRules: function () {
      this.page.isEditDisposition = false;
    },
    closeAddPausecode: function () {
      this.page.isAddDisposition = false;
    },
    saveEditRules() {
      var scope = this;

      if (scope.editDisposition.active === "Active") {
        scope.editDisposition.active = "Y";
      }
      if (scope.editDisposition.active === "Inactive") {
        scope.editDisposition.active = "N";
      }

      if (scope.editDisposition.call_back === true) {
        scope.editDisposition.call_back = "Y";
      }
      if (scope.editDisposition.call_back === false) {
        scope.editDisposition.call_back = "N";
      }

      scope.$store
        .dispatch("updateDisposition", scope.editDisposition)
        .then(() => {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("Edited Successfully");
          this.page.isEditDisposition = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
      if (scope.addDisposition.payload.active === "Active") {
        scope.addDisposition.payload.active = "Y";
      }
      if (scope.addDisposition.payload.active === "Inactive") {
        scope.addDisposition.payload.active = "N";
      }

      if (scope.addDisposition.payload.call_back === true) {
        scope.addDisposition.payload.call_back = "Y";
      }
      if (scope.addDisposition.payload.call_back === false) {
        scope.addDisposition.payload.call_back = "N";
      }

      scope.$store
        .dispatch("insertDisposition", scope.addDisposition.payload)
        .then(() => {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("Created Successfully");
          scope.addDisposition.payload.dispo_id = "";
          scope.addDisposition.payload.description = "";
          scope.addDisposition.payload.active = "";
          scope.addDisposition.payload.call_back = false;
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          scope.addDisposition.payload.dispo_id = "";
          scope.addDisposition.payload.description = "";
          scope.addDisposition.payload.active = "";
          scope.addDisposition.payload.call_back = false;
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      let scope = this;
      this.page.isEditDisposition = true;
      this.editDisposition.id = item.id;
      this.editDisposition.dispo_id = item.dispo_id;
      this.editDisposition.description = item.description;

      if (item.active === "Y") {
        scope.editDisposition.active = "Active";
      }
      if (item.active === "N") {
        scope.editDisposition.active = "Inactive";
      }

      if (item.call_back === "Y") {
        scope.editDisposition.call_back = true;
      }
      if (item.call_back === "N") {
        scope.editDisposition.call_back = false;
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteDisposition", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Disposition Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Disposition" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
