<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addData="exportAdd"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="contactData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.contactName }}</td>
          <td class="checkEmpty p0">{{ props.item.contactNumber }}</td>
          <td class="checkEmpty p0">{{ props.item.email }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Contact</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Contact.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddContact" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Create Contact</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="addContact.payload.name"
                required
                label="Name"
                type="text"
                :rules="[(v) => !!v || 'Name is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="addContact.payload.number"
                required
                label="Mobile Number"
                type="text"
                onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                :rules="mobileRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="addContact.payload.email"
                required
                label="E-Mail"
                type="text"
                :rules="emailRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="addContact.payload.address"
                required
                label="Address"
                type="text"
                :rules="[(v) => !!v || 'Address is required']"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddContact()">Close</v-btn>
          <v-btn color="primary" flat @click="saveAddContact()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditContact" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Edit Contact</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="editContact.name"
                required
                label="Name"
                type="text"
                :rules="[(v) => !!v || 'Name is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editContact.number"
                required
                label="Mobile Number"
                type="text"
                :rules="mobileRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editContact.email"
                required
                label="E-Mail"
                type="text"
                :rules="emailRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editContact.address"
                required
                label="Address"
                type="text"
                :rules="[(v) => !!v || 'Address is required']"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditContact()">Close</v-btn>
          <v-btn color="primary" flat @click="saveEditContact()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isUploadContact" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Upload Contact</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <p>
            <span style="font-weight: bold">Not sure what to upload? </span
            ><a @click="downloadSample">Click here</a> and download the sample
            CSV file. Open the file and enter the phone numbers at the first
            column of each row. Save the file with .csv extension and upload the
            file.
          </p>
          <div
            class="file-dummy"
            @click="$refs.file.click()"
            :style="{
              background: uploadContact.color + '66',
              'border-color': uploadContact.color,
              color: uploadContact.color,
            }"
          >
            {{ uploadContact.label }}
          </div>
          <input
            hidden
            type="file"
            id="file"
            ref="file"
            accept=".csv"
            v-on:change="handleFileUpload()"
          />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseUploadContact(false)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field
              v-model="filter.payload.name"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="filter.payload.number"
              label="Mobile Number"
            ></v-text-field>
            <v-text-field
              v-model="filter.payload.email"
              label="E-Mail"
            ></v-text-field>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
const { convertArrayToCSV } = require("convert-array-to-csv");
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    loader,
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Contact",
        icon: "fa-clipboard",
        loading: false,
        isAddContact: false,
        isFilterOpen: false,
        isUploadContact: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        isEditContact: false,
        temp: { key: "addcontact", label: "Add contact" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "contactName",
        },
        {
          text: "Mobile Number",
          align: "left",
          class: "p0",
          sortable: false,
          value: "number",
        },
        {
          text: "E-Mail",
          align: "left",
          class: "p0",
          sortable: false,
          value: "email",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      contactData: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      uploadContact: {
        color: "",
        label: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filter: {
        payload: {
          name: "",
          number: "",
          email: "",
        },
      },
      isProcessing: false,
      call_status: [],
      editContact: {
        id: "",
        name: "",
        number: "",
        email: "",
        address: "",
      },
      addContact: {
        valid: false,
        payload: {
          name: "",
          number: "",
          email: "",
          address: "",
        },
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) =>
          (!isNaN(parseFloat(v)) && v >= 999999999 && v <= 999999999999999) ||
          "Please Enter a Valid Phone Number",
      ],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      this.$store
        .dispatch("FilterContact", scope.filter.payload)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.contactData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    exportAdd: function (option) {
      if (option == "Add Single") {
        this.page.isAddContact = true;
      } else {
        this.page.isUploadContact = true;
      }
    },
    CloseUploadContact: function () {
      this.page.isUploadContact = false;
      this.uploadContact = getDefaultData();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store
        .dispatch("GetContact")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          scope.contactData = datas;
        })
        .catch(function (error) {
          scope.page.isAddContact = false;
          scope.showSnackBar("error" + error);
          this.page.isEditContact = false;
        });
    },
    closeEditContact: function () {
      this.page.isEditContact = false;
    },
    closeAddContact: function () {
      this.page.isAddContact = false;
    },
    saveEditContact() {
      var scope = this;
      scope.$store
        .dispatch("updateContact", scope.editContact)
        .then(() => {
          scope.page.isAddContact = false;
          scope.showSnackBar("Contact Updated Successfully");
          this.getDataFromApi();
          this.page.isEditContact = false;
        })
        .catch(function (error) {
          scope.page.isAddContact = false;
          scope.showSnackBar("Error: " + error);
          this.page.isEditContact = false;
        });
      this.getDataFromApi();
    },
    saveAddContact() {
      var scope = this;
      scope.$store
        .dispatch("insertContact", scope.addContact.payload)
        .then(() => {
          scope.page.isAddContact = false;
          scope.showSnackBar("Contact Created Successfully");
          this.getDataFromApi();
          scope.addContact.payload.name = "";
          scope.addContact.payload.number = "";
          scope.addContact.payload.email = "";
          scope.addContact.payload.address = "";
        })
        .catch(function (error) {
          scope.page.isAddContact = false;
          scope.showSnackBar("Error:" + error);
          scope.addContact.payload.name = "";
          scope.addContact.payload.number = "";
          scope.addContact.payload.email = "";
          scope.addContact.payload.address = "";
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      this.page.isEditContact = true;
      this.editContact.id = item.id;
      this.editContact.name = item.contactName;
      this.editContact.number = item.contactNumber;
      this.editContact.email = item.email;
      this.editContact.address = item.address;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteContact", this.delete_id)
        .then(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Contact Deleted Successfully");
          scope.getDataFromApi();
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete Contact");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    downloadSample: function () {
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += "contactName,contactNumber,email,address";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sampleContact.csv");
      document.body.appendChild(link);
      link.click();
    },
    handleFileUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadContact.color = "#A64452";
      scope.uploadContact.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/asterdialer/contact/upload", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.getDataFromApi();
          scope.uploadContact.color = "#4BB543";
          scope.uploadContact.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          if (response.data.skippedData.length > 0) {
            let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            let skipvalue = convertArrayToCSV(response.data.skippedData);
            csvContent += skipvalue;
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "skipContact.csv");
            document.body.appendChild(link);
            link.click();
          }
          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadContact.color = "#F51B00";
          scope.uploadContact.label = "Upload Error";
          scope.$refs.file.clearAll();
          return error;
        });
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.uploadContact = getDefaultData();
    this.$store.dispatch("uploadContact").then((response) => {
      var datas = response.data.data;
      this.table.data = datas;
    });
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:contact:create"
      ) !== -1
    ) {
      scope.page.exportOptions.push("Add Single");
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:contact:bulkupload"
      ) !== -1
    ) {
      scope.page.exportOptions.push("Add Bulk Upload");
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:contact:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:contact:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
