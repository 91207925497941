<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewForm"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="customformData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty text-xs-left p0">
            {{ props.item.template_name }}
          </td>
          <td class="checkEmpty text-xs-left p0">{{ props.item.count }}</td>
          <td class="checkEmpty text-xs-left p0">
            <v-flex xs4 v-if="props.item.active === 'Y'"> Yes </v-flex>
            <v-flex xs4 v-if="props.item.active === 'N'"> No </v-flex>
          </td>
          <td class="text-xs-left checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Template info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Template.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddCustomForm" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Select the Domain</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-select
                style="text-decoration: none"
                v-model="formDomain"
                :items="domain"
                label="Domain"
                item-text="name"
                item-value="value"
                return-string
                persistent-hint
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddCustomform()">Close</v-btn>
          <v-btn color="primary" flat @click="saveAddCustomform()"
            >Continue</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditCustomform" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">update CustomForm</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="editCustomform.pause_code"
                required
                label="Pause Code"
                type="text"
                :rules="[(v) => !!v || 'Pausecode is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editCustomform.description"
                required
                label="Description"
                type="text"
                :rules="[(v) => !!v || 'Description is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="editCustomform.status"
                required
                label="Status"
                type="text"
                :rules="[(v) => !!v || 'Status is required']"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.template_name"
              :items="filtervalue"
              label="Template Name"
              small
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Custom Forms",
        icon: "fa-clipboard",
        loading: false,
        isAddCustomForm: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditCustomform: false,
        temp: { key: "addcustomform", label: "Add CustomForm" },
        candelete: false,
        canedit: false,
      },
      headers: [
        {
          text: "Template Name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "template_name",
        },
        {
          text: "Total Fields",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Active",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          align: "left",
          class: "p0",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      customformData: [],
      pagecount: 0,
      delete_item: {},
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      domain: [
        { name: "SALES", value: "Sales" },
        /* { name: "SUPPORT", value: "Support" },
        { name: "E-COMMERCE", value: "Ecommerce" }, */
      ],
      formDomain: "",
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          template_name: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      call_status: [],
      editCustomform: {
        id: "",
        pause_code: "",
        type: "",
        description: "",
        status: "",
      },
      addCustomform: {
        valid: false,
        payload: {
          template_name: "",
          type: "",
          description: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
    };
  },
  methods: {
    refreshThisPage: function() {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function() {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      this.$store
        .dispatch("FilterCustomForm", scope.filter.payload)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.customformData = datas;
        })
        .catch(function(error) {
          scope.page.isAddCustomForm = false;
          scope.showSnackBar("error" + error);
          this.page.isEditCustomform = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      scope.filter.payload.pause_code = "";
      this.page.isFilterOpen = false;
      scope.filtervalue = [];
      this.$store
        .dispatch("GetCustomForm")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            datas.forEach((element) => {
              scope.filtervalue.push(element["template_name"]);
            });
          }
          scope.customformData = datas;
        })
        .catch(function(error) {
          scope.page.isAddCustomForm = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditCustomform = false;
        });
    },
    AddNewForm: function() {
      var scope = this;
      scope.page.isAddCustomForm = true;
    },
    closeEditRules: function() {
      this.page.isEditCustomform = false;
    },
    closeAddCustomform: function() {
      this.page.isAddCustomForm = false;
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("UpdateFields", scope.editCustomform)
        .then(() => {
          // console.log(response);
          scope.page.isAddCustomForm = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditCustomform = false;
        })
        .catch(function(error) {
          scope.page.isAddCustomForm = false;
          scope.showSnackBar("error" + error);
          this.page.isEditCustomform = false;
        });
      this.getDataFromApi();
    },
    saveAddCustomform() {
      var scope = this;
      scope.$store
        .dispatch("GetDefaultFields", scope.formDomain)
        .then(() => {
          scope.$store.dispatch("clearForm", scope.formDomain);
          scope.$router.push({
            name: "crm/CustomForm",
          });
        })
        .catch(function(error) {
          scope.page.isAddCustomForm = false;
          scope.showSnackBar("error" + error);
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(item) {
      this.DeleteRowdata = true;
      this.delete_item = item;
    },
    editItem(item) {
      // console.log("item", item);
      var scope = this;
      scope.$store
        .dispatch("GetFormFields", item.template_name)
        .then(() => {
          scope.$router.push({
            name: "crm/CustomForm",
          });
        })
        .catch(function(error) {
          scope.showSnackBar("error" + error);
        });
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteCustomform", this.delete_item)
        .then((response) => {
          scope.isProcessing = false;
          if (response.message === "Success") {
            scope.showSnackBar("Deleted Successfully");
            scope.getDataFromApi();
          }
        })
        .catch(function(error) {
          scope.isProcessing = false;
          scope.showSnackBar(error);
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function() {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:custom-form:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Custom Form" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:custom-form:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:custom-form:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    // this.page.addnew = [{ label: 'Add Custom Form' }]
    // scope.page.candelete = true
    // scope.page.canedit = true
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
.application a {
  text-decoration: none;
}
</style>
