import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterCampaign;
var getters = {
    AsterCampaign: state => state.AsterCampaign.getdata
};
const mutations = {
    [mutation_type.AsterCampaign]: (state, response) => {
        state.AsterCampaign.getdata = response;
    },
    [mutation_type.RefreshAsterCampaign]: (state, response) => {
        state.AsterCampaign.forms = response;
    },
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    GetAsterCampaigns: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/campaigns`).then(response => {
                    const campaign = response.data;
                    commit(mutation_type.AsterCampaign, campaign);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetPausecode: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/pausecode`).then(response => {
                    const pausecode = response.data;
                    commit(mutation_type.PauseCode, pausecode);
                    // localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterCampaigns: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/campaigns?campaign_name=${query.campaign_name}&call_type=${query.campaign_type}&active=${query.campaign_status}`).then(response => {
                    const campaign = response.data;
                    commit(mutation_type.AsterCampaign, campaign);
                    resolve(response.data);
                });

            } catch (error) {
                reject(error);
            }
        });
    },
    insertAsterCampaign: ({ commit }, payload) => {
      
        return new Promise((resolve, reject) => {
            try {
                if (payload.dnc_check) {
                    payload.dnc_check = "Y";
                } else {
                    payload.dnc_check = "N";
                }
                if (payload.active.name) {
                    payload.active = "Y";
                } else {
                    payload.active = "N";
                }
                if (payload.call_type != "auto") {
                    payload.dial_ratio = "0";
                }
                if (payload.call_type != "VB") {
                    payload.channel_count = 0;
                }

                api
                    .post(`/aster/campaigns`, payload)
                    .then(response => {
                        const campaign = response.data;
                        commit(mutation_type.AsterCampaign, campaign);
                        // commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetTemplate: () => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/aster/form/template`)
                    .then(response => {
                        let data = response.data.data
                        let template = []
                        for (let i = 0; i < data.length; i++) {
                            template.push(data[i].template_name);
                        }
                        resolve(template);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterCampaign: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/campaigns/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAsterCampaign: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                if (payload.call_type != "VB") {
                    payload.channel_count = 0;
                }

                if (payload.dnc_check == true) {
                    payload.dnc_check = "Y";
                } else {
                    payload.dnc_check = "N";
                }
                api
                    .put(`/aster/campaigns/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};