<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
    class="el-form-checkbox"
  >
    <el-checkbox-group v-model="checkList" v-bind="attributesBinding">
      <el-checkbox
        v-for="item in currentField.values"
        :id="currentField.model"
        :key="item.name"
        :label="item.value"
        :disabled="item.disabled"
      >
      </el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
import fetchData from "@/api/fetch-data";
export default {
  name: "Checkbox",
  props: ["currentField"],
  data() {
    return {
      checkList: [],
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;
      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
    setTimeout(() => {
      const containers = document.querySelectorAll(
        `#${this.currentField.model}`
      );
      for (let container of containers) {
        if (container.classList.value === "el-checkbox is-checked") {
          this.checkList.push(
            container.querySelector("span.el-checkbox__label").outerText
          );
        }
      }
    }, 500);
  },
};
</script>

<style>
.el-checkbox-group {
  font-size: 14px;
}
.el-form-checkbox {
  padding: 7px !important;
  margin-bottom: 0;
}
</style>
