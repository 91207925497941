import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
import auth0Variables from '../../utils/auth0-variables';
const state = config.groupManagement;
const auth0 = config.auth0;
const auth0Config = auth0Variables();
const common_api_url = auth0Config.common_api_url;

const getters = {
    groupData: state => state.groupManagement.groupsdata
};
const mutations = {
    ModifyUserPermissions: (state, payload) => {
        auth0.settings.permissions = payload.permissions;
        auth0.settings.group_id = payload.group_id;
    },
    ModifyGroupUser: (state, payload) => {
        auth0.settings.userList = payload;
    },

    [mutation_type.CommitNothing]: () => {},

    GetUsers: (state, payload) => {
        config.overall_report_state.filters.agent = payload;
    },
};
const actions = {
    groupLists: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/groups`).then(response => {
                    let data = response.data.data;
                    resolve(data);
                    commit(mutation_type.CommitNothing, {});
                }).catch(() => {
                    reject([]);
                });
            } catch (e) {
                reject([]);
            }
        });
    },
    editGroupList: (_, data) => {
        return new Promise((resolve, reject) => {
            try {
                api.put(`${common_api_url}/group/update/${data.args}`, data.params).then(response => {
                    resolve(response.data);
                }).catch(() => {
                    reject();
                })
            } catch (e) {
                reject();
            }
        });
    },
    createGroupList: (_, data) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`${common_api_url}/group/create`, data).then(response => {
                    if (response.data.statusCode === 201) {
                        let data = response.data.data;
                        if (!data.group_id) {
                            if (data.id) {
                                data.group_id = data.id
                            }
                        }
                        resolve(data);
                    }
                }).catch(() => {
                    reject();
                })
            } catch (e) {
                reject();
            }
        });
    },
    deleteGroupList: (_, data) => {
        return new Promise((resolve, reject) => {
            try {
                api.delete(`${common_api_url}/group/delete/${data.args}`).then(response => {
                    resolve(response.data);
                }).catch(() => {
                    reject();
                })
            } catch (e) {
                reject();
            }
        });
    },
    getPermissions: () => {
        return new Promise((resolve) => {
            api.get(`${common_api_url}/client/permissions`).then(response => {
                let permissions = response.data.data;
                let res={}
                let obj = {};
                permissions.forEach((val) => {
                    let str = val.permission_name.split(':');
                    if (!obj[str[0]]) {
                        obj[str[0]] = { module: str[0], value: {} };
                        obj[str[0]].value[str[1]] = { sub_module: str[1], value: [{ title: str[2], permission: val.permission_value }] };
                    } else if (!obj[str[0]].value[str[1]]) {
                        obj[str[0]].value[str[1]] = { sub_module: str[1], value: [{ title: str[2], permission: val.permission_value }] };
                    } else {
                        obj[str[0]].value[str[1]].value.push({ title: str[2], permission: val.permission_value });
                    }
                });
                res.obj=obj
                res.permission_data=response.data.data
                // console.log(JSON.stringify(obj));
                // let data = [
                //   {
                //     category: {
                //       title: "Permissions",
                //     },
                //     values: permissions
                //   }
                // ]
                resolve(res);
            }).catch(() => {
                resolve([]);
            });
        })
    },
    getGroupUserLists: ({ commit }, data) => {
        // localStorage.groupId = data;
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/group/users?group_id=${data}`).then(response => {
                    commit("ModifyGroupUser", response.data.data);
                    let users = response.data.data.map(d => d.user_email);
                    //let data = users.replace('"', ' ');
                    commit("GetUsers", users);
                    localStorage.setItem("group_users", JSON.stringify(users));
                    resolve(users);
                }).catch((err) => {
                    reject(err);
                })
            } catch (e) {
                reject(e);
            }
        });
    },
    getUserPermissions: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/user/permissions`).then(response => {
                    commit("ModifyUserPermissions", response.data.data);
                    dispatch("getGroupUserLists", response.data.data.group_id);
                    dispatch("reporttemplatelist");
                    dispatch("GetDisposition");
                    dispatch("GetPausecode");
                    dispatch("queue");
                    dispatch("ReportcampaignList");
                    localStorage.permissionInfo = JSON.stringify(response.data.data);
                    resolve(response.data.data);
                }).catch((err) => {
                    reject(err);
                })
            } catch (e) {
                reject(exports);
            }
        });
    },
    getGroupPermissions: (_, data) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`${common_api_url}/group/permissions?group_id=${data}`).then(response => {
                    resolve(response.data.data.permission);
                }).catch(() => {
                    reject([]);
                })
            } catch (e) {
                reject([]);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};