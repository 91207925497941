<template>
    <div>
      <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
        :addNewData="page.addnew" :exportOptions="page.exportOptions"
        @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject"></section-definition>
      <loader v-if="page.loading"></loader>
      <v-container v-else fluid grid-list-xl page>
        <v-data-table :headers="headers" :items="TeamData" class="elevation-1 fixed-layout"
          :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" >
          <template v-slot:no-data style="text-align: center">
            <div style="text-align: center">No Data Available</div>
          </template>
          <template v-slot:items="props">
            <td class="checkEmpty p0">{{ props.item.name }}</td>
            <td class="checkEmpty p0">{{ props.item.description }}</td>
            <td class="checkEmpty p0">{{ props.item.user.length }}</td>
            <td class="checkEmpty p0">
              <template v-if="props.item.active === 0">Active</template>
              <template v-else>Inactive</template>
            </td>
  
            <td class="text-xs-center checkEmpty p0">
              <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
              <v-icon color="red" small class="mr-4" v-if="page.candelete"
                @click="deleteItem(props.item.id)">delete</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete Team</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you Sure?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent :value="page.isAddTeam" max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Create Team</span>
              </v-card-title>
            </v-toolbar>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="addTeam.payload.name" required label="Name" type="text"
                    :rules="[(v) => !!v || 'Name is required']"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="addTeam.payload.description" required label="Description"
                    type="text"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete v-model="addTeam.payload.user" :items="team_user" label="User Assign" multiple>
                    <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text caption"
        >(+{{ addTeam.payload.user.length - 1 }} others)</span>
      </template>   
                    <template v-slot:prepend-item>
                      <v-list-tile ripple @click="Createtoggle">
                        <v-list-tile-action>
                          <v-icon :color="addTeam.payload.user.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>Select All</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeAddTeam()">Close</v-btn>
              <v-btn color="primary" flat @click="saveAddTeam()">Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog persistent :value="page.isEditTeam" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Team</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="editTeam.name" required label="Name" type="text"
                  :rules="[(v) => !!v || 'Name is required']"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="editTeam.description" required label="Description" type="text"
                  :rules="[(v) => !!v || 'Description is required']"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete v-model="editTeam.user" :items="team_user" label="User Assign" multiple>
                  <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text caption"
        >(+{{ editTeam.user.length - 1 }} others)</span>
      </template>   
                  <template v-slot:prepend-item>
                        
                        <v-list-tile ripple @click="Updatetoggle">
                          <v-list-tile-action>
                            <v-icon :color="editTeam.user.length > 0 ? 'primary' : ''">{{ Updateicon }}</v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title>Select All</v-list-tile-title>
                            
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                      
                    </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                <v-select v-model="editTeam.active" required label="Status" :items="status"  item-text="name"
    item-value="value"
                  ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import loader from "@/components/Widgets/loader";
  //import api from "@/utils/axios-util";
  export default {
    components: {
      loader,
    },
  
    data() {
      return {
        styleObject: {
          height: "80px",
          "margin-top": "-8px",
        },
        valid: true,
        page: {
          title: "Team Management",
          icon: "fa-clipboard",
          loading: false,
          options: { filter: false, refresh: true },
          isAddTeam: false,
          playingRecord: false,
          breadcrumbs: [],
          exportOptions: [],
          addnew: [],
          isEditTeam: false,
          candelete: false,
          canedit: false,
        },
        pagecount: 0,
        headers: [
          {
            text: "Name",
            align: "left",
            class: "p0",
            sortable: false,
            value: "name",
          },
          {
            text: "Description",
            align: "left",
            class: "p0",
            sortable: false,
            value: "description",
            
          },
          {
            text: "Assign Count",
            align: "left",
            class: "p0",
            sortable: false,
            value: "usercount",
          },
          {
            text: "Status",
            align: "left",
            class: "p0",
            sortable: false,
            value: "status",
          },
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "btn",
            width: "20%",
          },
        ],
        TeamData: [],
        pagination: {
          page: 1,
          rowsPerPage: 10,
        },
        snackbar: {
          show: false,
          timeout: 6000,
          text: "",
        },
        DeleteRowdata: false,
        showRowData: false,
        DataRow: false,
        tableLoading: false,
        filtervalue: [],
        filter: {
          payload: {
            Team: "",
            active:""
          },
          options: {
            status: ["Active", "Inactive"],
          },
        },
        isProcessing: false,
        call_status: [],
        editTeam: {
          id: "",
          name: "",
          description: "",
          active: 0,
          user: []
        },
        addTeam: {
          payload: {
            name: "",
            description: "",
            active: 0,
            user: []
          },
        },
        status: [{ name: "Active", value: 0 }, { name: "Inactive", value: 1 }],
        team_user: [],
        user:[],
        payload:{
          skip:0,
          take:0
        }
      };
    },
    methods: {
      Createtoggle() {
        this.$nextTick(() => {
          if (this.CreateSelectAll) {
            this.addTeam.payload.user = []
            this.userdata(this.addTeam.payload.user)
          } else {
            this.addTeam.payload.user = this.team_user.slice()
            this.userdata(this.addTeam.payload.user)
          }
        })
      },
      Updatetoggle() {
        this.$nextTick(() => {
          if (this.UpdateSelectAll) {
            this.editTeam.user = []
            this.userdata(this.editTeam.user)
          } else {
            this.editTeam.user = this.team_user.slice()
            this.userdata(this.editTeam.user)
          }
        })
      },
      userdata(data){
          this.$store.commit("filter_EmailId", { user_email: data.toString() });      
      },
      refreshThisPage: function () {
        var scope = this;
        scope.page.loading = true;
        scope.getDataFromApi();
      },
      getDataFromApi() {
        var scope = this;
        scope.tableLoading = true;
        this.page.isFilterOpen = false;
        var { page, rowsPerPage } = scope.pagination;
        if (rowsPerPage == -1) {
          scope.payload.take = scope.pagecount;
        } else {
          scope.payload.take = rowsPerPage;
        }
        if (page != "" && page != undefined && page != null) {
          scope.skip = page;
        } else {
          scope.skip = 1;
        }
        scope.payload.skip = (scope.skip - 1) * scope.payload.take;
        return new Promise(() => {
          this.$store
            .dispatch("GetTeam", scope.payload)
            .then((response) => {
              scope.tableLoading = false;
              scope.page.loading = false;
              if (response.status == 204) {
                scope.TeamData = [];
              } else {
                var datas = response.data.data;
                scope.TeamData = datas;
                scope.pagecount = response.data.count
                scope.user = []
                response.data.data.forEach((elm) => {
                  scope.user = [...scope.user, ...elm.user]
                })
                this.editTeam.id = "";
                this.editTeam.name = "";
                this.editTeam.description = "";
                this.editTeam.user = [];
              }

            })
            .catch(function (error) {
              console.log(error)
              scope.page.isAddTeam = false;
              scope.showSnackBar("error" + error);
              this.page.isEditTeam = false;
            });
        });
      },
      AddNewRule: function () {
        var scope = this;
        this.page.isAddTeam = true;
        scope.user.forEach((elm) => {
          scope.team_user.forEach((e) => {
            if(elm == e){
              if(scope.team_user.includes(e)){
               let indexdata= scope.team_user.indexOf(e)
               scope.team_user.splice(indexdata,1)
              }
              
            }
          })
        })
        scope.team_user=[...scope.team_user]
      },
      closeEditRules: function () {
        this.page.isEditTeam = false;
      },
      closeAddTeam: function () {
        this.page.isAddTeam = false;
        this.$refs.form.reset()
      },
      saveEditRules() {
        var scope = this;
  
        this.userdata(scope.editTeam.user)
        let Groupdata = {
          take: scope.editTeam.user.length,
          skip: 0
        }
        scope.$store
          .dispatch("userManagement", Groupdata)
          .then((res) => {
            if (res.status == 200) {
              scope.editTeam.user = scope.editTeam.user.map(element => {
                let userdata = res.data.data.find((elm)=> elm.user_email == element)
                if(userdata === undefined){
                  return {
                    email:undefined,
                    name: undefined
                  }
                }
                  return {
                    email:element,
                    name: userdata.user_name
                  }
                }).filter(elm => elm.email != undefined && elm.name != undefined );
              scope.$store
                .dispatch("updateTeam", scope.editTeam)
                .then(() => {
                  scope.page.isAddTeam = false;
                  scope.showSnackBar("Updated Successfully");
                  this.getDataFromApi();
                  this.page.isEditTeam = false;
                })
                .catch(function (error) {
                  scope.page.isAddTeam = false;
                  scope.showSnackBar("error" + error);
                  this.page.isEditTeam = false;
                });
              this.getDataFromApi();
            }
          })
          .catch(function (error) {
            console.log(error,"error");
            this.userdata("")
            scope.page.isAddTeam = false;
            if (error.statusCode == 409) {
              scope.showSnackBar(error.message);
            } else {
              scope.showSnackBar("error" + error);
            }
          });
      },
      saveAddTeam() {
        var scope = this;
        let isvalid = scope.$refs.form.validate()
        if (isvalid) {
          this.userdata(scope.addTeam.payload.user)
          let Groupdata = {
            take: scope.addTeam.payload.user.length,
            skip: 0
          }
          scope.$store
            .dispatch("userManagement", Groupdata)
            .then((res) => {
              if (res.status == 200) {
                scope.addTeam.payload.user = scope.addTeam.payload.user.map(element => {
                  return {
                    email:element,
                    name:res.data.data.find((elm)=> elm.user_email == element).user_name
                  }
                });
                
                scope.$store
                  .dispatch("insertTeam", scope.addTeam.payload)
                  .then(() => {
                    scope.page.isAddTeam = false;
                    scope.showSnackBar("Created Successfully");
                    scope.addTeam.payload.name = "";
                    scope.addTeam.payload.description = "";
                    scope.addTeam.payload.user = [];
                    scope.$refs.form.reset()
                    this.userdata("")
                  })
                  .catch(function (error) {
                    this.userdata("")
                    scope.page.isAddTeam = false;
                    if (error.statusCode == 409) {
                      scope.showSnackBar(error.message);
                    } else {
                      scope.showSnackBar("error" + error);
                    }
  
                  });
                this.getDataFromApi();
              }
            })
            .catch(function (error) {
              this.userdata("")
              scope.page.isAddTeam = false;
              if (error.statusCode == 409) {
                scope.showSnackBar(error.message);
              } else {
                scope.showSnackBar("error" + error);
              }
            });
        }
  
      },
      showSnackBar(message) {
        let scope = this;
        scope.snackbar.text = message;
        scope.snackbar.show = true;
        setTimeout(() => {
          scope.snackbar.text = "";
        }, scope.snackbar.timeout);
      },
      deleteItem(id) {
        this.DeleteRowdata = true;
        this.delete_id = id;
      },
      editItem(item) {
        let scope = this
        scope.user.forEach((elm) => {
          scope.team_user.forEach((e) => {
            if(elm == e){
              if(scope.team_user.includes(e)){
               let indexdata= scope.team_user.indexOf(e)
               scope.team_user.splice(indexdata,1)
              }
              
            }
          })
        })
        scope.team_user=[...scope.team_user,...item.user]
        this.page.isEditTeam = true;
        this.editTeam.id = item.id;
        this.editTeam.name = item.name;
        this.editTeam.description = item.description;
        this.editTeam.user = item.user;
      },
      savedelete() {
        let scope = this;
        scope.isProcessing = true;
        this.$store
          .dispatch("deleteTeam", this.delete_id)
          .then((response) => {
            scope.isProcessing = false;
            if (response.data.message == "success") {
              scope.getDataFromApi();
              scope.showSnackBar("Team Deleted");
            } else {
              scope.showSnackBar("Error! Unable Delete list");
            }
          })
          .catch(() => {
            scope.isProcessing = false;
            scope.showSnackBar("Error! Unable Delete list");
          });
        this.closedelete();
      },
      closedelete() {
        this.DeleteRowdata = false;
      },
      closeShow() {
        this.showRowData = false;
      },
    },
    computed: {
      CreateSelectAll() {
        return this.addTeam.payload.user.length === this.team_user.length
      },
      Createlike() {
        return this.addTeam.payload.user.length > 0 && !this.CreateSelectAll
      },
      icon() {
        if (this.CreateSelectAll) return 'mdi-close-box'
        if (this.Createlike) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      UpdateSelectAll() {
        return this.editTeam.user.length === this.team_user.length
      },
      Updatelike() {
        return this.editTeam.user.length > 0 && !this.UpdateSelectAll
      },
      Updateicon() {
        if (this.UpdateSelectAll) return 'mdi-close-box'
        if (this.Updatelike) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      }
    },
    mounted() {
      let scope = this;
      this.page.loading = false;
      this.team_user = JSON.parse(localStorage.getItem("group_users"));
      setTimeout(
        function () {
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "management:team:create"
            ) !== -1
          ) {
            scope.page.addnew = [{ label: "Add Team" }];
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "management:team:edit"
            ) !== -1
          ) {
            scope.page.canedit = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "management:team:delete"
            ) !== -1
          ) {
            scope.page.candelete = true;
          }
        }.bind(scope),
        1
      );
      this.userdata("")
      this.getDataFromApi();
    },
    watch: {
      pagination: {
      handler() {
        // if (this.pagination.page > 1 || this.skip >= 10) {
        this.getDataFromApi().then((data) => {
          this.pagecount = data.count;
        });
      // }
      },
      deep: true,
    },
    }
  };
  </script>