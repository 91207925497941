<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
    <div class="call-chart-container">
        <div class="Chart">
            <div v-if="loading" class="loader"></div>
            <h4 style="text-align: center">{{ name }}</h4>
            <doughnut-chart :data="dataPoints" :style="myStyles" />
        </div>
    </div>
</template>
<script>
import DoughnutChart from "./js/DoughnutChart";
export default {
    components: {
        DoughnutChart,
    },
    props: {
        timer: {
            type: Number,
            default: 10000,
        },
        id: {
            type: Number,
            default: 1,
        },
        name: {
            type: String,
            default: "",
            validator: function(val) {
                return typeof val === "string";
            },
        },
    },
    data() {
        return {
            loading: false,
            dataPoints: {},
            height: 20,
            polling: null,
        };
    },
    created() {
        const scope = this;
        const payload = {
            id: scope.id,
            skip: 0,
            take: 20,
        };
        scope.loading = true;
        scope.$store
            .dispatch("rect/renderWidgetData", payload)
            .then((result) => {
                scope.dataPoints = JSON.parse(JSON.stringify(result));
                scope.loading = false;
            })
            .catch((err) => {
                console.log("Error(Doughnut) ==>", err);
                scope.loading = false;
            });
        scope.fillData();
    },
    updated() {
        const scope = this;
        if (scope.activePreview.update) {
            scope.dataPoints = [];
            const payload = {
                id: scope.id,
                skip: 0,
                take: 20,
            };
            scope.loading = true;
            scope.$store
                .dispatch("rect/renderWidgetData", payload)
                .then((result) => {
                    scope.dataPoints = JSON.parse(JSON.stringify(result));
                    scope.loading = false;
                })
                .catch((err) => {
                    console.log("Error(Doughnut) ==>", err);
                    scope.loading = false;
                });
            scope.$store.dispatch("rect/updatePreview", scope.activePreview);
        }
    },
    methods: {
        increaseHeight() {
            this.height += 10;
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
        fillData() {
            const scope = this;
            scope.polling = setInterval(() => {
                if (scope.activePreview.id === scope.id) {
                    const payload = {
                        id: scope.id,
                        skip: 0,
                        take: 20,
                    };
                    scope.$store
                        .dispatch("rect/renderWidgetData", payload)
                        .then((result) => {
                            scope.dataPoints = JSON.parse(JSON.stringify(result));
                        })
                        .catch((err) => {
                            console.log("Error(Doughnut) ==>", err);
                        });
                } else {
                    clearInterval(scope.polling);
                }
            }, scope.timer);
        },
    },
    beforeDestroy() {
        clearInterval(this.polling);
    },
    computed: {
        myStyles() {
            return `height: 90%;width: 100%;position: relative;`;
        },
        activePreview() {
            return this.$store.getters['rect/activePreview'];
        }
    },
};
</script>
<style>
.call-chart-container {
    width: 100%;
    height: 100%;
    display: flex !important;
    position: relative;
}

h1 {
    font-family: "Helvetica", Arial;
    color: #464646;
    text-transform: uppercase;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 5px;
    font-size: 18px;
    margin-top: 0;
}

.call-chart-container .Chart {
    position: absolute;
    padding: 5px;
    width: 100%;
    height: 100%;
    margin: auto;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.4);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 6;
}
</style>