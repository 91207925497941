<template>
    <div class="main__wrapper">
        <div>
            <el-container>
                <el-main :style="cssProps">
                    <el-form>
                        <template v-for="(eachFormObj, eachFormIndex) in forms">
                            <el-row class="form-preview" :key="`row-${eachFormIndex}`">
                                <div class="wrapper--forms">
                                    <el-col v-for="(field, index) in eachFormObj.fields" :key="index" :span="field.span" v-bind="field" class="form__group">
                                        <component :is="field.fieldType" :currentField="field" class="form__field">
                                        </component>
                                    </el-col>
                                </div>
                            </el-row>
                        </template>
                    </el-form>
                </el-main>
            </el-container>
        </div>
    </div>
</template>
<script>
import {
    FormBuilder
} from '@/components/form_elements/formbuilder';
import { mapGetters } from "vuex";
var _ = require('lodash');

export default {
    name: 'Publish',
    components: FormBuilder.$options.components,
    computed: {
        ...mapGetters(["forms", "themingVars"]),
        cssProps() {
            // Return an object that will generate css properties key
            // to match with the themingVars
            //
            // Example output: { '--theme-primary-color': this.themingVars.primaryColor }
            var result = {
                    height: '100%',
                    margin: "10px"
                },
                themingVars = this.themingVars;

            for (var v in themingVars) {
                if (themingVars.hasOwnProperty(v)) {
                    var newV = "--theme-" + _.kebabCase(v),
                        suffix = "";

                    // Add px to the value if the default value contain 'px'
                    if (_.includes(newV, 'size')) suffix = "px"
                    else if (_.includes(newV, 'margin')) suffix = "px"
                    else if (_.includes(newV, 'radius')) suffix = "px"

                    result[newV] = themingVars[v] + suffix;
                }
            }

            return result;
        }
    }
}
</script>
<style lang="stylus" scoped>
.form-preview {
    border: 1px solid #ccc !important;
}

.el-form-item {
    padding: 20px 10px;
}
</style>