<template>
    <div id="app">
        <el-tabs v-model="activeName" class="tabs">
            <el-tab-pane label="Design" name="Design">
                <Home />
            </el-tab-pane>
            <el-tab-pane label="Preview" name="Preview">
                <Preview />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import Home from './Home';
import Preview from './Preview';

export default {
    name: 'App',
    data() {
        return {
            activeName: "Design"
        }
    },
    components: {
        Home,
        Preview
    }
}
</script>
<style scoped>
#app {
    padding: 10px 5px;
}

.tabs {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 5 !important;
    padding: 15px !important;
}
</style>>