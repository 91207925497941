<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
  >
    <el-time-select
      v-model="time"
      v-bind="attributesBinding"
      :picker-options="{
        start: '00:00',
        step: '00:15',
        end: '23:45',
      }"
      placeholder="Select time"
    >
    </el-time-select>
  </el-form-item>
</template>

<script>
export default {
  name: "TimePicker",
  props: ["currentField"],
  data() {
    return {
      time: "",
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
};
</script>
