import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import navigation from "./modules/navigation";
import swatch from "./modules/swatch";
import report from "./modules/report";
// import axios from 'axios';
import liveAgents from "./modules/liveAgents";
import campaign from "./modules/campaign_status";
import queuestatus from "./modules/queuestatus";
import auth0 from "./modules/auth0";
import overallreport from "./modules/overallreport";
import userManagement from "./modules/userManagement";
import queueManagement from "./modules/queueManagement";
import AudioStore from "./modules/AudioStore";
import groupManagement from "./modules/groupManagement";
import WebHookManagement from "./modules/WebHookManagement"
import DoNotCall from "./modules/DoNotCall"
import Blocklist from "./modules/Blocklist"
import TagManagement from "./modules/TagManagement"
import PauseCode from "./modules/pauseCode"
import Reminder from "./modules/reminder"
import Disposition from "./modules/Disposition"
import AsterCampaigns from "./modules/Astercampaigns"
import AsterList from "./modules/AsterList"
import AsterSms from "./modules/AsterSms"
import AsterMail from "./modules/AsterMail"
import AsterWhatsapp from "./modules/AsterWhatsapp"
import AsterLead from "./modules/AsterLead"
import AsterLeadInsert from "./modules/AsterLeadInsert"
import AsterLeadDelete from "./modules/AsterLeadDelete"
import Contact from "./modules/Contacts"
import Rules from "./modules/Rules"
import CustomForm from "./modules/CustomForm"
import rect from './modules/rect'
import CallFlow from "./modules/CallFlow";
import TeamManagement from "./modules/TeamManagement";
import Timezone from "./modules/timezone"

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {},
    getters,
    mutations,
    actions,
    modules: {
        navigation,
        swatch,
        liveAgents,
        campaign,
        queuestatus,
        report,
        auth0,
        overallreport,
        userManagement,
        queueManagement,
        AudioStore,
        groupManagement,
        WebHookManagement,
        DoNotCall,
        Blocklist,
        TagManagement,
        PauseCode,
        Reminder,
        Disposition,
        Contact,
        Rules,
        AsterCampaigns,
        AsterList,
        AsterSms,
        AsterMail,
        AsterWhatsapp,
        AsterLead,
        AsterLeadInsert,
        AsterLeadDelete,
        CustomForm,
        CallFlow,
        TeamManagement,
        'rect': rect,
        Timezone
    }
});