<template>
  <div>
    <section-definition
      :title="pagecontrol.title"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @openFilter="filterClick"
      @refreshPage="refreshClick"
      @addNew="NewUser"
      :style="styleObject"
    ></section-definition>
    <loader v-if="pagecontrol.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-dialog :value="pagecontrol.isAddUser" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">New user</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-form ref="adduserform" v-model="valid">
              <div id="scrollset">
                <div id="scrollbar">
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newlyUser.user_id"
                        :rules="idrules"
                        required
                        label="User Id"
                      ></v-text-field>
                      <div>
                        <h4 v-if="error_user_id === 500" class="alert-danger">
                          This UserId already exists
                        </h4>
                        <h4
                          v-else-if="error_user_id === 400"
                          class="alert-danger"
                        >
                          This UserId already exists
                        </h4>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newlyUser.name"
                        :rules="namerules"
                        required
                        label="Name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newlyUser.user_phone_number"
                        :rules="mobileRules"
                        required
                        label="Mobile Number"
                        onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                        type="text"
                      ></v-text-field>
                      <div>
                        <h4 v-if="error_user_id === 500" class="alert-danger">
                          This mobile Mobile Number maybe already exists
                        </h4>
                        <h4
                          v-else-if="error_user_id === 400"
                          class="alert-danger"
                        >
                          This Mobile Number maybe exists
                        </h4>
                      </div>
                      <v-flex xs12>
                        <v-text-field
                          v-model="newlyUser.user_email"
                          :rules="emailRules"
                          required
                          label="Email Id"
                        ></v-text-field>
                        <div>
                          <h4 v-if="error_user_id === 500" class="alert-danger">
                            This mail id maybe already exists
                          </h4>
                          <h4
                            v-else-if="error_user_id === 400"
                            class="alert-danger"
                          >
                            This mail id maybe exists
                          </h4>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="newlyUser.password"
                          :rules="passwordrules"
                          required
                          label="Password"
                          placeholder="sample@1234"
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="newlyUser.roles"
                        :items="UserType"
                        :rules="[(v) => !!v || 'Item is required']"
                        label="Role"
                        item-text="name"
                        item-value="id"
                        return-object
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="newlyUser.groups"
                        :items="groupTypes"
                        :rules="[(v) => !!v || 'Item is required']"
                        label="Group"
                        item-text="group_name"
                        item-value="group_id"
                        return-object
                        :multiple="newlyUser.roles"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="CloseNewUser()">Cancel</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="SaveNewUser()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-form ref="edituserform" v-model="valid">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_id"
                    :rules="idrules"
                    required
                    label="User Id"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This UserId maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This UserId maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="namerules"
                    required
                    label="Name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_phone_number"
                    :rules="mobileRules"
                    required
                    onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    label="Mobile Number"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This Number maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This Number maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_email"
                    :rules="emailRules"
                    required
                    label="Email Id"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This EmailId maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This EmailId maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.roles"
                    :items="UserType"
                    :rules="selectRules"
                    label="Role"
                    item-text="name"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.groups"
                    :items="groupTypes"
                    :rules="selectRules"
                    label="Groups"
                    item-text="group_name"
                    item-value="group_id"
                    :multiple="editedItem.roles"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.status"
                    :rules="selectRules"
                    :items="userstatus"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="CloseEditUser()">Cancel</v-btn>
            <v-btn color="primary" @click="SaveEditUser()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="password" max-width="500px">
        <v-form ref="editpassform" v-model="valid">
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Change Password</span>
              </v-card-title>
            </v-toolbar>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="change.Newpassword"
                    :rules="newRules"
                    required
                    label="New Password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="change.ConfirmPassword"
                    :rules="confirmRules"
                    required
                    label="Confirm Password"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="close()">Cancel</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="save()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="DeleteRow" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Detele user info</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text
                >Are you sure to delete this user information.</v-card-text
              >
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="QueueAssignModel" max-width="650px">
        <v-form ref="refassign" v-model="valid">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Assign Queue</v-toolbar-title>
            </v-toolbar>
            <v-container
              id="scroll-target"
              style="max-height: 350px"
              class="scroll-y"
            >
              <v-layout
                v-scroll:#scroll-target="onScroll"
                row
                v-for="(item, index) in QueueModelItems.queue"
                :key="index"
                style="height: 90 px"
                {{QueueModelItems.queue[index].name}}
              >
                <template>
                  <v-flex xs6 class="queue_user">
                    <v-select
                      @change="assignedval(QueueModelItems.queue[index].name)"
                      v-model="QueueModelItems.queue[index].name"
                      :items="queueclone"
                      item-disabled="active"
                      label="Queue"
                      item-text="name"
                      item-value="name"
                      persistent-hint
                      :rules="[(v) => !!v || 'required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs5 style="padding-left: 10px !important">
                    <v-select
                      v-model="QueueModelItems.queue[index].user_rank"
                      :items="rank"
                      :rules="[(v) => !!v || 'required']"
                      label="User_rank"
                      persistent-hint
                    ></v-select>
                  </v-flex>

                  <v-flex xs1>
                    <v-icon
                      v-if="QueueModelItems.queue.length > 0"
                      color="red"
                      class="mt-4 ml-0 remove-icon"
                      @click="removeItem(index, QueueModelItems.queue[index])"
                      >close</v-icon
                    >
                  </v-flex>
                </template>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-icon
                class="icon"
                :disabled="QueueModelItems.queue.length >= QueueList.length"
                @click="QueueAssignExtra()"
                color="primary"
                >mdi-plus
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn
                v-if="QueueModelItems.queue.length == 0"
                color="light"
                flat
                @click="QueueAssignClose()"
                >Close</v-btn
              >
              <v-btn v-else color="light" flat @click="QueueAssignClose()"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                @click="QueueAssignSave()"
                :disabled="QueueModelItems.queue.length == 0"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-navigation-drawer
        fixed
        right
        :value="pagecontrol.isFilterOpen"
        class="filterNavigation"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            color="white"
            @click="pagecontrol.isFilterOpen = false"
          >
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-container class="sidenavContainer">
          <UserManagementFilter
            v-if="pagecontrol.isFilterOpen"
            @getDataFrom="getDataFrom"
            :appliedfilter="appliedfilter"
          ></UserManagementFilter>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="getDataFrom()">Filter</v-btn>
        </v-container>
      </v-navigation-drawer>
      <v-data-table
        :headers="headers"
        :items="usersdata"
        class="fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.user_id }}</td>
          <td class="checkEmpty p0">{{ props.item.user_phone_number }}</td>
          <td class="checkEmpty p0">{{ props.item.user_email }}</td>
          <td class="checkEmpty p0">{{ userstring(props.item.status) }}</td>
          <td class="checkEmpty p0" style="text-overflow: elipsis">
            {{ rolesString(props.item.roles) }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              color="green"
              small
              class="mr-4"
              v-if="pagecontrol.canedit"
              @click="editItem(props.item)"
              >editz</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="pagecontrol.candelete"
              @click="deleteItem(props.item)"
              >delete</v-icon
            >
            <v-icon
              color="blue"
              small
              class="mr-4"
              :disabled="rolesString(props.item.roles) === 'Administrator'"
              v-if="pagecontrol.canassignqueue"
              @click="getUserQueue(props.item)"
              >queue</v-icon
            >
            <v-icon
              color="violet"
              small
              class="mr-4"
              v-if="pagecontrol.canassignqueue"
              @click="pass(props.item)"
              >fa-key</v-icon
            >
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";
import UserManagementFilter from "./Components/UserManagementFilter";
// import Multiselect from 'vue-multiselect';
// import vSelect from 'vue-select'
export default {
  components: {
    loader,
    UserManagementFilter,
  },
  data() {
    return {
      headers: [
        {
          text: "User Id",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_id",
        },
        {
          text: "Mobile Number",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_phone_number",
          width: "10%",
        },
        {
          text: "Email Id",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_email",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "status",
          width: "10%",
        },
        {
          text: "Role",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "roles",
          width: "10%",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "btn",
          width: "15%",
        },
      ],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      listdata: [],
      queueclone: [],


      userstatus: [
        { name: "Active", value: "active" },
        { name: "Inactive", value: "inactive" },
      ],
      payload: {
        skip: 0,
        take: 0,
      },
      isProcessing: false,
      page: 1,
      pagecount: 0,
      delete_id: "",
      totalCount: "",
      error_user_id: "",
      error_edit: "",
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      password: false,
      dialog: false,
      DeleteRow: false,
      QueueAssignModel: false,
      Role: null,
      valid: true,
      editedItem: {
        UserId: "",
        name: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        groups: [],
        Status: "",
      },
      tableLoading: false,
      defaultItem: {
        UserId: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        groups: "",
        Status: "",
      },
      groupTypes: [],
      newlyUser: {
        UserId: "",
        name: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        groups: "",
        Status: "",
      },
      string: "",
      QueueModelName: "",
      QueueModelItems: {
        name: "",
        interface: "",
        queue: [],
      },
      Assignbtn: false,
      listQueue: [],
      rank: [1, 2, 3, 4, 5],
      AddUser: false,
      change: {
        id: "",
        user_email: "",
        Newpassword: "",
        ConfirmPassword: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      mobileRules: [
        (v) => !!v || "Mobile Number is required"
      ],
      passwordrules: [
        (v) => !!v || "password is required",
        (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*]?)[a-zA-Z0-9!@#$%^&*]{7,}$/.test(v) ||
          "password is week",
      ],
      idrules: [(v) => !!v || "User Id is required"],
      namerules: [(v) => !!v || "Name is required"],

      selectRules: [
        (v) => !!v || "Item is required",
        (v) => !!v || "Must be select at lest one field",
      ],
      newRules: [
        (v) => !!v || "password is required",
        (v) => /.+@+./.test(v) || "password is week",
      ],
      confirmRules: [
        (v) => !!v || "password is required",
        (v) => v == this.change.Newpassword || "password doesn't match",
      ],
      appliedfilter: {
        UserId: "",
        MobileNumber: "",
        EmailId: "",
        Role: "",
      },
      page_flag: false,
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      tempInterface: "",
      pagecontrol: {
        title: "User Management",
        icon: "fa-user",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        canedit: false,
        candelete: false,
        canassignqueue: false,
      },
    };
  },
  updated() {
    this.QueueModelItems.queue.forEach((element) => {
      this.listdata.push(element);
    });
  },
  mounted() {
    let scope = this;
    this.pagecontrol.loading = false;
    this.$store.dispatch("UserType");
    // this.getDataFromApi();
    this.getGroupList();
    this.$store.dispatch("QueueList");
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:create"
          ) !== -1
        ) {
          scope.pagecontrol.addnew = [{ label: "Add User" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:edit"
          ) !== -1
        ) {
          scope.pagecontrol.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:delete"
          ) !== -1
        ) {
          scope.pagecontrol.candelete = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:assignqueue"
          ) !== -1
        ) {
          scope.pagecontrol.canassignqueue = true;
        }
      }.bind(scope)
    );
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    queuecopy() {
      for (let i = 0; i < this.queueclone.length; i++) {
        if (this.queueclone[i].active === false) {
          return this.queueclone;
        }
      }
    },

    assignedval(val) {
      for (let i = 0; i < this.queueclone.length; i++) {
        if (val === "cancel") {
          this.queueclone[i].active = false;
        } else {
          if (this.queueclone[i].name === val) {
            this.queueclone[i].active = true;
          }
        }
      }
    },
    queuearrange(val) {
      this.listdata.push(val);
    },
    refreshClick: function () {
      this.pagecontrol.loading = true;
      this.resetvalue();
      this.getDataFromApi();
    },
    selectedRoles(obj) {
      return obj.map((o) => {
        return o.id;
      });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    rolesString(obj) {
      let arr = obj.map((o) => {
        return o.name;
      });
      return arr.join(",");
    },
    getGroupList: function () {
      let scope = this;
      this.$store
        .dispatch("groupLists")
        .then((response) => {
          scope.groupTypes = response;
        })
        .catch(() => {
          scope.groupTypes = [];
        });
    },
    // modifyNewUserGroups: function () {
    //   if (this.newlyUser.roles === "Administrator") {
    //     this.newlyUser.groups = {};
    //   } else {
    //     this.newlyUser.groups = [];
    //   }
    // },
    // modifyUpdateUserGroups: function () {
    //   if (this.editedItem.roles === "Administrator") {
    //     this.editedItem.groups = {};
    //   } else {
    //     this.editedItem.groups = [];
    //   }
    // },
    userstring(val) {
      var k = this.userstatus.filter((statusvalue) => {
        return statusvalue.value == val;
      });
      if (k.length > 0) {
        return k[0].name;
      } else {
        return "";
      }
    },
    NewUser: function () {
      this.pagecontrol.isAddUser = true;
    },
    filterClick: function () {
      this.pagecontrol.isFilterOpen = true;
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.usersdata.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.roles = this.editedItem.roles[0];
      this.editedItem.name = this.editedItem.user_name;
      this.editedItem.groups = this.groupTypes.filter((group) => {
        return this.editedItem.groups.indexOf(group.group_id) !== -1;
      });
    },
    pass(item) {
      const scope = this;
      scope.change.id = item.id;
      scope.change.user_email = item.user_email;
      this.password = true;
      this.$refs.editpassform.reset();
    },
    close() {
      this.password = false;
    },
    save() {
      const scope = this;
      if (scope.change.Newpassword === scope.change.ConfirmPassword) {
        scope.isProcessing = true;
        scope.$store
          .dispatch("EditPassword", scope.change)
          .then((response) => {
            scope.isProcessing = false;
            if (response.statusCode == 200) {
              scope.change.id = "";
              scope.change.user_email = "";
              scope.change.Newpassword = "";
              scope.change.ConfirmPassword = "";
              scope.showSnackBar("Password Updated Successfully");
              this.getDataFromApi();
              this.close();
            } else {
              scope.isProcessing = false;
              scope.showSnackBar("Update Error- " + response.message);
            }
          })
          .catch((err) => {
            scope.isProcessing = false;
            scope.showSnackBar("Error: " + err.message);
          });
      } else {
        scope.showSnackBar(
          "Error: " + "password and confirm password didn't match"
        );
      }
    },
    CloseEditUser() {
      this.error_edit = "";
      this.$refs.edituserform.reset();
      this.dialog = false;
    },
    SaveEditUser() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("editedItem", this.editedItem)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            scope.showSnackBar("Data Updated Successfully");
            this.getDataFromApi();
            this.CloseEditUser();
          } else {
            scope.showSnackBar("Update Error- " + response.data.message);
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Update Error");
        });
    },
    CloseNewUser() {
      this.pagecontrol.isAddUser = false;
      this.newlyUser = Object.assign({}, this.defaultItem);
      this.error_user_id = "";
      this.$refs.adduserform.reset();
    },
    SaveNewUser() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("newlyUser", this.newlyUser)
        .then((response) => {
          scope.isProcessing = false;
          if (
            response.data.statusCode == 207 &&
            response.data.data[0].statusCode == 200
          ) {
            scope.showSnackBar("User Created Successfully");
            this.CloseNewUser();
            this.getDataFromApi();
          } else {
            scope.showSnackBar(
              "Error Creating User- " + response.data.data[0].message
            );
          }
        })
        .catch(function (error) {
          scope.isProcessing = false;
          scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Creating User");
        });
    },
    deleteItem(item) {
      this.DeleteRow = true;
      this.delete_id = item;
    },
    QueueAssignExtra() {
      this.QueueModelItems.queue.push({ name: "", user_rank: 0 });
    },
    removeItem: function (index, value) {
      let scope = this;
      for (let i = 0; i < this.queueclone.length; i++) {
        if (this.queueclone[i].name === value.name) {
          this.queueclone[i].active = false;
        }
      }
      if (value.id !== undefined) {
        scope.$store
          .dispatch("DeleteQueueUser", value.id)
          .then(() => {
            scope.showSnackBar("Success! User Removed Queues");
          })
          .catch(() => {
            scope.isProcessing = false;
            scope.QueueAssignModel = true;
            scope.showSnackBar("Error! User Assign Failed");
          });
      }

      this.QueueModelItems.queue.splice(index, 1);
    },

    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteRow", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "Success") {
            this.getDataFromApi();
            scope.showSnackBar("User Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete User");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete User");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRow = false;
    },
    checkstatus() {
      this.listQueue.push(this.checkstatus);
    },
    getUserQueue(item) {
      var scope = this;
      for (let j = 0; j < scope.QueueList.length; j++) {
        scope.QueueList[j].active = false;
        scope.queueclone.push(scope.QueueList[j]);
      }

      scope.QueueAssignModel = true;
      scope.tempInterface = item.user_phone_number;
      scope.$store
        .dispatch("getUserQueue", item.user_email)
        .then((response) => {
          scope.QueueModelItems = Object.assign({}, response);
          scope.QueueModelName = response.name;
          response.queue.forEach((elm) => scope.assignedval(elm.name));
        });
    },
    QueueAssignClose() {
      let scope=this
      scope.QueueModelItems= {
        name: "",
        interface: "",
        queue: [],
      }
      this.QueueAssignModel = false;

    },
    QueueAssignSave() {
      let scope = this;
      if (scope.QueueModelItems.queue.length > 0) {
        let count = 0;
        scope.QueueModelItems.queue.forEach((elm) => {
          if (elm.name == "" && elm.user_rank == 0) {
            count++
          }else if(elm.name == ""){
             count++
          }else if(elm.user_rank == 0){
            count++
          }
        });

        if (count== 0) {
          scope.QueueAssignModel = false;
          scope.isProcessing = true;
          let QueueModelItems = scope.QueueModelItems;
          QueueModelItems.interface = scope.tempInterface;
          this.$store
            .dispatch("createQueueUser", QueueModelItems)
            .then(() => {
              scope.QueueModelItems = {
                name: "",
                interface: "",
                queue: [],
              };
              scope.isProcessing = false;
              scope.showSnackBar("Success! User Assigned Successfully");
            })
            .catch(() => {
              scope.isProcessing = false;
              scope.QueueAssignModel = true;
              scope.showSnackBar("Error! User Assign Failed");
            });
        } else {
          this.$refs.refassign.validate()
            scope.QueueAssignModel = true;
        }
      }
    },
    isFilterCancel() {
      this.pagecontrol.isFilterOpen = false;
    },
    resetvalue() {
      this.skip = 0;
      this.appliedfilter.UserId = "";
      this.$store.commit("filter_userId", { user_id: "" });
      this.appliedfilter.MobileNumber = "";
      this.$store.commit("filter_MobileNumber", { user_phone_number: "" });
      this.appliedfilter.EmailId = "";
      this.$store.commit("filter_EmailId", { user_email: "" });
      this.appliedfilter.Role = "";
      this.$store.commit("filter_Role", { role: "" });
      this.getDataFrom();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      var { page, rowsPerPage } = scope.pagination;
      if (rowsPerPage == -1) {
        scope.payload.take = scope.totalCount;
      } else {
        scope.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        scope.skip = page;
      } else {
        scope.skip = 1;
      }
      scope.payload.skip = (scope.skip - 1) * scope.payload.take;
      return new Promise(() => {
        scope.$store
          .dispatch("userManagement", scope.payload)
          .then((response) => {
            scope.pagecontrol.loading = false;
            scope.tableLoading = false;
            if (scope.page_flag) {
              // scope.pagination.page = 1;
              scope.page_flag = false;
            }
            scope.totalCount = response.data.totalCount;
            scope.pagecount = scope.totalCount;
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
            scope.tableLoading = false;
          });
      });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },
  },
  computed: {
    ...mapGetters([
      "usersdata",
      "UserType",
      "QueueList",
      "Createqueue",
      "AssignQueueList",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "Change password" : "Edit User";
    },
  },
  watch: {
    dialog(val) {
      val || this.CloseEditUser();
    },
    pagination: {
      handler() {
        this.getDataFromApi().then((data) => {
          this.pagecount = data.totalCount;
        });
      },
      deep: true,
    },
  },

  refreshvalue() {
    this.$store.commit("filter_userId", { UserId: "" });
    this.$store.commit("filter_MobileNumber", { MobileNumber: "" });
    this.$store.commit("filter_EmailId", { EmailId: "" });
    this.$store.commit("filter_Role", { Role: "" });
  },
  beforeDestroy() {
    this.$store.commit("filter_userId", { user_id: "" });
    this.$store.commit("filter_MobileNumber", { user_phone_number: "" });
    this.$store.commit("filter_EmailId", { user_email: "" });
    this.$store.commit("filter_Role", { role: "" });
  },
};
</script>
<style>
.alert-danger {
  color: red;
}
</style>
