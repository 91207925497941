/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toTop': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M80 53.334H20V60h6.667v10c0 1.836 1.5 3.334 3.333 3.334h40A3.344 3.344 0 0 0 73.333 70V60H80v-6.666zM20 26.667v6.667h13.333v10a3.344 3.344 0 0 0 3.334 3.333h26.666a3.344 3.344 0 0 0 3.334-3.333v-10H80v-6.667H20zM60 40H40v-6.667h20V40z"/>'
  }
})
