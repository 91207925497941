<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      :style="styleObject"
    ></section-definition>
    <loader v-if="controls.rest.processing"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-layout row>
        <v-flex xs5>
          <v-card>
            <v-toolbar dark color="sidebar">
              <v-card-title>
                <span class="headline">User Groups</span>
              </v-card-title>
            </v-toolbar>
            <v-container grid-list-md class="scroll">
              <v-list two-line dense>
                <v-list-tile
                  v-for="item in groups.list"
                  :key="item.group_id"
                  :class="{
                    grouplist: true,
                    primary: item.group_id === groups.active.group_id,
                    selected: item.group_id === groups.active.group_id,
                  }"
                  :dark="item.group_id === groups.active.group_id"
                  @click="changeActive(item)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title
                      :class="{
                        grouptitle: true,
                        bold: item.group_id === groups.active.group_id,
                      }"
                      >{{ item.group_name }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      :class="{
                        groupcount: true,
                        highlight: item.group_id === groups.active.group_id,
                      }"
                      >Members Count:
                      {{ item.group_count }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      icon
                      ripple
                      @click="deleteGroupListInit(item)"
                      v-if="page.candelete"
                    >
                      <v-icon
                        :color="
                          item.group_id === groups.active.group_id
                            ? 'red'
                            : 'red'
                        "
                        >delete</v-icon
                      >
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-container>
            <v-divider></v-divider>
            <v-list class="extra-pad">
              <v-list-tile v-if="page.cancreate">
                <v-list-tile-avatar>
                  <v-icon color="green">add_circle</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-text-field
                    v-model="groups.new.name"
                    class="full-width"
                    label="New Group"
                    required
                  ></v-text-field>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn icon ripple @click="createGroup()">
                    <v-icon color="secondary">save</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile v-else></v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex xs7>
          <v-card>
            <v-tabs dark color="primary" show-arrows class="extraheight-tabs">
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab href="#permissionstab"> Permissions </v-tab>
              <v-tabs-items>
                <v-tab-item value="permissionstab">
                  <loader v-if="controls.groupSection.rest.processing"></loader>
                  <!-- <div v-else>
                    <v-layout wrap row ma-0>
                      <v-flex xs12>
                        <v-text-field v-model="groups.active.group_name" label="Group"></v-text-field>
                      </v-flex>
                    </v-layout>
                    <div class="event-modules" v-for="(permission, permissionsindex) in permissions.list" :key="permissionsindex">
                      <div class="module-toolbar">
                        <input type="checkbox" :checked="ischeckall(permissionsindex)" @click="checkall($event, permissionsindex)" id="event.name">  
                        <div class="title">{{permission.category.title}}</div>
                      </div>
                      <div class="event-checkboxes">
                        <div v-for="(event, index) in permission.values" :key="index">
                          <input type="checkbox" :checked="isChecked(event.permission_value)" :id="event.permission_name" @click="updatePermission(event.permission_value)">
                          <label :class="{active:isChecked(event.permission_value)}" :for="event.permission_name">{{event.permission_name}}</label>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div v-else>
                    <v-layout wrap row ma-0>
                      <v-flex xs12>
                        <v-text-field
                          v-model="groups.active.group_name"
                          label="Group"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-checkbox
                          v-model="checkbox"
                          @change="checkall()"
                          label="Select All"
                          color="primary"
                          hide-details
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                    <div
                      v-for="(
                        modules, moduleKey, moduleIndex
                      ) in permissions.list"
                      :key="`module-${moduleIndex}`"
                    >
                      <div
                        class="event-modules"
                        v-for="(
                          submodule, submoduleKey, submoduleIndex
                        ) in modules.value"
                        :key="`submodule-${submoduleIndex}`"
                      >
                        <div class="module-toolbar">
                          <input
                            type="checkbox"
                            :checked="ischeckall(submodule)"
                            @change="subselect(submodule)"
                            id="event.name"
                          />
                          <span
                            >{{ modules.module }} -
                            {{ submodule.sub_module }}</span
                          >
                        </div>
                        <div class="event-checkboxes">
                          <div
                            v-for="(event, index) in submodule.value"
                            :key="index"
                          >
                            <input
                              type="checkbox"
                              :checked="isChecked(event.permission)"
                              :id="event.permission"
                              @click="updatePermission(event.permission)"
                            />
                            <label
                              :class="{ active: isChecked(event.permission) }"
                              :for="event.permission"
                              >{{ event.title }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
            <v-divider></v-divider>
            <v-list class="extra-pad">
              <v-list-tile>
                <v-list-tile-action style="margin-left: auto">
                  <v-btn
                    ripple
                    color="primary"
                    @click="updateGroup"
                    v-if="page.canedit"
                  >
                    Save
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="groups.delete.active" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Detele Group info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text
              >Are you sure to delete this group information?</v-card-text
            >
            <v-card-text
              >Group Name: {{ groups.delete.object.group_name }}</v-card-text
            >
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="deleteGroupListReset(false)"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="deleteGroupListConfirm()"
            >Yes, Delete !</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="controls.snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="controls.snackbar.timeout"
    >
      {{ controls.snackbar.text }}
      <v-btn color="pink" flat @click="controls.snackbar.show = false"
        >Close</v-btn
      >
    </v-snackbar>
    <v-dialog v-model="controls.rest.processing" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";

export default {
  components: {
    loader,
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    let scope = this;
    this.getGroupList();
    this.getPermissionsList();
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:usergroup:create"
          ) !== -1
        ) {
          scope.page.cancreate = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:usergroup:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:usergroup:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
  },
  created() {},
  data() {
    return {
      checkbox: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "User Groups",
        icon: "fa-user",
        loading: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: false, refresh: false },
        addnew: [],
        cancreate: false,
        candelete: false,
        canedit: false,
      },
      controls: {
        snackbar: {
          show: false,
          timeout: 6000,
          text: "",
        },
        rest: {
          processing: false,
        },
        groupSection: {
          rest: {
            processing: false,
            fetching: false,
          },
        },
      },
      groups: {
        list: [],
        new: {
          name: "",
        },
        active: {},
        delete: {
          active: false,
          object: {},
        },
      },
      permissions: {
        list: [],
        active: [],
        entries_permission: [],
      },
    };
  },
  methods: {
    changeActive: function (item) {
      this.groups.active = Object.assign({}, item);
      this.viewGroup();
    },
    checkall: function () {
      if (this.permissions.active.length > 0 && this.checkbox==false) {
        this.permissions.active = [];
        this.checkbox = false;
      } else {
        this.checkbox = true;
        this.permissions.active =[...this.permissions.entries_permission] ;
      }
    },
    subselect: function (submodule) {
      let countdata = 0;
      submodule.value.map((elem) => {
        if (
          this.permissions.active.find((elm) => elm == elem.permission) ==
          undefined
        ) {
          this.permissions.active.push(elem.permission);
        } else {
          countdata++;
        }
      });
      if (submodule.value.length == countdata) {
        submodule.value.map((elem) => {
          this.permissions.active.splice(
            this.permissions.active.findIndex((t) => t == elem.permission),
            1
          );
        });
      }

      if (
        this.permissions.active.length !==
        this.permissions.entries_permission.length
      ) {
        this.checkbox = false;
      } else {
        this.checkbox = true;
      }
    },
    ischeckall: function (submodule) {
      let datacount = 0;
      submodule.value.map((elem) => {
        if (
          this.permissions.active.find((elm) => elm == elem.permission) !==
          undefined
        ) {
          datacount++;
        }
      });
      if (submodule.value.length == datacount) {
        return true;
      } else {
        return false;
      }
    },
    isChecked: function (val) {
      return this.permissions.active.indexOf(val) !== -1;
    },
    updatePermission: function (val) {
      let index = this.permissions.active.indexOf(val);
      if (index !== -1) {
        this.permissions.active.splice(index, 1);
      } else {
        this.permissions.active.push(val);
      }
      this.selectallcheck();
    },
    getGroupList: function () {
      let scope = this;
      scope.$store
        .dispatch("groupLists")
        .then((response) => {
          scope.groups.list = response;
          if (!scope.groups.active.group_id) {
            scope.groups.active = Object.assign({}, response[0]);
          }
          scope.viewGroup();
        })
        .catch(() => {
          scope.groups.list = [];
          scope.groups.active = {};
          scope.permissions.active = [];
        });
    },
    getPermissionsList: function () {
      let scope = this;
      this.$store.dispatch("getPermissions").then((response) => {
        scope.permissions.list = response.obj;
        scope.permissions.entries_permission = response.permission_data.map(
          (elm) => {
            return elm.permission_value;
          }
        );
        this.selectallcheck();
      });
    },
    selectallcheck: function () {
      if (
        this.permissions.active.length ==
        this.permissions.entries_permission.length
      ) {
        this.checkbox = true;
      } else {
        this.checkbox = false;
      }
    },
    viewGroup: function () {
      let scope = this;
      let data = scope.groups.active.group_id;
      scope.controls.groupSection.rest.processing = true;
      this.$store
        .dispatch("getGroupPermissions", data)
        .then((response) => {
          scope.permissions.active = response;
          scope.controls.groupSection.rest.processing = false;
          this.selectallcheck();
        })
        .catch(() => {
          scope.permissions.active = [];
          scope.controls.groupSection.rest.processing = false;
          this.selectallcheck();
        });
    },
    createGroup: function () {
      let scope = this;
      let data = {
        group_name: scope.groups.new.name,
        permissions: [],
      };
      scope.controls.rest.processing = true;
      this.$store
        .dispatch("createGroupList", data)
        .then((response) => {
          scope.groups.active = response;
          scope.groups.new.name = "";
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Group Created Successfully";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.getGroupList();
        })
        .catch(() => {
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Error Creating Group";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.permissions.active = [];
          scope.getGroupList();
        });
    },
    updateGroup: function () {
      let scope = this;
      let data = {
        args: scope.groups.active.group_id,
        params: {
          group_name: scope.groups.active.group_name,
          permissions: scope.permissions.active,
        },
      };
      scope.controls.rest.processing = true;
      this.$store
        .dispatch("editGroupList", data)
        .then(() => {
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Group Updated Successfully";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.permissions.active = [];
          scope.getGroupList();
        })
        .catch(() => {
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Error Updating Group And Permissions";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.permissions.active = [];
          scope.getGroupList();
        });
    },
    deleteGroupListInit: function (item) {
      let scope = this;
      scope.groups.delete.object = Object.assign({}, item);
      scope.groups.delete.active = true;
    },
    deleteGroupListReset: function (fetch) {
      let scope = this;
      scope.groups.delete.active = false;
      if (fetch) {
        scope.permissions.active = [];
        scope.groups.active = {};
        scope.groups.delete.object = {};
        scope.getGroupList();
      }
    },
    deleteGroupListConfirm: function () {
      let scope = this;
      let data = {
        args: scope.groups.delete.object.group_id,
      };
      scope.controls.rest.processing = true;
      this.$store
        .dispatch("deleteGroupList", data)
        .then(() => {
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Group Deleted Successfully";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.deleteGroupListReset(true);
        })
        .catch(() => {
          scope.controls.rest.processing = false;
          scope.controls.snackbar.text = "Error Deleting Group And Permissions";
          scope.controls.snackbar.show = true;
          setTimeout(() => {
            scope.controls.snackbar.show = false;
            scope.controls.snackbar.text = "";
          }, scope.controls.snackbar.timeout);
          scope.deleteGroupListReset(true);
        });
    },
  },
};
</script>
<style>
.extraheight-tabs .v-window__container {
  overflow: auto;
  height: 25em;
  padding: 7px;
}
.extraheight-tabs .v-tabs__container {
  height: 64px;
}
.event-checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.event-checkboxes > div {
  width: 33%;
  padding: 7px 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-checkboxes > div > input {
  visibility: hidden;
  margin-right: 0px;
}
.event-checkboxes > div > label {
  padding: 7px 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.event-checkboxes > div > label:hover {
  background: #ccc;
}
.event-checkboxes > div > label.active,
.event-checkboxes > div > label.active:hover {
  background: #2196f3;
  color: #fff;
  font-weight: bold;
}
.module-toolbar {
  display: flex;
  padding: 5px 10px;
  background: #ccc;
  border-radius: 3px 3x 0px 0px;
  align-items: center;
  justify-content: flex-start;
}
.event-modules {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 5px;
  text-transform: capitalize;
}
input[type="checkbox"] {
  margin-right: 10px;
}
</style>
<style scoped>
.scroll,
.extraheight-tabs .v-window__container {
  overflow: auto;
  height: 25em;
  padding: 7px;
}
.full-width {
  width: 100%;
  padding-top: 20px;
}
.extra-pad {
  padding: 10px;
}
.grouplist.selected {
  border-radius: 5px;
  color: #fff;
}
.grouptitle.bold {
  font-weight: bold;
  color: #fff;
}
.groupcount.highlight {
  color: #fff !important;
}
</style>
