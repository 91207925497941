import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterLeadInsert;
var getters = {
    GetAsterLeadInsert: state => state.AsterLeadInsert.getdata
};
const mutations = {
    [mutation_type.AsterLeadInsert]: (state, response) => {
        state.AsterLeadInsert.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {
    }
};
const actions = {
    Editdata: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.AsterLeadInsert, query);
            } catch (error) {
                reject(error);
            }
        });
    },
    BulkEditSave: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.put(`/aster/lead/bulk/edit`,payload).then((response) => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    BulkDeleteSave: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/aster/lead/bulk/delete`,payload).then((response) => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    gethistory: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/lead/details?phone_number=${query.phone_number}&lead_id=${query.id}`).then((response) => {
                    resolve(response.data.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    }

};
export default {
    state,
    getters,
    mutations,
    actions
};