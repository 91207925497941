<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewCampaign"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="campaignData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.campaign_name }}</td>
          <td class="checkEmpty p0 text-uppercase">
            {{ props.item.call_type }}
          </td>
          <td class="checkEmpty p0">
            {{ props.item.active == "Y" ? "Yes" : "No" }}
          </td>
          <td class="checkEmpty p0">
            <!-- ///////   Quck edit option code by abdul //////////////// -->
            <v-flex xs4 v-if="props.item.call_type == 'auto' || props.item.call_type == 'VB'">
              <v-select
                v-model="props.item.hooper_level"
                :items="addCampaign.options.buffer_level"
                v-bind:disabled="disabled"
                item-text="name"
                class="edit-input"
                item-value="value"
                v-on:change="onbufferChange($event, props.item)"
                return-string
                persistent-hint
              ></v-select>
            </v-flex>
          </td>
          <td class="checkEmpty p0">
            <v-flex xs4 v-if="props.item.call_type == 'auto'">
              <v-select
                v-model="props.item.dial_ratio"
                class="edit-input"
                :items="addCampaign.options.dial_ratio"
                v-bind:disabled="disabled"
                item-text="name"
                item-value="value"
                v-on:change="onratioChange($event, props.item)"
                required
                return-string
                persistent-hint
              ></v-select>
            </v-flex>
          </td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon
              color="green"
              small
              class="mr-4 fa fa-pencil-square-o"
              v-if="page.canedit && disabled"
              @click="disabled = !disabled"
            ></v-icon>
            <v-icon
              color="red"
              small
              class="mr-4 fa fa-pencil-square-o"
              v-if="page.canedit && !disabled"
              @click="disabled = !disabled"
            ></v-icon>
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Delete Campaign info</v-toolbar-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this campaign ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddPausecode" max-width="800px">
      <v-card>
        <v-form ref="Createform" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Campaign</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  v-model="addCampaign.payload.campaign_name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Campaign Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="addCampaign.payload.dial_statuses"
                    :items="filterdispo"
                    label="Dial Status"
                    required
                    persistent-hint
                    small
                    multiple
                    single-line
                    :allow-overflow="false"
                    :rules="[(v) => !!v || 'Dial Status is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="addCampaign.payload.caller_id"
                  required
                  label="Caller ID"
                  type="text"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="addCampaign.payload.default_queue"
                    :items="addCampaign.options.add_queue"
                    label="Add Queue"
                    persistent-hint
                    small
                    required
                    multiple
                    single-line
                    :allow-overflow="false"
                    :rules="[(v) => !!v || 'Queue is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="addCampaign.payload.call_type"
                  :items="addCampaign.options.type"
                  label="Type"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Type is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="addCampaign.payload.pause_code"
                    :items="pause_code"
                    label="Pause Code"
                    persistent-hint
                    small
                    required
                    multiple
                    single-line
                    :allow-overflow="false"
                    :rules="[(v) => !!v || 'Pause Code is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="addCampaign.payload.dispo_statuses"
                    :items="filterdispo"
                    label="Dispo Status"
                    persistent-hint
                    small
                    required
                    multiple
                    single-line
                    :allow-overflow="false"
                    :rules="[(v) => !!v || 'Dispo Status is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="addCampaign.payload.active"
                  :items="addCampaign.options.status"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Status is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'auto' || this.addCampaign.payload.call_type == 'VB'">
                <v-select
                  v-model="addCampaign.payload.hooper_level"
                  :items="addCampaign.options.buffer_level"
                  label="Buffer Level"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Buffer Level is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-checkbox
                  v-model="addCampaign.payload.dnc_check"
                  :label="`DNC check`"
                  color="primary"
                ></v-checkbox>
              </v-flex>
              <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'auto'">
                <v-select
                  v-model="addCampaign.payload.dial_ratio"
                  :items="addCampaign.options.dial_ratio"
                  label="Dial Ratio"
                  item-text="name"
                  item-value="value"
                  required
                  return-string
                  persistent-hint
                  :rules="[(v) => !!v || 'Dial Ratio is required']"
                ></v-select>
              </v-flex>

              <!-- ////////////show change based on call type by abdul///////////////// -->

              <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'VB'">
                <v-select
                  v-model="addCampaign.payload.channel_count"
                  :items="addCampaign.options.channels"
                  label="Channels"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Channels is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="addCampaign.payload.template_name"
                  :items="addCampaign.options.templates"
                  label="Template Name"
                  item-text="name"
                  item-value="value"
                  return-string
                  required
                  persistent-hint
                  :rules="[(v) => !!v || 'Template Name is required']"
                ></v-select>
              </v-flex>

              <!-- ////////////dail prefix changed to number only by abdul///////////////// -->

              <v-flex xs6>
                <v-text-field
                  v-model="addCampaign.payload.dial_prefix"
                  label="Dial Prefix"
                  type="number"
                ></v-text-field>
              </v-flex>
                <v-flex xs6>
                <v-checkbox
                  v-model="addCampaign.payload.timezone"
                  label="Timezone"
                  color="primary"
                ></v-checkbox>
            </v-flex> 
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" type="reset" flat @click="closeAddPausecode()"
              >Close</v-btn
            >
            <v-btn
              :disabled="!valid"
              color="primary"
              flat
              @click="saveAddAstercampaign()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditPausecode" max-width="800px">
      <v-card>
        <v-form ref="Updateform" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Campaign</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  v-model="editCampaign.campaign_name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Campaign Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="editCampaign.dial_statuses"
                    :items="filterdispo"
                    label="Dial Status"
                    persistent-hint
                    small
                    multiple
                    single-line
                    :allow-overflow="false"
                    required
                    :rules="[(v) => !!v || 'Dial Status is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="editCampaign.caller_id"
                  required
                  label="Caller ID"
                  type="text"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="editCampaign.default_queue"
                    :items="addCampaign.options.add_queue"
                    label="Add Queue"
                    persistent-hint
                    small
                    multiple
                    single-line
                    :allow-overflow="false"
                    required
                    :rules="[(v) => !!v || 'Queue is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="editCampaign.call_type"
                  :items="addCampaign.options.type"
                  label="Type"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Type is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="editCampaign.pause_code"
                    :items="pause_code"
                    label="Pause Code"
                    persistent-hint
                    small
                    multiple
                    single-line
                    :allow-overflow="false"
                    required
                    :rules="[(v) => !!v || 'Pause Code is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="autocompleteDiv">
                  <v-autocomplete
                    v-model="editCampaign.dispo_statuses"
                    :items="filterdispo"
                    label="Dispo Status"
                    persistent-hint
                    small
                    multiple
                    single-line
                    :allow-overflow="false"
                    required
                    :rules="[(v) => !!v || 'Dispo Status is required']"
                  ></v-autocomplete>
                </div>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="editCampaign.active"
                  :items="addCampaign.options.status"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Status is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6 v-if="this.editCampaign.call_type == 'auto' || this.editCampaign.call_type == 'VB'">
                <v-select
                  v-model="editCampaign.hooper_level"
                  :items="addCampaign.options.buffer_level"
                  label="Buffer Level"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Buffer Level is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-checkbox
                  v-model="editCampaign.dnc_check"
                  :label="`DNC check`"
                   color="primary"
                ></v-checkbox>
              </v-flex>
              <v-flex xs6 v-if="this.editCampaign.call_type == 'auto'">
                <v-select
                  v-model="editCampaign.dial_ratio"
                  :items="addCampaign.options.dial_ratio"
                  label="Dial Ratio"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Dial Ratio is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6 v-if="this.editCampaign.call_type == 'VB'">
                <v-select
                  v-model="editCampaign.channel_count"
                  :items="addCampaign.options.channels"
                  label="Channels"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Channels is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="editCampaign.template_name"
                  :items="addCampaign.options.templates"
                  label="Template Name"
                  item-text="name"
                  item-value="value"
                  return-string
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Template Name is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="editCampaign.dial_prefix"
                  label="Dial Prefix"
                  type="number"
                ></v-text-field>
              </v-flex>
             <v-flex xs6>
                <v-checkbox
                  v-model="editCampaign.timezone"
                  label="Timezone"
                    color="primary"
                ></v-checkbox>
            </v-flex> 
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.campaign_name"
              :items="filtervalue"
              label="Name"
              small
            ></v-select>
          </div>

          <!-- /////////two more filter is added by abdul//////// -->
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.campaign_type"
              :items="filter.options.type"
              label="Type"
              item-text="name"
              item-value="value"
              return-string
              required
              persistent-hint
            ></v-select>
          </div>
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.campaign_status"
              :items="filter.options.status"
              label="Status"
              item-text="name"
              item-value="value"
              return-string
              persistent-hint
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      disabled: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      pause_code: [],
      selected: { name: "ACTIVE", value: "Y" },
      page: {
        title: "Campaign",
        icon: "fa-clipboard",
        loading: false,
        isAddPausecode: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditPausecode: false,
        temp: { key: "addastercampaign", label: "Add AsterCampaign" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "campaign_name",
        },
        {
          text: "Type",
          align: "left",
          class: "p0",
          sortable: false,
          value: "call_type",
        },
        {
          text: "Active",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Buffer level",
          align: "left",
          class: "p0",
          sortable: false,
          value: "hooper_level",
        },
        {
          text: "Dial Ratio",
          align: "left",
          class: "p0",
          sortable: false,
          value: "dial_ratio",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          class: "p0",
          value: "btn",
        },
      ],
      campaignData: [],
      dispo_status: ["new", "Answer", "No Answer"],
      //pagecount: 0,
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filterdispo: [],
      filtervalue: [],
      filter: {
        payload: {
          campaign_name: "",
          campaign_type: "",
          campaign_status: "",
        },
        options: {
          type: [
            { name: "POWER", value: "power" },
            { name: "AUTO", value: "auto" },
            { name: "VOICE BLAST", value: "VB" },
            { name: "PREVIEW", value: "preview" },
            { name: "INBOUND", value: "inbound" },
          ],
          status: ["Active", "Inactive"],
        },
      },
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editCampaign: {
        campaign_name: "",
        dial_statuses: "",
        caller_id: "",
        default_queue: "",
        call_type: "",
        pause_code: "",
        dispo_statuses: "",
        active: "",
        hooper_level: "",
        dnc_check: false,
        dial_ratio: "",
        channel_count: "",
        template_name: "",
        dial_prefix: "",
        timezone:false
      },
      addCampaign: {
        valid: false,
        payload: {
          campaign_name: "",
          dial_statuses: "",
          caller_id: "",
          default_queue: "",
          call_type: "",
          pause_code: "",
          dispo_statuses: "",
          active: { name: "ACTIVE", value: "Y" },
          hooper_level: "",
          dnc_check: false,
          dial_ratio: "",
          channel_count: "",
          template_name: "",
          dial_prefix: "",
          timezone:false
        },
        options: {
          // dial_status: filters.dispo_status,
          add_queue: [],
          type: [
            { name: "POWER", value: "power" },
            { name: "AUTO", value: "auto" },
            { name: "VOICE BLAST", value: "VB" },
            { name: "PREVIEW", value: "preview" },
            { name: "INBOUND", value: "inbound" },
          ],
          // pause_code: status,
          // dispo_status: filters.dispo_status,
          status: [
            { status_id: 1, name: "ACTIVE", value: "Y" },
            { status_id: 2, name: "INACTIVE", value: "N" },
          ],
          buffer_level: [
            { name: "5", value: "5" },
            { name: "10", value: "10" },
            { name: "50", value: "50" },
            { name: "100", value: "100" },
            { name: "500", value: "500" },
            { name: "1000", value: "1000" },
            { name: "2000", value: "2000" },
          ],
          //////// dail ratio value changed  by abdul//////
          dial_ratio: [
            { name: "0", value: "0" },
            { name: "1", value: "1" },
            { name: "1.2", value: "1.2" },
            { name: "1.4", value: "1.4" },
            { name: "1.6", value: "1.6" },
            { name: "1.8", value: "1.8" },
            { name: "2", value: "2" },
            { name: "2.2", value: "2.2" },
            { name: "2.4", value: "2.4" },
            { name: "2.6", value: "2.6" },
            { name: "2.8", value: "2.8" },
            { name: "3", value: "3" },
            { name: "3.2", value: "3.2" },
            { name: "3.4", value: "3.4" },
            { name: "3.6", value: "3.6" },
            { name: "3.8", value: "3.8" },
            { name: "4", value: "4" },
            { name: "4.2", value: "4.2" },
            { name: "4.4", value: "4.4" },
            { name: "4.6", value: "4.6" },
            { name: "4.8", value: "4.8" },
            { name: "5", value: "5" },
            { name: "6", value: "6" },{ name: "7", value: "7" },{ name: "8", value: "8" },{ name: "9", value: "9" },{ name: "10", value: "10" },{ name: "11", value: "11" },{ name: "12", value: "12" },{ name: "13", value: "13" },{ name: "14", value: "14" },{ name: "15", value: "15" },{ name: "16", value: "16" },{ name: "17", value: "17" },{ name: "18", value: "18" },{ name: "19", value: "19" },{ name: "20", value: "20" }
          ],
          channels: [
            { name: "5", value: "5" },
            { name: "10", value: "10" },
            { name: "15", value: "15" },
            { name: "20", value: "20" },
            { name: "25", value: "25" },
            { name: "30", value: "30" },
            { name: "35", value: "35" },
            { name: "40", value: "40" },
            { name: "45", value: "45" },
            { name: "50", value: "50" },
          ],
          templates: [],
        },
      },
    };
  },
  methods: {
    // quick edit buffer update by abdul
    // Why you passing all element (pass only editted and required fields only)

    onbufferChange: function(e, item) {
      this.editCampaign.id = item.id;
      this.editCampaign.campaign_name = item.campaign_name;
      this.editCampaign.dial_statuses = item.dial_statuses;
      this.editCampaign.caller_id = item.caller_id;
      this.editCampaign.default_queue = item.default_queue;
      this.editCampaign.call_type = item.call_type;
      this.editCampaign.pause_code = item.pause_code;
      this.editCampaign.dispo_statuses = item.dispo_statuses;
      this.editCampaign.active = item.active;
      this.editCampaign.dnc_check = item.dnc_check;
      this.editCampaign.dial_ratio = item.dial_ratio;
      this.editCampaign.channel_count = item.channel_count;
      this.editCampaign.template_name = item.template_name;
      this.editCampaign.dial_prefix = item.dial_prefix;

      this.editCampaign.hooper_level = e;

      var scope = this;
      scope.$store
        .dispatch("updateAsterCampaign", scope.editCampaign)
        .then(() => {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditPausecode = false;
          scope.getDataFromApi();
        })
        .catch(function(error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
      scope.getDataFromApi();
    },

    // quick edit dail ratio update by abdul
    // Why you passing all element (pass only editted and required fields only)
    onratioChange: function(e, item) {
      this.editCampaign.id = item.id;
      this.editCampaign.campaign_name = item.campaign_name;
      this.editCampaign.dial_statuses = item.dial_statuses;
      this.editCampaign.caller_id = item.caller_id;
      this.editCampaign.default_queue = item.default_queue;
      this.editCampaign.call_type = item.call_type;
      this.editCampaign.pause_code = item.pause_code;
      this.editCampaign.dispo_statuses = item.dispo_statuses;
      this.editCampaign.active = item.active;
      this.editCampaign.dnc_check = item.dnc_check;
      this.editCampaign.channel_count = item.channel_count;
      this.editCampaign.template_name = item.template_name;
      this.editCampaign.dial_prefix = item.dial_prefix;
      this.editCampaign.hooper_level = item.hooper_level;

      this.editCampaign.dial_ratio = e;

      var scope = this;
      scope.$store
        .dispatch("updateAsterCampaign", scope.editCampaign)
        .then(() => {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditPausecode = false;
        })
        .catch(function(error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
      scope.getDataFromApi();
    },
    refreshThisPage: function() {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function() {
      this.page.isFilterOpen = true;
    },
    fetchFilter() {
      //filter changed by abdul
      // Using ternary operator instead of if condition
      var scope = this;
      scope.tableLoading = true;
      let status = "";
      if (scope.filter.payload.campaign_status == "Active") {
        status = "Y";
      }
      if (scope.filter.payload.campaign_status == "Inactive") {
        status = "N";
      }

      var data = {
        campaign_name: scope.filter.payload.campaign_name,
        campaign_type: scope.filter.payload.campaign_type,
        campaign_status: status,
      };
      this.$store
        .dispatch("FilterAsterCampaigns", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.campaignData = datas;
        })
        .catch(function(error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      scope.filter.payload.campaign_name = "";
      scope.filter.payload.campaign_type = "";
      scope.filter.payload.campaign_status = "";
      scope.filtervalue = [];
      this.$store
        .dispatch("GetAsterCampaigns")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            datas.forEach((element) => {
              scope.filtervalue.push(element["campaign_name"]);
            });
          }
          scope.campaignData = datas;
        })
        .catch(function(error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },
    AddNewCampaign: function() {
      //var scope = this;
      this.page.isAddPausecode = true;
      this.addCampaign.payload.template_name = "default";
      this.addCampaign.payload.hooper_level = "100";
    },
    closeEditRules: function() {
      this.page.isEditPausecode = false;
      this.$refs.Updateform.resetValidation();
    },
    closeAddPausecode: function() {
      var scope = this;
      scope.page.isAddPausecode = false;
      scope.addCampaign.payload = {
        campaign_name: "",
        dial_statuses: "",
        caller_id: "",
        default_queue: "",
        call_type: "",
        pause_code: "",
        dispo_statuses: "",
        active: { name: "ACTIVE", value: "Y" },
        hooper_level: "",
        dnc_check: false,
        dial_ratio: "",
        channel_count: "",
        template_name: "",
        dial_prefix: "",
      };
      scope.$refs.Createform.resetValidation();
    },
    saveEditRules() {
      this.$refs.Updateform.validate();
      var scope = this;
      if(scope.editCampaign.timezone == true){
        scope.editCampaign.timezone = 'Y'
      }else{
         scope.editCampaign.timezone = 'N'
      }
      scope.page.isEditPausecode = !this.$refs.Updateform.validate();
      if (scope.$refs.Updateform.validate()) {
        scope.$store
          .dispatch("updateAsterCampaign", scope.editCampaign)
          .then(() => {
            scope.showSnackBar("Updated Successfully");
            scope.page.isEditPausecode = false;
            scope.getDataFromApi();
            scope.$refs.Updateform.resetValidation();
          })
          .catch(function(error) {
            scope.page.isAddPausecode = false;
            scope.$refs.Updateform.resetValidation();
            scope.showSnackBar("error" + error);
            scope.page.isEditPausecode = false;
          });
      }
      scope.getDataFromApi();
    },
    saveAddAstercampaign() {
      this.$refs.Createform.validate();
      var scope = this;
      if(scope.addCampaign.payload.timezone){
        scope.addCampaign.payload.timezone='Y'
      }else{
         scope.addCampaign.payload.timezone='N'
      }
      scope.page.isAddPausecode = !this.$refs.Createform.validate();
      if (this.$refs.Createform.validate()) {
        scope.$store
          .dispatch("insertAsterCampaign", scope.addCampaign.payload)
          .then(() => {
            scope.showSnackBar("Created Successfully");
            scope.addCampaign.payload.campaign_name = "";
            scope.addCampaign.payload.dial_statuses = "";
            scope.addCampaign.payload.caller_id = " ";
            scope.addCampaign.payload.default_queue = "";
            scope.addCampaign.payload.call_type = "";
            scope.addCampaign.payload.pause_code = "";
            scope.addCampaign.payload.dispo_statuses = "";
            scope.addCampaign.payload.active = "";
            scope.addCampaign.payload.hooper_level = "";
            scope.addCampaign.payload.dnc_check = false;
            scope.addCampaign.payload.dial_ratio = "";
            scope.addCampaign.payload.channel_count = "";
            scope.addCampaign.payload.template_name = "";
            scope.addCampaign.payload.dial_prefix = "";
            scope.getDataFromApi();
            this.$refs.Createform.resetValidation();
          })
          .catch(function(error) {
            scope.page.isAddPausecode = false;
            scope.showSnackBar(error.response.data.message);
            this.$refs.Createform.resetValidation();
          });
      }
      scope.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
   
      this.page.isEditPausecode = true;
      this.editCampaign.id = item.id;
      this.editCampaign.campaign_name = item.campaign_name;
      this.editCampaign.dial_statuses = item.dial_statuses;
      this.editCampaign.caller_id = item.caller_id;
      this.editCampaign.default_queue = item.default_queue;
      this.editCampaign.call_type = item.call_type;
      this.editCampaign.pause_code = item.pause_code;
      this.editCampaign.dispo_statuses = item.dispo_statuses;
      this.editCampaign.active = item.active;
      this.editCampaign.hooper_level = item.hooper_level;

      if (item.dnc_check == "Y") {
        this.editCampaign.dnc_check = true;
      } else {
        this.editCampaign.dnc_check = false;
      }

      this.editCampaign.dial_ratio = item.dial_ratio;
      this.editCampaign.channel_count = item.channel_count;
      this.editCampaign.template_name = item.template_name;
      this.editCampaign.dial_prefix = item.dial_prefix;
      if(item.timezone == 'Y'){
      this.editCampaign.timezone =true;
      }else{
         this.editCampaign.timezone =false;
      }

    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAsterCampaign", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("List Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
      scope.getDataFromApi();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow", "filters", "status"]),
  },
  mounted() {
    let scope = this;
    scope.page.loading = false;
    // this.$store.dispatch("GetDisposition");
    scope.filterdispo = JSON.parse(localStorage.getItem("group_disposition"));
    // scope.pause_code = JSON.parse(localStorage.getItem("pause_code"));
    scope.$store.dispatch("GetPausecode").then((response) => {
            let data = response;
            let pausecode = [];
            for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].status == "Ac") {
                  pausecode.push(data.data[i].pause_code);
                }
            }
            scope.pause_code =pausecode
        });
    scope.$store.dispatch("GetTemplate").then((response) => {
      scope.addCampaign.options.templates = response;
    });
    scope.$store.dispatch("queue").then((response) => {
      let data = response;
      let queue = [];
      for (let i = 0; i < data.length; i++) {
        queue.push(data[i]);
      }
      scope.addCampaign.options.add_queue = queue;
    });
    setTimeout(
      function() {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:campaigns:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Campaign" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:campaigns:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:campaigns:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    // this.page.addnew = [{ label: "Add Campaign" }];
    // scope.page.candelete = true;
    // scope.page.canedit = true;
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
.edit-input.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
</style>
