import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterMail;
var getters = {
    Getastermail: state => state.AsterMail.getdata
};
const mutations = {
    [mutation_type.AsterMail]: (state, response) => {
        state.AsterMail.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {
    }
};
const actions = {
    GetConfig: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/email/mailconfig`).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    UpdateConfig: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/email/mailconfig`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetAsterMail: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/mail`).then(response => {
                    const astermail = response.data;
                    resolve(response.data);
                    commit(mutation_type.AsterMail, astermail);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterMail: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/mail?name=${query}`).then(response => {
                    const astermail = response.data;
                    resolve(response.data);
                    commit(mutation_type.AsterMail, astermail);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertAsterMail: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/mail`, payload)
                    .then(response => {
                        const astermail = response.data;
                        resolve(response);
                        commit(mutation_type.AsterMail, astermail);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterMail: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/mail/${payload}`)
                    .then(response => {
                        const astermail = response.data;
                        resolve(response);
                        commit(mutation_type.AsterMail, astermail);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAsterMail: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/aster/mail/${id}`, payload)
                    .then(response => {
                        const astermail = response.data;
                        resolve(response);
                        commit(mutation_type.AsterMail, astermail);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};