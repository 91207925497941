<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
    <div>
        <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewWidget" :style="styleObject"></section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table :headers="headers" :items="widgets" class="elevation-1 fixed-layout" :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty p0">{{ props.item.name }}</td>
                    <td class="checkEmpty p0">{{ props.item.type }}</td>
                    <td class="checkEmpty p0">
                        {{ props.item.isShowOnDashboard ? "Yes" : "No" }}
                    </td>
                    <td class="text-xs-center checkEmpty p0">
                        <v-btn small fab :color="'success'" @click="openPreview(props.item)" flat>
                            <i class="fas fa-eye"></i>
                        </v-btn>
                        <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">delete</v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-navigation-drawer fixed right :value="page.isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">
                    <div class="autocompleteDiv">
                        <v-text-field v-model="filter.payload.name" label="Name" small></v-text-field>
                    </div>
                    <div class="autocompleteDiv">
                        <v-autocomplete v-model="filter.payload.type" :items="widget_types" label="Type" persistent-hint small single-line :allow-overflow="false"></v-autocomplete>
                    </div>
                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="resetFilter()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click="getDataFromApi()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>
        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete widget info</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete widget list.</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="DeleteRowdata = false">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOpenPreview" max-width="800px">
            <v-card>
                <component style="
            display: flex;
            resize: both;
            min-height: 30%;
            min-width: 50%;
            height: 500px;
          " :timer="preview.timer" :id="preview.id" :is="preview.component" :name="preview.name" :row_limit="preview.row_limit">
                </component>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
export default {
    components: {
        loader,
    },

    data() {
        return {
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            page: {
                title: "Widgets",
                icon: "fa-clipboard",
                loading: false,
                isFilterOpen: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
                addnew: [],
                candelete: false,
                canedit: false,
            },
            widgets: [],
            pagecount: 0,
            pagination: {
                page: 0,
                rowsPerPage: 5,
            },
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            headers: [{
                    text: "Name",
                    align: "left",
                    class: "p0",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Type",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "type",
                },
                {
                    text: "Show on Dashboard",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "show on dashboard",
                },
                {
                    text: "Action",
                    align: "center",
                    sortable: false,
                    value: "btn",
                    width: "20%",
                },
            ],
            DeleteRowdata: false,
            tableLoading: false,
            filter: {
                payload: {
                    name: "",
                    type: "",
                },
                options: {
                    status: ["Active", "Inactive"],
                },
            },
            isProcessing: false,
            widget_types: ["Chart", "Table"],
            delete_id: 0,
            preview: {
                timer: 0,
                id: 0,
                component: "",
                name: "",
            },
            isOpenPreview: false,
        };
    },

    created() {
        const scope = this;
        scope.getDependencies();
        scope.$store.dispatch("rect/clearWidgets");
    },
    beforeDestroy() {
        const scope = this;
        scope.$store.dispatch("rect/clearWidgets");
    },

    mounted() {
        const scope = this;
        this.page.loading = false;
        setTimeout(
            function() {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:pausecode:create"
                    ) !== -1
                ) {
                    scope.page.addnew = [{ label: "Add Widget" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:pausecode:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:pausecode:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        this.getDataFromApi();
    },
    methods: {
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        getDependencies() {
            const scope = this;
            scope.$store.dispatch("rect/config").then((response) => {
                scope.widget_types = response.widget_types;
            });
        },
        getDataFromApi() {
            const scope = this;
            scope.tableLoading = true;
            scope.page.isFilterOpen = false;
            scope.$store
                .dispatch("rect/fetchWidgets", scope.filter.payload)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    scope.widgets = response;
                })
                .catch(function(error) {
                    scope.widgets = [];
                    scope.showSnackBar("error :" + error.message);
                    scope.tableLoading = false;
                    scope.page.loading = false;
                });
        },
        resetFilter: function() {
            var scope = this;
            scope.filter.payload.name = "";
            scope.filter.payload.type = "";
            scope.page.loading = true;
            scope.getDataFromApi();
        },
        refreshThisPage: function() {
            var scope = this;
            scope.page.loading = true;
            scope.getDataFromApi();
        },
        AddNewWidget: function() {
            this.$router.push({ name: "addwidgets" });
        },
        filterClick: function() {
            this.page.isFilterOpen = true;
        },
        editItem: function(item) {
            const scope = this;
            scope.$store.dispatch("rect/fetchWidgetData", item.id).then(() => {
                scope.$router.push({ name: "editwidgets" });
            });
        },
        deleteItem(id) {
            const scope = this;
            scope.delete_id = id;
            scope.DeleteRowdata = true;
        },
        savedelete() {
            const scope = this;
            const id = scope.delete_id;
            scope.$store
                .dispatch("rect/removeWidget", { id })
                .then(() => {
                    scope.DeleteRowdata = false;

                    scope.page.loading = true;
                    scope.showSnackBar("Widget deleted Succesfully");
                    scope.getDataFromApi();
                })
                .catch((err) => {
                    scope.showSnackBar("error :" + err.message);
                });
        },
        openPreview(item) {
            const scope = this;
            scope.$store.dispatch("rect/updatePreview", { id: item.id });
            scope.$store.dispatch("rect/fetchWidgetData", item.id).then((result) => {
                scope.preview.timer = result.timer;
                scope.preview.id = result.id;
                scope.preview.component = result.component;
                scope.preview.name = result.name;
                scope.isOpenPreview = true;
            });
        },
    },
};
</script>