<template>
  <div>
    <v-card>
      <h1>
        <label
          style="font-size: 25px;text-align:center;display:block;color:black;padding:5px;"
          >Create Widget</label
        >
      </h1>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs3>
            <v-text-field
              required
              v-model="create.widget_name"
              label="Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-select
              :items="table_names"
              label="Data Table"
              item-text="name"
              item-value="value"
              return-string
              v-model="create.data_table"
              @change="getColumns(create.data_table)"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select
              :items="widget_types"
              label="Widget Type"
              v-model="create.widget_type"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select
              :items="seconds"
              label="Refresh rate"
              v-model="create.refresh_rate_sec"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3 v-if="create.widget_type === 'Chart'">
            <v-select
              :items="chart_types"
              item-text="name"
              item-value="value"
              return-string
              label="Chart Type"
              v-model="create.chart_type"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3 v-if="create.widget_type === 'Chart'">
            <v-select
              :items="xaxis_columns"
              label="Params"
              item-text="name"
              item-value="value"
              return-string
              v-model="create.xaxis_column"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3 v-if="create.widget_type === 'Chart'">
            <v-select
              :items="yaxis_columns"
              label="Value"
              item-text="name"
              item-value="value"
              return-string
              v-model="create.yaxis_column"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3 v-if="create.widget_type === 'Chart'">
            <v-text-field
              required
              v-model="create.row_limit"
              label="Row Limit"
            ></v-text-field>
          </v-flex>
          <v-flex xs3 v-if="create.widget_type === 'Table'">
            <v-btn
              style="width:50%;background-color:#47c4be;font-size:14px;border-radius:5px;padding:5px 10px 5px;color:white;"
              @click="edit_summarize"
              >Summarize</v-btn
            ><i
              @click="function_summarize"
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            ></i>
          </v-flex>
          <v-flex xs3>
            <v-btn
              style="width:50%;background-color:#47c4be;font-size:14px;border-radius:5px;padding:5px 10px 5px;color:white;"
              @click="edit_filter"
              >Filter</v-btn
            ><i
              @click="function_filter"
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            ></i>
          </v-flex>
          <v-flex xs3>
            <v-btn
              style="width:50%;background-color:#47c4be;font-size:14px;border-radius:5px;padding:5px 10px 5px;color:white;"
              >Group</v-btn
            ><i
              @click="function_group"
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            ></i>
          </v-flex>
          <v-flex xs3>
            <v-btn
              style="width:50%;background-color:#47c4be;font-size:14px;border-radius:5px;padding:5px 10px 5px;color:white;"
              @click="edit_sort"
              >Sort</v-btn
            ><i
              @click="function_sort"
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            ></i>
          </v-flex>
          <v-flex xs3>
            <textarea
              v-model="function_area"
              style="width:100%;height:100%;"
              disabled
            />
          </v-flex>
          <v-flex xs3>
            <textarea
              v-model="filter_area"
              style="width:100%;height:100%;"
              disabled
            />
          </v-flex>
          <v-flex xs3>
            <textarea
              v-model="group_area"
              style="width:100%;height:100%;"
              disabled
            />
          </v-flex>
          <v-flex xs3>
            <textarea
              v-model="sort_area"
              style="width:100%;height:100%;"
              disabled
            />
          </v-flex>
          <v-flex xs4 v-if="summarize_function">
            <v-select
              :items="function_types"
              item-text="name"
              item-value="value"
              return-string
              label="Function Type"
              v-model="function_metrics.function_type"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs4 v-if="function_metrics.function_type">
            <v-select
              :items="xaxis_columns"
              label="Select Column To Summarize"
              item-text="name"
              item-value="value"
              return-string
              v-model="function_metrics.column_name"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs3 v-if="function_metrics.column_name">
            <v-text-field
              v-model="function_metrics.alias_name"
              label="Alias Name"
            ></v-text-field>
          </v-flex>
          <i
            v-if="function_metrics.column_name"
            @click="summarize_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check"
            aria-hidden="true"
          ></i>
          <i
            v-if="summarize_function"
            @click="function_cancel"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
          <v-flex xs4 v-if="summarize_filter">
            <v-select
              :items="xaxis_columns"
              label="Select Column To Filter"
              item-text="name"
              item-value="value"
              return-string
              v-model="filter_metrics.column_name"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs2 v-if="filter_metrics.column_name">
            <v-select
              :items="operations"
              label="Select Condition"
              item-text="name"
              item-value="value"
              return-string
              v-model="filter_metrics.operation"
            ></v-select>
          </v-flex>
          <v-flex
            xs3
            v-if="
              filter_metrics.operation &&
                filter_metrics.operation !== 'IS NULL' &&
                filter_metrics.operation !== 'IS NOT NULL'
            "
          >
            <v-text-field
              v-if="
                (filter_metrics.operation &&
                  filter_metrics.operation === 'IN') ||
                  filter_metrics.operation === 'NOT IN'
              "
              v-model="filter_metrics.column_value"
              label="Column Value"
              hint="Give Values in Comma Seperated"
            ></v-text-field>
            <v-text-field
              v-if="
                (filter_metrics.operation &&
                  filter_metrics.operation === 'BETWEEN') ||
                  filter_metrics.operation === 'NOT BETWEEN'
              "
              v-model="filter_metrics.column_value"
              label="Column Value"
              hint="Give Date(YYYY-MM-DD) in Comma Seperated"
            ></v-text-field>
            <v-text-field
              v-if="
                filter_metrics.operation &&
                  filter_metrics.operation !== 'BETWEEN' &&
                  filter_metrics.operation !== 'NOT BETWEEN' &&
                  filter_metrics.operation !== 'IN' &&
                  filter_metrics.operation !== 'NOT IN'
              "
              v-model="filter_metrics.column_value"
              label="Column Value"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 v-if="filter_metrics.column_name">
            <v-select
              :items="options"
              label="Select Method"
              item-text="name"
              item-value="value"
              return-string
              v-model="filter_metrics.option"
            ></v-select>
          </v-flex>
          <i
            v-if="filter_metrics.operation"
            @click="filter_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check"
            aria-hidden="true"
          ></i>
          <i
            v-if="summarize_filter"
            @click="filter_cancel"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
          <v-flex xs6 v-if="summarize_sort">
            <v-select
              :items="xaxis_columns"
              label="Select Column To Sort"
              item-text="name"
              item-value="value"
              return-string
              v-model="sort.column_name"
              :disabled="create.data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex xs5 v-if="sort.column_name">
            <v-select
              :items="orders"
              label="Order By"
              item-text="name"
              item-value="value"
              return-string
              v-model="sort.order"
            ></v-select>
          </v-flex>
          <i
            v-if="sort.order"
            @click="sort_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check"
            aria-hidden="true"
          ></i>
          <i
            v-if="summarize_sort"
            @click="sort_cancel"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
          <v-flex xs6 v-if="summarize_group">
            <v-select
              :items="xaxis_columns"
              label="Select Column To Group"
              item-text="name"
              item-value="value"
              return-string
              v-model="group"
              :disabled="create.data_table == ''"
              persistent-hint
              multiple
            ></v-select>
          </v-flex>
          <i
            v-if="summarize_group"
            @click="group_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check"
            aria-hidden="true"
          ></i>
          <i
            v-if="summarize_group"
            @click="group_cancel"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
          <div
            style="display:flex"
            v-for="(item, index) in metrics"
            :key="index"
          >
            <v-flex xs4 v-if="summarize_edit">
              <v-select
                :items="function_types"
                item-text="name"
                item-value="value"
                return-string
                label="Function Type"
                v-model="item.function_type"
                :disabled="create.data_table == ''"
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs4 v-if="summarize_edit">
              <v-select
                :items="xaxis_columns"
                label="Select Column To Summarize"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.column_name"
                :disabled="create.data_table == ''"
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs3 v-if="summarize_edit">
              <v-text-field
                v-model="item.alias_name"
                label="Alias Name"
              ></v-text-field>
            </v-flex>
            <i
              v-if="summarize_edit"
              @click="summarize_delete(item)"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </div>
          <i
            v-if="summarize_edit"
            @click="summarize_edited_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check-circle-o"
            aria-hidden="true"
          ></i>
          <div
            style="display:flex"
            v-for="(item, index) in filters"
            :key="'A' + index"
          >
            <v-flex xs3 v-if="filter_edit">
              <v-select
                :items="xaxis_columns"
                label="Select Column To Filter"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.column_name"
                :disabled="create.data_table == ''"
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs3 v-if="filter_edit">
              <v-select
                :items="operations"
                label="Select Condition"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.operation"
              ></v-select>
            </v-flex>
            <v-flex
              xs2
              v-if="
                filter_edit &&
                  item.operation !== 'IS NULL' &&
                  item.operation !== 'IS NOT NULL'
              "
            >
              <v-text-field
                v-model="item.column_value"
                label="Column Value"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 v-if="filter_edit">
              <v-select
                :items="options"
                label="Select Method"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.option"
              ></v-select>
            </v-flex>
            <i
              v-if="filter_edit"
              @click="filter_delete(item)"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </div>
          <i
            v-if="filter_edit"
            @click="filter_edited_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check-circle-o"
            aria-hidden="true"
          ></i>
          <div
            style="display:flex"
            v-for="(item, index) in sorted"
            :key="'B' + index"
          >
            <v-flex xs6 v-if="sort_edit">
              <v-select
                :items="xaxis_columns"
                label="Select Column To Sort"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.column_name"
                :disabled="create.data_table == ''"
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs5 v-if="sort_edit">
              <v-select
                :items="orders"
                label="Order By"
                item-text="name"
                item-value="value"
                return-string
                v-model="item.order"
              ></v-select>
            </v-flex>
            <i
              v-if="sort_edit"
              @click="sort_delete(item)"
              style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </div>
          <i
            v-if="sort_edit"
            @click="sort_edited_save"
            style="color:#47c4be;padding:5px;padding-top:5px;font-size:25px;"
            class="fa fa-check"
            aria-hidden="true"
          ></i>
          <v-flex xs6>
            <v-checkbox
              color="primary"
              label="Show On Dashboard"
              v-model="create.isShowOnDashboard"
              persistent-hint
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 v-if="create.widget_type === 'Table'">
            <v-text-field
              required
              v-model="create.row_limit"
              label="Row Limit"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light" flat @click="goback">Cancel</v-btn>
        <v-btn color="primary" @click="createWidget">Create</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      table_names: [],
      seconds: [],
      widget_types: ["Chart", "Table"],
      chart_types: ["Pie", "Doughnut", "Line", "Bar", "Horizontal Bar"],
      xaxis_columns: [],
      yaxis_columns: [],
      create: {
        widget_name: "",
        data_table: "",
        widget_type: "",
        refresh_rate_sec: "",
        isShowOnDashboard: false,
        row_limit: "",
        xaxis_column: "",
        yaxis_column: "",
        chart_type: "",
        column: "",
      },
      summarize_function: false,
      summarize_edit: false,
      function_types: [
        "select",
        "sum_of",
        "count_of",
        "average_of",
        "min_of",
        "max_of",
        "distinct_of",
      ],
      function_metrics: {
        function_type: "",
        column_name: "",
        alias_name: "",
      },
      metrics: [],
      function_area: "",
      summarize_filter: false,
      operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IN",
        "NOT IN",
        "IS NULL",
        "IS NOT NULL",
        "BETWEEN",
        "NOT BETWEEN",
        "=",
        ">",
        "<",
        ">=",
        "<=",
        "!=",
      ],
      options: ["All", "Any"],
      filter_metrics: {
        column_name: "",
        operation: "",
        option: "",
        column_value: "",
      },
      filters: [],
      filter_area: "",
      filter_edit: false,
      summarize_sort: false,
      orders: ["ASC", "DESC"],
      sort: {
        column_name: "",
        order: "",
      },
      sorted: [],
      sort_area: "",
      sort_edit: false,
      summarize_group: false,
      group: "",
      group_area: "",
    };
  },
  mounted() {
    const scope = this;
    var widgetTable = JSON.parse(localStorage.getItem("widgetTable"));
    scope.widget_types = widgetTable.widget_types;
    scope.chart_types = widgetTable.chart_types;
    scope.table_names = widgetTable.tables;
    for (let i = 5; i <= 90; i++) {
      if (i % 5 == 0) {
        scope.seconds.push(i);
      }
    }
  },
  methods: {
    getColumns(table) {
      const scope = this;
      scope.$store.dispatch("rect/fetchTableColumn", table).then((response) => {
        scope.yaxis_columns = response;
        scope.xaxis_columns = response;
        scope.filter_area = "";
        scope.function_area = "";
        scope.sort_area = "";
        scope.group_area = "";
        scope.metrics = [];
        scope.filters = [];
        scope.sorted = [];
        scope.summarize_edit = false;
        scope.filter_edit = false;
        scope.sort_edit = false;
      });
    },
    goback() {
      const scope = this;
      scope.$router.push({ name: "Widgets" });
    },
    function_summarize() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_function = true;
    },
    function_filter() {
      var scope = this;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_filter = true;
    },
    function_sort() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_sort = true;
    },
    function_group() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_edit = false;
      scope.summarize_group = true;
    },
    sort_cancel() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
    },
    sort_save() {
      var scope = this;
      scope.sorted.push(JSON.parse(JSON.stringify(scope.sort)));
      if (scope.sort_area !== "") scope.sort_area += ", ";
      scope.sort_area += scope.sort.column_name + " " + scope.sort.order;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
    },
    group_cancel() {
      var scope = this;
      scope.group = "";
      scope.group_area = "";
      scope.summarize_group = false;
    },
    group_save() {
      var scope = this;
      scope.group_area = "";
      if (scope.group_area !== "") scope.group_area += ", ";
      scope.group_area += JSON.parse(JSON.stringify(scope.group));
      scope.summarize_group = false;
    },
    summarize_save() {
      var scope = this;
      scope.metrics.push(JSON.parse(JSON.stringify(scope.function_metrics)));
      if (scope.function_area !== "") scope.function_area += ", ";
      scope.function_area +=
        scope.metrics[scope.metrics.length - 1].function_type.replace(
          "_",
          " "
        ) +
        " " +
        scope.metrics[scope.metrics.length - 1].column_name;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_function = false;
    },
    function_cancel() {
      var scope = this;
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
    },
    filter_cancel() {
      var scope = this;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
    },
    filter_save() {
      var scope = this;
      scope.filters.push(JSON.parse(JSON.stringify(scope.filter_metrics)));
      if (scope.filter_area !== "") scope.filter_area += ", ";
      scope.filter_area +=
        scope.filters[scope.filters.length - 1].column_name +
        " " +
        scope.filters[scope.filters.length - 1].operation +
        " " +
        scope.filters[scope.filters.length - 1].column_value;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_filter = false;
    },
    edit_summarize() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.filter_edit = false;
      scope.sort_edit = false;
      if (scope.function_area !== "" && scope.function_area) {
        scope.function_area = "";
        scope.summarize_edit = true;
      }
    },
    summarize_edited_save() {
      var scope = this;
      for (let i = 0; i < scope.metrics.length; i++) {
        scope.function_area +=
          scope.metrics[i].function_type.replace("_", " ") +
          " " +
          scope.metrics[i].column_name;
        if (i < scope.metrics.length - 1) {
          scope.function_area += ",";
        }
      }
      scope.summarize_edit = false;
    },
    summarize_delete(values) {
      var scope = this;
      for (let i = 0; i < scope.metrics.length; i++) {
        if (
          scope.metrics[i].column_name === values.column_name &&
          scope.metrics[i].function_type === values.function_type &&
          scope.metrics[i].alias_name === values.alias_name
        ) {
          let id = scope.metrics.indexOf(scope.metrics.function_type);
          scope.metrics.splice(id, 1);
        }
      }
      if (scope.metrics.length <= 0) {
        scope.summarize_edit = false;
      }
    },
    edit_filter() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.sort_edit = false;
      if (scope.filter_area !== "" && scope.filter_area) {
        scope.filter_area = "";
        scope.filter_edit = true;
      }
    },
    filter_edited_save() {
      var scope = this;
      for (let i = 0; i < scope.filters.length; i++) {
        if (
          scope.filters[i].operation === "IS NULL" ||
          scope.filters[i].operation === "IS NOT NULL"
        ) {
          scope.filters[i].column_value = "";
        }
        scope.filter_area +=
          scope.filters[i].column_name +
          " " +
          scope.filters[i].operation +
          " " +
          scope.filters[i].column_value;
        if (i < scope.filters.length - 1) {
          scope.filter_area += ",";
        }
      }
      scope.filter_edit = false;
    },
    filter_delete(values) {
      var scope = this;
      for (let i = 0; i < scope.filters.length; i++) {
        if (
          scope.filters[i].column_name === values.column_name &&
          scope.filters[i].operation === values.operation &&
          scope.filters[i].column_value === values.column_value
        ) {
          let id = scope.filters.indexOf(scope.filters.column_name);
          scope.filters.splice(id, 1);
        }
      }
      if (scope.filters.length <= 0) {
        scope.filter_edit = false;
      }
    },
    edit_sort() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.filter_edit = false;
      if (scope.sort_area !== "" && scope.sort_area) {
        scope.sort_area = "";
        scope.sort_edit = true;
      }
    },
    sort_edited_save() {
      var scope = this;
      for (let i = 0; i < scope.sorted.length; i++) {
        scope.sort_area +=
          scope.sorted[i].column_name + " " + scope.sorted[i].order;
        if (i < scope.sorted.length - 1) {
          scope.sort_area += ",";
        }
      }
      scope.sort_edit = false;
    },
    sort_delete(values) {
      var scope = this;
      for (let i = 0; i < scope.sorted.length; i++) {
        if (
          scope.sorted[i].column_name === values.column_name &&
          scope.sorted[i].order === values.order
        ) {
          let id = scope.sorted.indexOf(scope.sorted.column_name);
          scope.sorted.splice(id, 1);
        }
      }
      if (scope.sorted.length <= 0) {
        scope.sort_edit = false;
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    createWidget() {
      const scope = this;
      var top = 10;
      var left = 10;
      var lostElement = {
        top: 10,
        left: 10,
        width: 400,
      };
      scope.$store
        .dispatch("rect/fetchProperty")
        .then((response) => {
          lostElement.top = !isNaN(parseInt(response[0].top))
            ? parseInt(response[0].top)
            : 10;
          lostElement.left = !isNaN(parseInt(response[0].left))
            ? parseInt(response[0].left)
            : 10;
          lostElement.width = !isNaN(parseInt(response[0].width))
            ? parseInt(response[0].width)
            : 400;
          if (lostElement.left === 10 && lostElement.width <= 400) {
            top = lostElement.top;
            left = 420;
          } else {
            top = lostElement.top;
            top += 320;
            left = 10;
          }
          if (scope.metrics.length > 0) {
            for (let i = 0; i < scope.metrics.length; i++) {
              scope.metrics[i].column_order = i + 1;
            }
          }
          const widget = {
            name: scope.create.widget_name,
            data_table: scope.create.data_table,
            type: scope.create.widget_type,
            xaxis_column: scope.create.xaxis_column,
            yaxis_column: scope.create.yaxis_column,
            is_shown_on_dashboard: scope.create.isShowOnDashboard,
            chart_type:
              scope.create.widget_type === "Chart"
                ? scope.create.chart_type
                : "",
            row_limit: scope.create.row_limit,
            backgroun_colors: {},
            labels: {},
            width: scope.create.widget_type === "Table" ? 810 : 400,
            height: 300,
            position_top: top,
            position_left: left,
            is_draggable: false,
            is_resizeable: false,
            min_width: 10,
            min_height: 10,
            axis: "both",
            parent_limit: false,
            snap_to_grid: false,
            aspect_ratio: false,
            zindex: 1,
            color: "#FFF",
            active: false,
            component:
              scope.create.widget_type === "Chart"
                ? scope.create.chart_type
                : "Table",
            class: "box-shaddow",
            refresh_rate: parseInt(scope.create.refresh_rate_sec) * 1000,
            function_metrics: scope.metrics,
            filter_metrics: scope.filters,
            group_by: scope.group_area,
            order_by: scope.sort_area,
          };
          if (scope.metrics.length <= 0) {
            delete widget.function_metrics;
          }
          if (scope.filters.length <= 0) {
            delete widget.filter_metrics;
          }
          scope.$store
            .dispatch("rect/createWidget", widget)
            .then(() => {
              scope.create.widget_name = "";
              scope.create.data_table = "";
              scope.create.widget_type = "";
              scope.create.xaxis_column = "";
              scope.create.yaxis_column = "";
              scope.create.isShowOnDashboard = "";
              scope.create.chart_type = "";
              scope.create.row_limit = 0;
              scope.create.refresh_rate_sec = 5;
              scope.showSnackBar("Widget Created Succesfully");
              scope.goback();
            })
            .catch((err) => {
              scope.showSnackBar("error :" + err);
            });
        })
        .catch(function(error) {
          scope.showSnackBar("error :" + error.message);
        });
    },
  },
};
</script>
<style>
.mydiv {
  background-color: #edf2ef;
}
</style>
