/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M33.333 50H40v13.334h-6.667zM46.667 50h6.666v13.334h-6.666zM60 50h6.667v13.334H60z"/><path pid="1" d="M73.333 36.667H70V30c0-11.045-8.955-20-20-20s-20 8.955-20 20v6.667h-3.333c-3.666 0-6.667 3-6.667 6.667v40C20 86.999 23.001 90 26.667 90h46.666C76.999 90 80 86.999 80 83.333v-40c0-3.666-3.001-6.666-6.667-6.666zM36.667 30c0-7.363 5.97-13.333 13.333-13.333S63.333 22.637 63.333 30v6.667H36.667V30zm36.666 13.333v26.664H26.667V43.333h46.666zm-46.666 40v-6.666h46.666v6.666H26.667z"/>'
  }
})
