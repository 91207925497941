export default {
    calldetails: {
        report: []
    },
    effectiveabandon: {
        report: []
    },
    recordingreport: {
        report: []
    },
    missedcallreport: {
        report: []
    },
    agent_activity_report: {
        report: []
    },
    agent_pause_report: {
        report: []
    },
    agent_dispo_report: {
        report: []
    },
    performance_report: {
        report: []
    },
    queueSummaryReport: {
        report: []
    },
    dropedcallreport: {
        report: []
    },
    filter: {
        fromdate: "",
        todate: "",
        agent: '',
        type: '',
        phone: '',
        group: '',
        station: '',
        did: '',
        queue: '',
        dispo: '',
        status: '',
        filter_process: false,
        last_app: '',
        campaign: '',
        event: '',
        uniqueid: '',
        template: '',
        extension: ''
    },

    filters: {
        fromdate: '',
        todate: '',
        agent: [],
        template: [],
        type: ["Inbound", "Manual", "Auto"],
        phonenumber: '',
        group: [],
        station: '',
        did: '',
        queue: [],
        dispo_status: [],
        status: ['NOANSWER', 'COMPLETE', 'ABANDON'],
        last_app: '',
        event: ["Login", "Break"],
        campaign: []
    }
}