<!-- 
**** Author: Vishnu Vardhan G
**** Module: Drag and Drop Report Builder
-->
<template>
    <div id="table-component">
        <div style="margin: 0px auto; height: 30px">
            <h4>{{ name }}</h4>
        </div>
        <div v-if="loading" class="loader"></div>
        <v-layout v-resize="onResize" class="table-layout" ref="scrollDiv" v-on:scroll="detectScroll">
            <table class="table-frame">
                <tr>
                    <th color="primary" v-bind:key="index" v-for="(header, index) in headers" class="text-xs-left p0 table-body">
                        {{ header }}
                    </th>
                </tr>
                <tr v-bind:key="index" v-for="(data, index) in datasets">
                    <td v-bind:key="index" v-for="(keyval, index) in headers" class="text-xs-left p0 table-body">
                        {{ data[keyval] }}
                    </td>
                </tr>
            </table>
        </v-layout>
    </div>
</template>
<style>
#table-component {
    position: relative;
    padding: 5px 10px 15px 10px;
    width: 100%;
    height: 95%;
}

.table-layout {
    position: absolute;
    padding: 35px 5px 5px 5px;
    width: 97%;
    height: 95%;
    overflow: auto;
}

.table-frame {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.table-body {
    padding-left: 0px;
    text-indent: 20px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.mobile {
    color: #333;
}

@media screen and (max-width: 768px) {
    .mobile table.v-table tr {
        max-width: 100%;
        position: absolute;
        display: block;
    }

    .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
    }

    .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
    }

    .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
    }

    .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: 0.5em;
        text-align: left;
        display: block;
        color: #999;
    }

    .v-datatable__actions__select {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
    }

    .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
    }
}

.flex-content {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.flex-item {
    padding: 5px;
    width: 50%;
    height: 40px;
    font-weight: bold;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 6;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
<script>
export default {
    props: {
        timer: {
            type: Number,
            default: 10000,
        },
        id: {
            type: Number,
            default: 1,
        },
        name: {
            type: String,
            default: "",
            validator: function(val) {
                return typeof val === "string";
            },
        },
        row_limit: {
            type: Number,
            default: 20,
        }
    },
    data: () => ({
        loading: false,
        pagination: {
            sortBy: "name",
        },
        selected: [],
        search: "",
        isMobile: false,
        headers: [],
        datasets: [],
        polling: null,
        skip: 0,
        take: 20,
        lock_scroll: false,
    }),
    computed: {
        activePreview() {
            return this.$store.getters['rect/activePreview'];
        }
    },
    created() {
        const scope = this;
        const payload = {
            skip: scope.skip,
            take: scope.row_limit,
            id: scope.id,
        };
        scope.$store
            .dispatch("rect/renderWidgetData", payload)
            .then((result) => {
                const data = JSON.parse(JSON.stringify(result));
                scope.headers = Object.keys(data[0]);
                scope.datasets = data;
                scope.t
            })
            .catch((err) => {
                console.log("Error(Table) ==>", err);
            });
        scope.fetchData();
    },
    updated() {
        const scope = this;
        if (scope.activePreview.update) {
            scope.headers = [];
            scope.datasets = [];
            scope.skip = 0;
            scope.take = 20;
            const payload = {
                skip: scope.skip,
                take: scope.take,
                id: scope.id,
            };
            scope.loading = true;
            scope.$store
                .dispatch("rect/renderWidgetData", payload)
                .then((result) => {
                    const data = JSON.parse(JSON.stringify(result));
                    scope.headers = Object.keys(data[0]);
                    scope.datasets = data;
                    scope.loading = false;
                })
                .catch((err) => {
                    console.log("Error(Table) ==>", err);
                    scope.loading = false;
                });
            scope.$store.dispatch("rect/updatePreview", scope.activePreview);
        }
    },
    methods: {
        detectScroll() {
            const scope = this;
            if (
                scope.$refs.scrollDiv.scrollHeight -
                scope.$refs.scrollDiv.clientHeight -
                scope.$refs.scrollDiv.scrollTop <
                1 &&
                !scope.end
            ) {
                scope.skip = scope.take + scope.skip;
                const payload = {
                    skip: scope.skip,
                    take: scope.take,
                    id: scope.id,
                };
                scope.$refs.scrollDiv.scrollTo(0, 0);
                scope.loading = true;
                console.log("payload", payload);
                scope.$store
                    .dispatch("rect/renderWidgetData", payload)
                    .then((result) => {
                        scope.loading = false;
                        const data = JSON.parse(JSON.stringify(result));
                        scope.headers = Object.keys(data[0]);
                        scope.datasets = data;
                    })
                    .catch((err) => {
                        console.log("Error(Table) ==>", err);
                    });
            }
        },
        onResize() {
            if (window.innerWidth < 769) this.isMobile = true;
            else this.isMobile = false;
        },
        toggleAll() {
            if (this.selected.length) this.selected = [];
            else this.selected = this.desserts.slice();
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        fetchData() {
            const scope = this;
            scope.polling = setInterval(() => {
                if (scope.activePreview.id === scope.id) {
                    const payload = {
                        skip: scope.skip,
                        take: scope.take,
                        id: scope.id,
                    };
                    scope.$store
                        .dispatch("rect/renderWidgetData", payload)
                        .then((result) => {
                            const data = JSON.parse(JSON.stringify(result));
                            scope.headers = Object.keys(data[0]);
                            scope.datasets = data;
                        })
                        .catch((err) => {
                            console.log("Error(Table) ==>", err);
                        });
                } else {
                    clearInterval(scope.polling);
                }
            }, scope.timer);
        },
    },
    beforeDestroy() {
        clearInterval(this.polling);
    },
};
</script>