import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
var getters = {
    GetDisposition: state => state.Disposition
};
const mutations = {
    [mutation_type.Disposition]: (state, response) => {
        state.Disposition = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetDisposition: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/disposition`).then(response => {
                    const disposition = response.data.data;
                    commit(mutation_type.Disposition, disposition);
                    const dispo_array = [];
                    disposition.forEach(dispo => {
                        dispo_array.push(dispo.dispo_id);
                    });
                    localStorage.setItem("group_disposition", JSON.stringify(dispo_array));
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterDisposition: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/disposition?active=${query.dispo_status}&dispo_id=${query.dispo_id}`).then(response => {
                    const disposition = response.data;
                    commit(mutation_type.Disposition, disposition);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/disposition`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/disposition/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/disposition/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};