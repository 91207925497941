import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterWhatsapp;
var getters = {
    Getasterwhatsapp: state => state.AsterWhatsapp.getdata
};
const mutations = {
    [mutation_type.AsterWhatsapp]: (state, response) => {
        state.AsterWhatsapp.getdata = response;
    }
};
const actions = {
    GetAsterWhatsapp: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/whatsapp`).then(response => {
                    const asterwhatsapp = response.data;
                    resolve(response.data);
                    commit(mutation_type.AsterWhatsapp, asterwhatsapp);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterWhatsapp: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/whatsapp?name=${query.name}&status=${query.status}`).then(response => {
                    const asterwhatsapp = response.data;
                    resolve(response.data);
                    commit(mutation_type.AsterWhatsapp, asterwhatsapp);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertAsterWhatsapp: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/whatsapp`, payload)
                    .then(response => {
                        const asterwhatsapp = response.data;
                        resolve(response);
                        commit(mutation_type.AsterWhatsapp, asterwhatsapp);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterWhatsapp: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/whatsapp/${payload}`)
                    .then(response => {
                        const asterwhatsapp = response.data;
                        resolve(response);
                        commit(mutation_type.AsterWhatsapp, asterwhatsapp);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAsterWhatsapp: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/aster/whatsapp/${id}`, payload)
                    .then(response => {
                        const asterwhatsapp = response.data;
                        resolve(response);
                        commit(mutation_type.AsterWhatsapp, asterwhatsapp);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};